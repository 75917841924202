import { setInitialSettings } from 'app/store/actions/nexus';
import * as MessageActions from 'app/store/actions/nexus/message.actions';
import auth0Service from 'app/services/auth0Service';
import _ from '@lodash';
import {
  SET_USER_DATA,
  REMOVE_USER_DATA,
  USER_LOGGED_OUT,
  SET_SECURITY_DATA,
  REMOVE_SECURITY_DATA,
  SET_LOGGING_OUT,
} from '../../constants';

/**
 * Set user data from Auth0 token data
 */
export function setUserDataAuth0(tokenData) {
  const user = {
    role: ['admin'],
    from: 'auth0',
    data: {
      displayName: getDisplayNameFromToken(tokenData),
      photoURL: tokenData.picture,
      email: tokenData.email,
      fhirUser: tokenData.fhirUser || tokenData.profile,
    },
  };

  return setUserData(user);
}

/**
 * Set user data from SMART token data
 */
export function setUserDataSmart(tokenData) {
  const user = {
    role: ['admin'],
    from: 'SMART',
    data: {
      displayName: getDisplayNameFromToken(tokenData),
      photoURL: tokenData.picture,
      email: tokenData.email,
      fhirUser: tokenData.fhirUser || tokenData.profile,
    },
  };

  return setUserData(user);
}

function getDisplayNameFromToken(tokenData) {
  const name = tokenData?.name;
  const username = tokenData?.username;
  const nickname = tokenData?.nickname;
  const email = tokenData?.email;
  const displayName = tokenData?.displayName;

  // if there is a display name, use that
  if (displayName !== undefined) {
    return displayName;
  }

  // if name and email but they don't match, return name
  if (name && email && name !== email) {
    return name;
  }
  // if name and email match, return blank displayName
  if (name && email && name === email) {
    return nickname || '';
  }
  // if only email and no name, but there is a nickname, return the nickname
  if (!name && nickname) {
    return nickname;
  }
  // If we only take emails for user reg this should remain empty, but we'll check it anyway as a last resort
  if (username) {
    return username;
  }

  return name;
}

/**
 * Set User Data
 */
export function setUserData(user) {
  return { type: SET_USER_DATA, payload: user };
}

/**
 * Set Security Data
 */
export function setSecurityData(securityData) {
  return { type: SET_SECURITY_DATA, payload: securityData };
}

/**
 * Update User Settings
 */
export function updateUserSettings(settings) {
  return (dispatch, getState) => {
    const oldUser = getState().auth.user;
    const user = _.merge({}, oldUser, { settings });

    updateUserData(user, dispatch);

    return dispatch(setUserData(user));
  };
}

/**
 * Load all user settings into state on login
 */
export function loadUserSettings(settings) {
  return (dispatch, getState) => {
    const oldUser = getState().auth.user;
    if (_.isEmpty(settings)) {
      return;
    }
    // Merge old user data with user settings
    const newUser = _.merge({}, oldUser, settings);
    // Set newUser to state
    return dispatch(setUserData(newUser));
  };
}

/**
 * Remove User Data
 */
export function removeUserData() {
  return {
    type: REMOVE_USER_DATA,
  };
}

/**
 * Remove Security Data
 */
export function removeSecurityData() {
  return {
    type: REMOVE_SECURITY_DATA,
  };
}

/**
 * Set Logging Out
 */
export function setLoggingOut(value) {
  return {
    type: SET_LOGGING_OUT,
    payload: value,
  };
}

/**
 * Logout
 */
export function logoutUser(logout) {
  return dispatch => {
    // The authentication manager will set loggingOut back to false
    // once the user loads a page and is found to be unauthenticated by auth0
    // Note: this should happen as the user accesses the homepage
    dispatch(setLoggingOut(true));

    logout({ returnTo: window.location.origin });
    auth0Service.logout();

    dispatch(setInitialSettings());
    dispatch({
      type: USER_LOGGED_OUT,
    });
  };
}

/**
 * Update User Data
 */
async function updateUserData(user, dispatch) {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }

  try {
    const updateResults = await auth0Service.updateUserMetadata({
      settings: user.settings,
      shortcuts: user.shortcuts,
    });
    if (_.isEmpty(updateResults) || _.isUndefined(updateResults.success) || updateResults.success === false) {
      dispatch(
        MessageActions.showMessageAlert(
          `Unable to save user data: ${updateResults?.errorCode}: ${updateResults?.errorMessage}`,
          'error',
        ),
      );
    }
  } catch (err) {
    console.warn('Error updating userMetadata', err);
    dispatch(MessageActions.showMessageError(err));
  }
}
