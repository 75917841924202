import React from 'react';
import { authScopes } from 'app/services/auth0Service/authScopes';

export const DashboardConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  requireAuthentication: true,
  routes: [
    {
      path: '/dashboard',
      exact: true,
      component: React.lazy(() => import('app/content/apps/Dashboards/DashboardPicker')),
      scopes: [authScopes.view.practitionerList, authScopes.practitioner.read],
    },
    // {
    //   path: '/dashboard/schedule',
    //   exact: true,
    //   component: React.lazy(() => import('app/content/apps/Dashboards/DashboardPicker')),
    //   scopes: [authScopes.view.practitionerList, authScopes.practitioner.read],
    // },
    // {
    //   path: '/dashboard/patients',
    //   exact: true,
    //   component: React.lazy(() => import('app/content/apps/Dashboards/DashboardPicker')),
    //   scopes: [authScopes.view.practitionerList, authScopes.practitioner.read],
    // },
  ],
};
