// http://hl7.org/fhir/R4/datatypes.html#HumanName
//
// NOTE: if we wanted to add prefix/suffix support, this is a good list source:
// https://github.com/joshfraser/PHP-Name-Parser/blob/master/parser.php

import { get, isArray, isEmpty, isPlainObject, isString } from 'lodash';
import { isArrayOfStrings, isNonEmptyArray, joinNonEmpty, hasAll, isNonEmptyString } from 'app/utils/helpers';
import { appendElement, extractValueFromPath } from '../resourceUtils';

export const HUMAN_NAME_FIELDS = {
  use: 'use',
  text: 'text',
  family: 'family',
  given: 'given',
  prefix: 'prefix',
  suffix: 'suffix',
  period: 'period',
};

const SPACE_SEPARATOR = ' ';

export function createHumanName(given, family) {
  const humanName = {};
  let humanNameDisplay = '';
  // append given name; handle both arrays and string values
  if (isNonEmptyString(given)) {
    appendElement(humanName, 'given', [given]);
    humanNameDisplay = appendDisplayName(humanNameDisplay, given);
  } else if (isArrayOfStrings(given)) {
    // set given name array, but remove any empty array items
    appendElement(
      humanName,
      'given',
      given.filter(item => !isEmpty(item)),
    );
    humanNameDisplay = appendDisplayName(humanNameDisplay, joinNonEmpty(given, SPACE_SEPARATOR));
  }
  // append family name
  if (isString(family)) {
    appendElement(humanName, 'family', family);
    humanNameDisplay = appendDisplayName(humanNameDisplay, family);
  }

  // finalize the text representation of the full name
  appendElement(humanName, 'text', humanNameDisplay);

  // return resulting HumanName
  return humanName;
}

function appendDisplayName(currentDisplayName, nameToAppend) {
  if (isEmpty(currentDisplayName)) {
    return nameToAppend;
  }
  if (isEmpty(nameToAppend)) {
    return currentDisplayName;
  }
  return `${currentDisplayName} ${nameToAppend}`;
}

// returns the raw given element array
export function extractGivenName(humanName) {
  return extractValueFromPath(humanName, 'given');
}

// returns a flattened version of the given name array
export function extractGivenNameDisplay(humanName) {
  const givenNameArray = extractGivenName(humanName);
  if (isArray(givenNameArray)) {
    return joinNonEmpty(givenNameArray, SPACE_SEPARATOR);
  }
  // given name was not found; return empty string
  return '';
}

// family name is always a string value
export function extractFamilyName(humanName) {
  return extractValueFromPath(humanName, 'family');
}

// Extracts the first humanName object that meets the user's criteria
export function findHumanName(names, ...requiredFields) {
  if (isNonEmptyArray(names) && isNonEmptyArray(requiredFields)) {
    return names.find(name => hasAll(name, requiredFields));
  }
}

// finds the index of the first humanName object that meets the user's criteria
export function findHumanNameIndex(names, ...requiredFields) {
  if (isNonEmptyArray(names) && isNonEmptyArray(requiredFields)) {
    return names.findIndex(name => hasAll(name, requiredFields));
  }
}

// NOTE: this is limited to setting the humanName to the first item of an humanName array
export function setHumanName(resource, humanNamePath, humanName, nameIndexToUpdate = -1) {
  if (isPlainObject(resource)) {
    // get humanName array from object path
    const humanNames = get(resource, humanNamePath);
    if (isNonEmptyArray(humanNames)) {
      if (nameIndexToUpdate > -1) {
        appendElement(resource, `${humanNamePath}[${nameIndexToUpdate}]`, humanName);
      } else {
        // none of the current names matched our criteria; append a new name to the array
        appendElement(resource, `${humanNamePath}[${humanNames.length}]`, humanName);
      }
    } else {
      // no humanName array currently exists, create it and set our humanName as the first item
      appendElement(resource, humanNamePath, [humanName]);
    }
  }
}
