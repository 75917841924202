import auth0Service from 'app/services/auth0Service';

export function requestGetOptions(jsonObject) {
  return {
    method: 'GET',
    body: jsonObject && JSON.stringify(jsonObject),
    headers: {
      'Content-Type': 'application/json',
    },
  };
}

export function requestPutOptions(jsonObject) {
  return {
    method: 'PUT',
    body: jsonObject && JSON.stringify(jsonObject),
    headers: {
      'Content-Type': 'application/json',
    },
  };
}

export function requestPostOptions(jsonObject) {
  return {
    method: 'POST',
    body: jsonObject && JSON.stringify(jsonObject),
    headers: {
      'Content-Type': 'application/json',
    },
  };
}

export function requestDeleteOptions(jsonObject) {
  return {
    method: 'DELETE',
    body: jsonObject && JSON.stringify(jsonObject),
    headers: {
      'Content-Type': 'application/json',
    },
  };
}

export function requestPatchOptions(jsonObject) {
  return {
    method: 'PATCH',
    body: jsonObject && JSON.stringify(jsonObject),
    headers: {
      'Content-Type': 'application/json',
    },
  };
}

export function requestJSONPatchOptions(jsonObject) {
  return {
    method: 'PATCH',
    body: jsonObject && JSON.stringify(jsonObject),
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
  };
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

// Used to verfiy that the error came from the 'checkStatus' function
// and guarentees that the error will have a 'response' property
export const isResponseError = error => error?.response !== undefined;

// Helper function to check if an error was due to lack of permissions
export const isForbidden = error => error?.reponse?.status === 403;

// Helper funection to check if an error is due to the resource being missing
export const isResourceMissing = error => error?.response?.status === 404;

export async function requestWithToken(url, options = {}) {
  let fetchOptions = options;
  const accessToken = auth0Service.getAccessToken();
  if (accessToken) {
    fetchOptions = {
      ...fetchOptions,
      headers: { Authorization: `Bearer ${accessToken}`, ...fetchOptions.headers },
    };
  } else {
    console.log('No access_token found');
  }
  return request(url, fetchOptions);
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default async function request(url, options = {}) {
  const fetchResponse = await fetch(url, options);
  const response = await checkStatus(fetchResponse);
  return parseJSON(response);
}
