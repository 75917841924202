import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import Auth0Manager from './Auth0Manager/AuthenticationProvider';
import SMARTManager from './SmartManager';

function AuthenticationProvider({ children }) {
  const [authType, setAuthType] = useState('auth0');
  const launchMatch = useRouteMatch('/smart/:id/launch');
  const callbackMatch = useRouteMatch('/smart/:id/callback');

  useEffect(() => {
    if (launchMatch !== null || callbackMatch !== null) {
      // SMART Launch
      setAuthType('smart');
      console.log('Smart on fhir launch path discovered... Using SMART for login');
    }
  }, [setAuthType, launchMatch, callbackMatch]);

  if (authType === 'smart') {
    const type = launchMatch !== null ? 'launch' : 'callback';
    let clientId;
    if (launchMatch !== null) {
      clientId = launchMatch.params.id;
    } else if (callbackMatch !== null) {
      clientId = callbackMatch.params.id;
    }

    return (
      <SMARTManager mode={type} clientId={clientId}>
        {children}
      </SMARTManager>
    );
  }

  return <Auth0Manager>{children}</Auth0Manager>;
}

AuthenticationProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthenticationProvider;
