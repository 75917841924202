import produce from 'immer';
import {
  GET_LAUNCHABLE_SMARTAPPS_SUCCESS,
  GET_LAUNCHABLE_SMARTAPPS_ERROR,
  SET_LAUNCHABLE_SMARTAPPS,
} from 'app/content/settings/SmartApps/store/constants';
import { createSmartSelector } from 'app/utils/stateHelpers';
import {
  SET_USER_DATA,
  REMOVE_USER_DATA,
  USER_LOGGED_OUT,
  REMOVE_SECURITY_DATA,
  SET_SECURITY_DATA,
} from '../../constants';

const initialState = {
  role: [], // guest
  data: {
    displayName: 'CareNexus User',
    photoURL: 'assets/images/avatars/profile.jpg',
    email: 'noreply@carenexus.io',
    fhirUser: null,
  },
  security: {},
  smartApps: {
    appList: [],
  },
};

const user = produce((draft, action) => {
  switch (action.type) {
    case SET_USER_DATA: {
      return {
        ...initialState,
        ...action.payload,
      };
    }
    case REMOVE_USER_DATA: {
      return {
        ...initialState,
      };
    }
    case SET_SECURITY_DATA: {
      draft.security = { ...action.payload };
      break;
    }
    case REMOVE_SECURITY_DATA: {
      draft.security = {};
      break;
    }
    // case SET_USER_SETTINGS: {
    //   return {
    //     ...initialState,
    //     ...action.payload,
    //   };
    // }
    case USER_LOGGED_OUT: {
      return initialState;
    }
    case SET_LAUNCHABLE_SMARTAPPS: {
      draft.smartApps.appList = action.smartApps?.body;
      break;
    }
    case GET_LAUNCHABLE_SMARTAPPS_SUCCESS: {
      draft.smartApps.appList = action?.smartApps?.body;
      break;
    }
    case GET_LAUNCHABLE_SMARTAPPS_ERROR: {
      draft.smartApps.appList = [];
      break;
    }
    default: {
      return draft;
    }
  }
}, initialState);

// Selector Info
// export const tokenDataSelector = state => userSelector(state)?.security;
export const tokenDataSelector = createSmartSelector(state => state.security);

export const userSelectors = [tokenDataSelector];
export default user;
