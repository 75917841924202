import { memo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import NexusScrollbars from '@nexus/core/NexusScrollbars';
import Logo from 'app/layout/shared-components/Logo';
import NavbarToggleButton from 'app/layout/shared-components/NavbarToggleButton';
import Navigation from 'app/layout/shared-components/Navigation';
import UserNavbarHeader from 'app/layout/shared-components/UserNavbarHeader';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    '& ::-webkit-scrollbar-thumb': {
      boxShadow: `inset 0 0 0 20px ${
        theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)'
      }`,
    },
    '& ::-webkit-scrollbar-thumb:active': {
      boxShadow: `inset 0 0 0 20px ${
        theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(255, 255, 255, 0.37)'
      }`,
    },
  },
  content: {
    overscrollBehavior: 'contain',
    overflowX: 'hidden',
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    background:
      'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 40px, 100% 10px',
    backgroundAttachment: 'local, scroll',
  },
}));

function NavbarContent(props) {
  const classes = useStyles();

  return (
    <div className={clsx('flex flex-auto flex-col overflow-hidden h-full', classes.root, props.className)}>
      <AppBar
        color="primary"
        position="static"
        className="flex flex-row items-center flex-shrink h-48 md:h-64 min-h-48 md:min-h-64 px-8 shadow-0"
      >
        <div className="flex flex-1 mx-4">
          <Logo />
        </div>

        <NavbarToggleButton className="w-40 h-40 p-0" />
      </AppBar>

      <NexusScrollbars className={clsx(classes.content)} option={{ suppressScrollX: true, wheelPropagation: false }}>
        <UserNavbarHeader />

        <Navigation layout="vertical" />
      </NexusScrollbars>
    </div>
  );
}

NavbarContent.propTypes = {
  className: PropTypes.string,
};

export default memo(NavbarContent);
