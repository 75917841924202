import { extractResourceId } from 'app/resources/resourceUtils';
import { requestPutOptions } from 'app/utils/request';
import urljoin from 'url-join';
import { errorIfUninitialized, query } from './core';
import { URL_ORGANIZATION } from './urlConstants';

/**
 * A function that allows for querying organizations. Add params to modify the request
 *
 * @param {array} params - An array of query strings. They will be joined with '&' and appended to the request URL
 * @returns promise
 */
export async function getOrganizations(params = []) {
  errorIfUninitialized();

  // By default get 200 practitioners
  params.push('_count=200');

  const paramsString = params.length > 0 ? `?${params.join('&')}` : '';
  const response = await query(`${URL_ORGANIZATION}${paramsString}`);
  return response;
}

/**
 * A function for requesting a single organization
 *
 * @param {string} id - The id of the organization
 * @returns promise
 */
export async function getOrganization(id) {
  errorIfUninitialized();
  const response = await query(`${URL_ORGANIZATION}?_id=${id}`);
  return response;
}

/**
 * A function for creating a organization
 *
 * @param {object} organization - A FHIR organization object
 * @returns promise
 */
export async function createOrganization(organization) {
  errorIfUninitialized();
  const url = urljoin(URL_ORGANIZATION, extractResourceId(organization));
  const response = await query(url, requestPutOptions(organization));
  return response;
}
