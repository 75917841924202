/*
 * FHIR valueSet constants
 */

import { ValueSetNamespace } from 'app/store/stateConstants';
import { URL_FHIR_API } from 'app/utils/urlConstants';

const VALUESET_URL = 'http://hl7.org/fhir/ValueSet/';
const VALUESET_USCORE_URL = 'http://hl7.org/fhir/us/core/ValueSet/';
const VALUESET_TERM_URL = 'http://terminology.hl7.org/ValueSet/';
const VALUESET_PATH = `${URL_FHIR_API}/ValueSet/$expand?url=${VALUESET_URL}`;
const VALUESET_RELATIVE_PATH = `ValueSet/$expand?url=${VALUESET_URL}`;
const VALUESET_USCORE_PATH = `${URL_FHIR_API}/ValueSet/$expand?url=${VALUESET_USCORE_URL}`;
const VALUESET_USCORE_RELATIVE_PATH = `ValueSet/$expand?url=${VALUESET_USCORE_URL}`;
const VALUESET_TERM_PATH = `${URL_FHIR_API}/ValueSet/$expand?url=${VALUESET_TERM_URL}`;
const VALUESET_TERM_RELATIVE_PATH = `ValueSet/$expand?url=${VALUESET_TERM_URL}`;
// const VALUESET_CARENEXUS_PATH = `${URL_FHIR_API}/ValueSet/$expand?url=https://carenexus.io/api/v1/fhir/ValueSet/`;
// const VALUESET_CARENEXUS_RELATIVE_PATH = `ValueSet/$expand?url=https://carenexus.io/api/v1/fhir/ValueSet/`;

// Condition-related valueSets
export const CONDITION_CLINICAL_STATUS = {
  key: 'conditionClinical',
  id: 'condition-clinical',
  path: `${VALUESET_PATH}condition-clinical`,
  relativePath: `${VALUESET_RELATIVE_PATH}condition-clinical`,
  url: `${VALUESET_URL}condition-clinical`,
};
export const CONDITION_VERIFICATION_STATUS = {
  key: 'conditionVerStatus',
  id: 'condition-ver-status',
  path: `${VALUESET_PATH}condition-ver-status`,
  relativePath: `${VALUESET_RELATIVE_PATH}condition-ver-status`,
  url: `${VALUESET_URL}condition-ver-status`,
};
export const USCORE_CONDITION_CATEGORY = {
  key: 'conditionCategory',
  id: 'us-core-condition-category',
  path: `${VALUESET_USCORE_PATH}us-core-condition-category`,
  relativePath: `${VALUESET_USCORE_RELATIVE_PATH}us-core-condition-category`,
  url: `${VALUESET_USCORE_URL}us-core-condition-category`,
};
export const CONDITION_CODE_SNOMED = {
  key: 'conditionCodeSNOMED',
  id: 'cn-condition-code',
  path: `${URL_FHIR_API}/ValueSet/cn-condition-code/$expand`,
  relativePath: `/ValueSet/cn-condition-code/$expand`,
  url: 'https://carenexus.io/api/v1/fhir/ValueSet/cn-condition-code',
  allowPreloading: false,
};

export const CONDITION_CODE_ICD10 = {
  key: 'conditionCodeICD10',
  id: 'cn-icd-10-cm',
  path: `${URL_FHIR_API}/ValueSet/cn-icd-10-cm/$expand`,
  relativePath: `/ValueSet/cn-icd-10-cm/$expand`,
  url: '', // TODO: Update url in FHIR Server
  allowPreloading: false,
};
// export const CONDITION_CATEGORY = {
//   key: 'conditionCategory',
//   path: `${VALUESET_PATH}condition-category`,
//   relativePath: `${VALUESET_USCORE_RELATIVE_PATH}condition-category`,
// };

// Goal-related valueSets
export const GOAL_STATUS = {
  key: 'goalStatus',
  id: 'goal-status',
  path: `${VALUESET_PATH}goal-status`,
  relativePath: `${VALUESET_RELATIVE_PATH}goal-status`,
  url: `${VALUESET_URL}goal-status`,
};
export const GOAL_ACHIEVEMENT = {
  key: 'goalAchievement',
  id: 'goal-achievement',
  path: `${VALUESET_PATH}goal-achievement`,
  relativePath: `${VALUESET_RELATIVE_PATH}goal-achievement`,
  url: `${VALUESET_URL}goal-achievement`,
};
export const GOAL_PRIORITY = {
  key: 'goalPriority',
  id: 'goal-priority',
  path: `${VALUESET_PATH}goal-priority`,
  relativePath: `${VALUESET_RELATIVE_PATH}goal-priority`,
  url: `${VALUESET_URL}goal-priority`,
};

// CareTeam-related ValueSets
export const CARETEAM_STATUS = {
  key: 'careTeamStatus',
  id: 'care-team-status',
  path: `${VALUESET_PATH}care-team-status`,
  relativePath: `${VALUESET_RELATIVE_PATH}care-team-status`,
  url: `${VALUESET_URL}care-team-status`,
};

// Include all codes defined in http://nucc.org/provider-taxonomy
// Include codes from http://snomed.info/sct where concept is-a 223366009 (Healthcare professional)
// Include codes from http://snomed.info/sct where concept is-a 224930009 (Services)
export const USCORE_CARETEAM_PROVIDER_ROLES = {
  key: 'usCoreCareTeamProviderRoles',
  id: 'us-core-careteam-provider-roles',
  path: `${VALUESET_USCORE_PATH}us-core-careteam-provider-roles`,
  relativePath: `${VALUESET_USCORE_RELATIVE_PATH}us-core-careteam-provider-roles`,
  url: `${VALUESET_USCORE_URL}us-core-careteam-provider-roles`,
  allowPreloading: false,
};

// CarePlan-related ValueSets
export const REQUEST_STATUS = {
  key: 'requestStatus',
  id: 'request-status',
  path: `${VALUESET_PATH}request-status`,
  relativePath: `${VALUESET_RELATIVE_PATH}request-status`,
  url: `${VALUESET_URL}request-status`,
};

export const CARE_PLAN_INTENT = {
  key: 'carePlanIntent',
  id: 'care-plan-intent',
  path: `${VALUESET_PATH}care-plan-intent`,
  relativePath: `${VALUESET_RELATIVE_PATH}care-plan-intent`,
  url: `${VALUESET_URL}care-plan-intent`,
};

// universal ValueSets
export const IDENTIFIER_TYPE = {
  key: 'identifierType',
  id: 'identifier-type',
  path: `${VALUESET_PATH}identifier-type`,
  relativePath: `${VALUESET_RELATIVE_PATH}identifier-type`,
  url: `${VALUESET_URL}identifier-type`,
};

export const USCORE_NARRATIVE_STATUS = {
  key: 'narrativeStatus',
  id: 'us-core-narrative-status',
  path: `${VALUESET_USCORE_PATH}us-core-narrative-status`,
  relativePath: `${VALUESET_USCORE_RELATIVE_PATH}us-core-narrative-status`,
  url: `${VALUESET_USCORE_URL}us-core-narrative-status`,
};

export const APPOINTMENT_STATUS = {
  key: 'appointmentStatus',
  id: 'appointmentstatus',
  path: `${VALUESET_PATH}appointmentstatus`,
  relativePath: `${VALUESET_RELATIVE_PATH}appointmentstatus`,
  url: `${VALUESET_URL}appointmentstatus`,
};

export const APPOINTMENT_PARTICIPANT_REQUIRED = {
  key: 'appointmentParticipantRequired',
  id: 'participantrequired',
  path: `${VALUESET_PATH}participantrequired`,
  relativePath: `${VALUESET_RELATIVE_PATH}participantrequired`,
  url: `${VALUESET_URL}participantrequired`,
};

export const USCORE_BIRTHSEX = {
  key: 'usCoreBirthSex',
  id: 'birthsex',
  path: `${VALUESET_USCORE_PATH}birthsex`,
  relativePath: `${VALUESET_USCORE_RELATIVE_PATH}birthsex`,
  url: `${VALUESET_USCORE_URL}birthsex`,
};

export const ADMINISTRATIVE_GENDER = {
  key: 'administrativeGender',
  id: 'administrative-gender',
  path: `${VALUESET_PATH}administrative-gender`,
  relativePath: `${VALUESET_RELATIVE_PATH}administrative-gender`,
  url: `${VALUESET_URL}administrative-gender`,
};

export const USCORE_OMB_ETHNICITY_CATEGORY = {
  key: 'usCoreOmbEthnicityCategory',
  id: 'omb-ethnicity-category',
  path: `${VALUESET_USCORE_PATH}omb-ethnicity-category`,
  relativePath: `${VALUESET_USCORE_RELATIVE_PATH}omb-ethnicity-category`,
  url: `${VALUESET_USCORE_URL}omb-ethnicity-category`,
};

export const USCORE_OMB_RACE_CATEGORY = {
  key: 'usCoreOmbRaceCategory',
  id: 'omb-race-category',
  path: `${VALUESET_USCORE_PATH}omb-race-category`,
  relativePath: `${VALUESET_USCORE_RELATIVE_PATH}omb-race-category`,
  url: `${VALUESET_USCORE_URL}omb-race-category`,
};

export const USCORE_USPS_STATE = {
  key: 'usCoreUspsState',
  id: 'us-core-usps-state',
  path: `${VALUESET_USCORE_PATH}us-core-usps-state`,
  relativePath: `${VALUESET_USCORE_RELATIVE_PATH}us-core-usps-state`,
  url: `${VALUESET_USCORE_URL}us-core-usps-state`,
};

export const USCORE_ENCOUNTER_TYPE = {
  key: 'usCoreEncounterType',
  id: 'us-core-encounter-type',
  path: `${VALUESET_USCORE_PATH}us-core-encounter-type`,
  relativePath: `${VALUESET_USCORE_RELATIVE_PATH}us-core-encounter-type`,
  url: `${VALUESET_USCORE_URL}us-core-encounter-type`,
};

export const TERM_V3_ACT_ENCOUNTER_CODE = {
  key: 'termV3ActEncounterCode',
  id: 'v3-ActEncounterCode',
  path: `${VALUESET_TERM_PATH}v3-ActEncounterCode`,
  relativePath: `${VALUESET_TERM_RELATIVE_PATH}v3-ActEncounterCode`,
  url: `${VALUESET_TERM_URL}v3-ActEncounterCode`,
};

export const ENCOUNTER_STATUS = {
  key: 'encounterStatus',
  id: 'encounter-status',
  path: `${VALUESET_PATH}encounter-status`,
  relativePath: `${VALUESET_RELATIVE_PATH}encounter-status`,
  url: `${VALUESET_URL}encounter-status`,
};

export const ENCOUNTER_PARTICIPANT_TYPE = {
  key: 'encounterParticipantType',
  id: 'encocunter-participant-type',
  path: `${VALUESET_PATH}encounter-participant-type`,
  relativePath: `${VALUESET_RELATIVE_PATH}encounter-participant-type`,
  url: `${VALUESET_URL}encounter-participant-type`,
};

export const OBSERVATION_STATUS = {
  key: 'observationStatus',
  id: 'observation-status',
  path: `${VALUESET_PATH}observation-status`,
  relativePath: `${VALUESET_RELATIVE_PATH}observation-status`,
  url: `${VALUESET_URL}observation-status`,
};

export const OBSERVATION_CATEGORY = {
  key: 'observationCategory',
  id: 'observation-category',
  path: `${VALUESET_PATH}observation-category`,
  relativePath: `${VALUESET_RELATIVE_PATH}observation-category`,
  url: `${VALUESET_URL}observation-category`,
};

export const CONTACT_POINT_SYSTEM = {
  key: 'contactPointSystem',
  id: 'contact-point-system',
  path: `${VALUESET_PATH}contact-point-system`,
  relativePath: `${VALUESET_RELATIVE_PATH}contact-point-system`,
  url: `${VALUESET_URL}contact-point-system`,
};

export const GET_VALUESET = `${ValueSetNamespace}/GET_VALUESET`;
export const GET_VALUESET_SUCCESS = `${ValueSetNamespace}/GET_VALUESET_SUCCESS`;
export const GET_VALUESET_ERROR = `${ValueSetNamespace}/GET_VALUESET_ERROR`;
export const GET_VALUESET_BATCH = `${ValueSetNamespace}/GET_VALUESET_BATCH`;
export const GET_VALUESET_BATCH_SUCCESS = `${ValueSetNamespace}/GET_VALUESET_BATCH_SUCCESS`;
export const GET_VALUESET_BATCH_ERROR = `${ValueSetNamespace}/GET_VALUESET_BATCH_ERROR`;
