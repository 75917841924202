import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { memo } from 'react';
import _ from '@lodash';
import NexusNavHorizontalLayout1 from './horizontal/NexusNavHorizontalLayout1';
import NexusNavVerticalLayout1 from './vertical/NexusNavVerticalLayout1';
import NexusNavVerticalLayout2 from './vertical/NexusNavVerticalLayout2';
import NexusNavHorizontalCollapse from './horizontal/types/NexusNavHorizontalCollapse';
import NexusNavHorizontalGroup from './horizontal/types/NexusNavHorizontalGroup';
import NexusNavHorizontalItem from './horizontal/types/NexusNavHorizontalItem';
import NexusNavHorizontalLink from './horizontal/types/NexusNavHorizontalLink';
import NexusNavVerticalCollapse from './vertical/types/NexusNavVerticalCollapse';
import NexusNavVerticalGroup from './vertical/types/NexusNavVerticalGroup';
import NexusNavVerticalItem from './vertical/types/NexusNavVerticalItem';
import NexusNavVerticalLink from './vertical/types/NexusNavVerticalLink';
import { registerComponent } from './NexusNavItem';
/*
Register Nexus Navigation Components
 */
registerComponent('vertical-group', NexusNavVerticalGroup);
registerComponent('vertical-collapse', NexusNavVerticalCollapse);
registerComponent('vertical-item', NexusNavVerticalItem);
registerComponent('vertical-link', NexusNavVerticalLink);
registerComponent('horizontal-group', NexusNavHorizontalGroup);
registerComponent('horizontal-collapse', NexusNavHorizontalCollapse);
registerComponent('horizontal-item', NexusNavHorizontalItem);
registerComponent('horizontal-link', NexusNavHorizontalLink);
registerComponent('vertical-divider', () => <Divider className="my-16" />);
registerComponent('horizontal-divider', () => <Divider className="my-16" />);

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.popper-navigation-list': {
      '& .nexus-list-item': {
        padding: '8px 12px 8px 12px',
        height: 40,
        minHeight: 40,
        '& .nexus-list-item-text': {
          padding: '0 0 0 8px',
        },
      },
      '&.dense': {
        '& .nexus-list-item': {
          minHeight: 32,
          height: 32,
          '& .nexus-list-item-text': {
            padding: '0 0 0 8px',
          },
        },
      },
    },
  },
}));

function NexusNavigation(props) {
  const classes = useStyles(props);
  const options = _.pick(props, [
    'navigation',
    'layout',
    'active',
    'dense',
    'className',
    'onItemClick',
    'firstLevel',
    'selectedId',
  ]);
  if (props.navigation.length > 0) {
    switch (props.layout) {
      case 'horizontal': {
        return <NexusNavHorizontalLayout1 {...options} />;
      }
      case 'vertical': {
        return <NexusNavVerticalLayout1 {...options} />;
      }
      case 'vertical-2': {
        return <NexusNavVerticalLayout2 {...options} />;
      }
      default: {
        return <NexusNavVerticalLayout1 {...options} />;
      }
    }
  } else {
    return null;
  }
}

NexusNavigation.propTypes = {
  navigation: PropTypes.array.isRequired,
};

NexusNavigation.defaultProps = {
  layout: 'vertical',
};

export default memo(NexusNavigation);
