import React from 'react';
import clsx from 'clsx';
import { AppBar, Hidden, Toolbar } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import NavbarToggleButton from 'app/layout/shared-components/NavbarToggleButton';
import QuickPanelToggleButton from 'app/layout/shared-components/quickPanel/QuickPanelToggleButton';
import UserMenu from 'app/layout/shared-components/UserMenu';
import NexusSearch from '@nexus/core/NexusSearch';
import NexusShortcuts from '@nexus/core/NexusShortcuts';
import LanguageSwitcher from 'app/layout/shared-components/LanguageSwitcher';

const useStyles = makeStyles(theme => ({
  separator: {
    width: 1,
    height: 64,
    backgroundColor: theme.palette.divider,
  },
}));

function ToolbarLayoutMain(props) {
  const config = useSelector(({ nexus }) => nexus.settings.current.layout.config);
  const toolbarTheme = useSelector(({ nexus }) => nexus.settings.toolbarTheme);

  const classes = useStyles(props);

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="nexus-toolbar"
        className={clsx(classes.root, 'flex relative z-10 shadow-md')}
        color="default"
        style={{ backgroundColor: toolbarTheme.palette.background.paper }}
      >
        <Toolbar className="p-0 min-h-48 md:min-h-64">
          {config.navbar.display && config.navbar.position === 'left' && (
            <Hidden lgUp>
              <NavbarToggleButton className="w-64 h-64 p-0 mx-0 sm:mx-8" />
              <div className={classes.separator} />
            </Hidden>
          )}

          <div className="flex flex-1">
            <Hidden mdDown>
              <NexusShortcuts className="px-16" />
            </Hidden>
          </div>

          <div className="flex items-center px-0 md:px-8">
            <UserMenu />

            <div className={classes.separator} />

            <NexusSearch />

            <div className={classes.separator} />

            <LanguageSwitcher />

            <div className={classes.separator} />

            <QuickPanelToggleButton />
          </div>

          {config.navbar.display && config.navbar.position === 'right' && (
            <Hidden lgUp>
              <NavbarToggleButton />
            </Hidden>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default React.memo(ToolbarLayoutMain);
