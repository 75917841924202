import React from 'react';
import { authScopes } from 'app/services/auth0Service/authScopes';

export const PatientsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  requireAuthentication: true,
  routes: [
    {
      path: '/app/patients',
      exact: true,
      component: React.lazy(() => import('app/content/apps/Patients/PatientList')),
      scopes: [authScopes.view.patientList, authScopes.patient.read],
    },
    {
      path: '/app/patients/edit/:patientId',
      component: React.lazy(() => import('app/content/apps/Patients/PatientEditor')),
      scopes: [authScopes.view.patientAdd, authScopes.patient.read, authScopes.patient.write],
    },
    {
      path: '/app/patients/add',
      exact: true,
      component: React.lazy(() => import('app/content/apps/Patients/PatientEditor')),
      scopes: [authScopes.view.patientAdd, authScopes.patient.write],
    },
    {
      path: '/app/patients/:patientId',
      component: React.lazy(() => import('app/content/apps/Patients/PatientProfile')),
      scopes: [
        authScopes.view.patientList,
        authScopes.patient.read,
        authScopes.condition.read,
        authScopes.appointment.read,
        authScopes.goal.read,
        authScopes.careTeam.read,
        authScopes.carePlan.read,
      ],
    },
  ],
};
