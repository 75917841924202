import React from 'react';

export default React.createContext({
  authenticationIsDone: false,
  setAuthenticationIsDone: () => {},
  authenticationLoading: false,
  setAuthenticationLoading: () => {},
  loggedIn: false,
  setLoggedIn: () => {},
  systemMode: 'auth0',
});
