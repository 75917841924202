import { careNexusPrimary } from './colors';

export const tooltipDark = {
  MuiTooltip: {
    tooltip: {
      fontSize: '0.9em',
      backgroundColor: careNexusPrimary[400],
    },
  },
};

export const tooltipLight = {
  MuiTooltip: {
    tooltip: {
      fontSize: '0.9em',
      color: careNexusPrimary[900],
      backgroundColor: careNexusPrimary[100],
    },
  },
};

export const button = {
  MuiButton: {
    root: {
      fontFamily: 'Helvetica Neue, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
    },
  },
};
