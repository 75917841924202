import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSaga } from 'redux-injectors';
import { SettingsNamespace, SmartAppsSettingName } from 'app/store/stateConstants';
import smartAppsSaga from 'app/content/settings/SmartApps/store/sagas';
import NexusSplashScreen from '@nexus/core/NexusSplashScreen';
import { oauth2 } from 'fhirclient';
import jwtDecode from 'jwt-decode';
import Auth0Service from 'app/services/auth0Service';
import { setNavigation } from 'app/store/actions';
import navigationConfig from 'app/config/navigationConfig';
import * as FHIRService from 'app/services/FHIR';
import * as ValueSetService from 'app/services/ValueSetService';
import PropTypes from 'prop-types';
import urljoin from 'url-join';
import { useHistory } from 'react-router-dom';
import { DEFAULT_LASTPATH } from 'app/store/settingConstants';
import { smartOnFhirScopes } from 'app/services/auth0Service/authScopes';
import AuthContext from '../AuthenticationProviderContext';
import settingsSaga from '../store/sagas/settings.saga';
import { setForcedLogout, setLoggingOut, setSecurityData, setUserDataSmart } from '../store/actions';
import { clientIdRegistry } from './registry';

function SmartAuthManager({ children, mode, clientId }) {
  const {
    authenticationIsDone,
    authenticationLoading,
    setAuthenticationIsDone,
    setAuthenticationLoading,
    setLoggedIn,
  } = useContext(AuthContext);
  const [smartAuthenticated, setSmartAuthentication] = useState(false);
  const [smartLoading, setSmartLoading] = useState(true);
  const [client, setClient] = useState(null);
  const history = useHistory();

  useEffect(() => {
    let updatedClientId = clientId;
    const registryId = clientIdRegistry[clientId];
    if (registryId !== undefined) {
      updatedClientId = registryId;
    }

    const auth = async () => {
      console.log('SMART authorization triggered');
      const redirectUrl = urljoin(window.location.origin, 'smart', clientId, 'callback');
      oauth2.authorize({
        clientId: updatedClientId,
        redirectUri: redirectUrl,
        scope: smartOnFhirScopes,
      });
    };
    if (mode === 'launch') {
      auth();
    } else if (mode === 'callback') {
      console.log('Smart callback triggered');
      oauth2.ready().then(_client => {
        console.log(_client.getState());
        setClient(_client);
        setSmartLoading(false);
        setSmartAuthentication(true);
        history.push(DEFAULT_LASTPATH);
      });
    } else {
      console.error(`unknown mode used: ${mode}`);
    }
  }, [setClient, setSmartAuthentication, clientId, mode, history]);

  const dispatch = useDispatch();

  // logging out ?
  const loggingOut = useSelector(state => state.auth.status.loggingOut);

  useInjectSaga({ key: SettingsNamespace, saga: settingsSaga });
  useInjectSaga({ key: SmartAppsSettingName, saga: smartAppsSaga });

  const anyAuthenticationStillLoading = authenticationLoading || smartLoading;

  const handlePostLogin = useCallback(async () => {
    const idTokenData = client.getIdToken();
    // clear any forced timeout marker
    dispatch(setForcedLogout(false));
    // Set user profile data to state
    dispatch(setUserDataSmart(idTokenData));

    const accessTokenRaw = client.getAuthorizationHeader();
    const accessToken = accessTokenRaw.replace('Bearer ', '');
    const accessTokenData = jwtDecode(accessToken);
    Auth0Service.setSession(accessToken, accessTokenData.exp);
    dispatch(setSecurityData(accessTokenData));

    // Initialize FHIR Service
    FHIRService.initialize(client.getState('serverUrl'), accessTokenRaw, 'SMART');

    // Initialize ValueSet Service
    ValueSetService.initialize(FHIRService, 'SMART');

    // Set initial navTree state (this setups a clean tree regardless of potential downstream nav changes)
    // This must be done here instead of in the reducer becuase it is dependent on the access token scopes
    dispatch(setNavigation(navigationConfig(undefined, true)));
  }, [dispatch, client]);

  useEffect(() => {
    if (!anyAuthenticationStillLoading && smartAuthenticated && !authenticationIsDone) {
      setAuthenticationLoading(true);
      handlePostLogin().then(() => {
        setLoggedIn(true);
        setAuthenticationIsDone(true);
        setAuthenticationLoading(false);
      });
    }
  }, [
    setAuthenticationIsDone,
    handlePostLogin,
    setAuthenticationLoading,
    anyAuthenticationStillLoading,
    setLoggedIn,
    smartAuthenticated,
    authenticationIsDone,
  ]);

  if (!smartAuthenticated && loggingOut) {
    dispatch(setLoggingOut(false));
  }

  if (loggingOut) {
    return <NexusSplashScreen />;
  }

  // If the authentication phase is done
  // display children
  if (authenticationIsDone) {
    return <>{children}</>;
  }

  // If either auth0 or our post processing are currently loading
  // and the auth phase is not set to done
  // display a loading screen
  if (!authenticationIsDone) {
    return <NexusSplashScreen />;
  }

  // If auth0 is not loading and it does not believe you are logged in
  // -----redirect to the login page
  if (!smartLoading && !smartAuthenticated && !authenticationIsDone) {
    setAuthenticationIsDone(true);
    setLoggedIn(false);
  }

  return <>{children}</>;
}

SmartAuthManager.propTypes = {
  children: PropTypes.node,
  mode: PropTypes.string,
  clientId: PropTypes.string,
};

export default SmartAuthManager;
