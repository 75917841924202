import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth0Provider } from '@auth0/auth0-react';
import { auth0Scopes } from 'app/services/auth0Service/authScopes';
import authConfig from '../../services/auth0Service/auth0ServiceConfig';
import AuthContext from '../AuthenticationProviderContext';
import AuthenticationManager from './AuthenticationManager';

function Auth0ProviderWithHistory({ children }) {
  const history = useHistory();

  const onRedirectCallback = appState => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  const [authenticationIsDone, setAuthenticationIsDone] = useState(false);
  const [authenticationLoading, setAuthenticationLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <Auth0Provider
      domain={authConfig.domain}
      clientId={authConfig.clientId}
      redirectUri={authConfig.callbackUrl}
      onRedirectCallback={onRedirectCallback}
      scope={auth0Scopes}
      audience={authConfig.api_id}
    >
      <AuthContext.Provider
        value={{
          authenticationIsDone,
          authenticationLoading,
          loggedIn,
          setLoggedIn,
          setAuthenticationIsDone,
          setAuthenticationLoading,
          systemMode: 'auth0',
        }}
      >
        <AuthenticationManager>{children}</AuthenticationManager>
      </AuthContext.Provider>
    </Auth0Provider>
  );
}

Auth0ProviderWithHistory.propTypes = {
  children: PropTypes.node,
};

export default Auth0ProviderWithHistory;
