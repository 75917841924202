// Functions assisting with the creation of Reference resources
// http://hl7.org/fhir/R4/references.html

import { get, isPlainObject, isString } from 'lodash';
import { isAnyEmpty, isNonEmptyString } from 'app/utils/helpers';
import { isResource } from './resourceUtils';

export function createReference(resourceType, resourceId) {
  const fhirReference = {};
  fhirReference.reference = createReferenceString(resourceType, resourceId);
  fhirReference.type = resourceType;
  return fhirReference;
}

export function createReferenceString(resourceType, resourceId) {
  if (isNonEmptyString(resourceType) && isNonEmptyString(resourceId)) {
    return `${resourceType}/${resourceId}`;
  }
}

export function createReferenceFromResource(resource) {
  // extract type and id from resource
  if (isResource(resource)) {
    // isResource ensures that these properties are present
    const { resourceType, id } = resource;
    // abort if resource is missing type or id
    if (isAnyEmpty(resourceType, id)) return;
    // build reference resource
    const fhirReference = {};
    fhirReference.reference = `${resourceType}/${id}`;
    fhirReference.type = resource?.resourceType;
    return fhirReference;
  }
}

// Takes in a reference string, i.e. 'Patient/<guid>' and removes the Patient part
export function stripResourceFromReference(reference) {
  if (isString(reference)) {
    const strArr = reference.split('/');
    return strArr.length > 0 ? strArr[1] : strArr[0];
  }
}

// Takes in a reference resource and returns the id to the reference
export function extractResourceIdFromReference(referenceResource) {
  if (isPlainObject(referenceResource)) {
    return stripResourceFromReference(referenceResource?.reference);
  }
}

// compare two FHIR reference objects; return true if references match
export function isMatchingReference(reference1, reference2) {
  if (get(reference1, 'reference') === get(reference2, 'reference')) {
    // Reference.reference match; check type
    if (get(reference1, 'type') === get(reference2, 'type')) {
      return true;
    }
  }
  return false;
}
