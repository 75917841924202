//
// Constants used to handle nexus settings
//

import { NexusNamespace } from './stateConstants';

export const DIALOG_OPEN = `${NexusNamespace}/DIALOG_OPEN`;
export const DIALOG_CLOSE = `${NexusNamespace}/DIALOG_CLOSE`;
export const MESSAGE_HIDE = `${NexusNamespace}/MESSAGE_HIDE`;
export const MESSAGE_SHOW = `${NexusNamespace}/MESSAGE_SHOW`;
export const NAVBAR_OPEN_FOLDED = `${NexusNamespace}/NAVBAR_OPEN_FOLDED`;
export const NAVBAR_CLOSE_FOLDED = `${NexusNamespace}/NAVBAR_CLOSE_FOLDED`;
export const NAVBAR_TOGGLE_FOLDED = `${NexusNamespace}/NAVBAR_TOGGLE_FOLDED`;
export const NAVBAR_TOGGLE_MOBILE = `${NexusNamespace}/NAVBAR_TOGGLE_MOBILE`;
export const NAVBAR_OPEN_MOBILE = `${NexusNamespace}/NAVBAR_OPEN_MOBILE`;
export const NAVBAR_CLOSE_MOBILE = `${NexusNamespace}/NAVBAR_CLOSE_MOBILE`;
export const GET_NAVIGATION = `${NexusNamespace}/GET_NAVIGATION`;
export const SET_NAVIGATION = `${NexusNamespace}/SET_NAVIGATION`;
export const RESET_NAVIGATION = `${NexusNamespace}/RESET_NAVIGATION`;
export const SETTINGS_SET_SETTINGS = `${NexusNamespace}/SETTINGS_SET_SETTINGS`;
export const SETTINGS_SET_DEFAULT_SETTINGS = `${NexusNamespace}/SETTINGS_SET_DEFAULT_SETTINGS`;
export const SETTINGS_SET_INITIAL_SETTINGS = `${NexusNamespace}/SETTINGS_SET_INITIAL_SETTINGS`;
export const SETTINGS_RESET_DEFAULT_SETTINGS = `${NexusNamespace}/SETTINGS_RESET_DEFAULT_SETTINGS`;
