import { MESSAGE_HIDE, MESSAGE_SHOW } from '../../nexusConstants';

export function hideMessage() {
  return {
    type: MESSAGE_HIDE,
  };
}

export function showMessage(options) {
  return {
    type: MESSAGE_SHOW,
    options,
  };
}
export function showMessageNotification(message, variant = 'primary', duration = 6000) {
  return {
    type: MESSAGE_SHOW,
    options: {
      message, // text or html
      autoHideDuration: duration, // ms
      anchorOrigin: {
        vertical: 'bottom', // top bottom
        horizontal: 'right', // left center right
      },
      variant, // success error info warning primary secondary null
    },
  };
}

export function showMessageAlert(message, variant = 'primary', duration = 6000) {
  return {
    type: MESSAGE_SHOW,
    options: {
      message, // text or html
      autoHideDuration: duration, // ms
      anchorOrigin: {
        vertical: 'bottom', // top bottom
        horizontal: 'center', // left center right
      },
      variant, // success error info warning primary secondary null
    },
  };
}

export function showMessageError(error, variant = 'error', duration = 12000) {
  // Error Type: ${error.response.type}
  const message = `Error${error?.response?.status ? ` ${error?.response?.status}` : ''}: ${error?.message}`;
  return {
    type: MESSAGE_SHOW,
    options: {
      message, // text or html
      autoHideDuration: duration, // ms
      anchorOrigin: {
        vertical: 'bottom', // top bottom
        horizontal: 'center', // left center right
      },
      variant, // success error info warning primary secondary null
    },
  };
}

export function showMessageSuccess(message, duration = 6000) {
  return {
    type: MESSAGE_SHOW,
    options: {
      message, // text or html
      autoHideDuration: duration, // ms
      anchorOrigin: {
        vertical: 'bottom', // top bottom
        horizontal: 'center', // left center right
      },
      variant: 'success', // success error info warning primary secondary null
    },
  };
}
