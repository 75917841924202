import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { set } from 'lodash';
import queryString from 'query-string';
import { settingsSelector } from 'app/auth/store/reducers';
import AuthenticationProviderContext from 'app/auth/AuthenticationProviderContext';
import { Redirect, useLocation } from 'react-router-dom';
import { URL_LOGIN, URL_PRACTITIONERS_ALL } from 'app/utils/urlConstants';
import { DEFAULT_LASTPATH } from 'app/store/settingConstants';
import { showMessageError } from 'app/store/actions/nexus/message.actions';

function RedirectToLastPath() {
  const dispatch = useDispatch();
  const location = useLocation();
  const settings = useSelector(settingsSelector);
  const { loggedIn, authenticationIsDone } = useContext(AuthenticationProviderContext);

  useEffect(() => {
    const { error: errorTitle, error_description: errorDescription } = queryString.parse(location?.search);
    if (errorTitle || errorDescription) {
      // pack error into a standard Error object
      const loginError = new Error(errorDescription);
      // set error title
      set(loginError, 'response.status', errorTitle);
      // dispatch error message
      dispatch(showMessageError(loginError));
    }
  }, [dispatch, location]);

  if (authenticationIsDone && loggedIn) {
    if (settings?.global?.lastPath) {
      return <Redirect to={settings.global.lastPath || DEFAULT_LASTPATH} />;
    }
    // default redirect
    return <Redirect to={URL_PRACTITIONERS_ALL} />;
  }

  // If not logged in
  // redirect to login
  return <Redirect to={URL_LOGIN} />;
}

export default RedirectToLastPath;
