import { extractResourceId } from 'app/resources/resourceUtils';
import { requestPutOptions } from 'app/utils/request';
import urljoin from 'url-join';
import { errorIfUninitialized, query } from './core';
import { URL_ENCOUNTER } from './urlConstants';

/**
 * A function that allows for querying encounters. Add params to modify the request
 *
 * @param {array} params - An array of query strings. They will be joined with '&' and appended to the request URL
 * @returns promise
 */
export async function getEncounters(params = []) {
  errorIfUninitialized();

  const finalParams = params.length > 0 ? params : '_count=200';

  const paramsString = `?${finalParams.join('&')}`;
  const response = await query(`${URL_ENCOUNTER}${paramsString}`);
  return response;
}

/**
 * A function for creating an Encounter
 *
 * @param {object} encounter - A FHIR encounter object
 * @returns promise
 */
export async function createEncounter(encounter) {
  errorIfUninitialized();
  const url = urljoin(URL_ENCOUNTER, extractResourceId(encounter));
  const response = await query(url, requestPutOptions(encounter));
  return response;
}
