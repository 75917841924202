import { combineReducers } from 'redux';
import { prependSelectors, createSmartSelector } from 'app/utils/stateHelpers';
import user, { userSelectors } from './user.reducer';
import login from './login.reducer';
import register from './register.reducer';
import status from './authStatus.reducer';
import settings from './settings.reducer';

const authReducers = combineReducers({
  user,
  settings,
  login,
  status,
  register,
});

// Create selectors for this level
export const userSelector = createSmartSelector(state => state.user);
export const settingsSelector = createSmartSelector(state => state.settings);
export const loginSelector = createSmartSelector(state => state.login);
export const statusSelector = createSmartSelector(state => state.status);
export const registerSelector = createSmartSelector(state => state.register);

// update leaf selectors with current level info
prependSelectors(userSelectors, userSelector);

// re-export selectors for easy updating higher up the tree
export const authSelectors = [userSelector, settingsSelector, loginSelector, statusSelector, registerSelector];

export default authReducers;
