import { call, put, takeLatest } from 'redux-saga/effects';
import { cloneDeep, isEmpty, set } from 'lodash';
import jsonPatch from 'fast-json-patch';
import { requestWithToken, requestJSONPatchOptions, requestPatchOptions } from 'app/utils/request';
import { URL_API_METADATA_USER } from 'app/utils/urlConstants';
import { getLocalUserSettings } from 'app/utils/stateHelpers';
import { SET_USER_SETTINGS } from '../../constants';
import { setUserSettingsError, setLocalUserSettings } from '../actions/settings.actions';

export function* setUserSettingsSaga({ userSettings, path }) {
  const requestURL = `${URL_API_METADATA_USER}`;
  try {
    if (isEmpty(path)) {
      // NOTE: This path is only used when we're setting an entire userSettings object from scratch
      // set local settings first for fast UI response
      yield put(setLocalUserSettings(userSettings));
      // overwrite any existing settings with the passed userSettings
      const result = yield call(requestWithToken, requestURL, requestPatchOptions(userSettings));
      // yield put(setUserSettingsSuccess(userSettings));
      if (!result?.success) {
        throw new Error(`${result?.errorMessage}`);
      }
    } else {
      const originalUserSettings = getLocalUserSettings();
      const draftUserSettings = cloneDeep(originalUserSettings);
      // set passed userSettings to our new draft user settings
      yield call(set, draftUserSettings, path, userSettings);
      // generate JSON patches between original and draft
      const patchOps = jsonPatch.compare(originalUserSettings, draftUserSettings);
      if (patchOps.length > 0) {
        // set local settings first for fast UI response
        yield put(setLocalUserSettings(userSettings, path));
        // there are settings changes! send patch operations to the network
        const result = yield call(requestWithToken, requestURL, requestJSONPatchOptions(patchOps));
        if (!result?.success) {
          throw new Error(`${result?.errorMessage}`);
        }
      }
    }
  } catch (err) {
    yield put(setUserSettingsError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* userSettingsRootSaga() {
  yield takeLatest(SET_USER_SETTINGS, setUserSettingsSaga);
}
