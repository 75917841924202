import { showMessageError } from 'app/store/actions';
import {
  GET_SMARTAPPS,
  GET_SMARTAPPS_SUCCESS,
  GET_LAUNCHABLE_SMARTAPPS,
  GET_LAUNCHABLE_SMARTAPPS_SUCCESS,
  GET_LAUNCHABLE_SMARTAPPS_ERROR,
  SET_LAUNCHABLE_SMARTAPPS,
  CREATE_SMARTAPP,
  CREATE_SMARTAPP_SUCCESS,
  UPDATE_SMARTAPP,
  UPDATE_SMARTAPP_SUCCESS,
  DELETE_SMARTAPP,
  DELETE_SMARTAPP_SUCCESS,
  DELETE_SMARTAPP_ERROR,
} from '../constants';

// Get Smart Apps registered in the system
export function getSmartApps() {
  return {
    type: GET_SMARTAPPS,
  };
}

export function getSmartAppsSuccess(smartApps) {
  return {
    type: GET_SMARTAPPS_SUCCESS,
    smartApps,
  };
}

export function getSmartAppsError(error) {
  return dispatch => dispatch(showMessageError(error));
}

// Get list of launchable smartApps
export function getLaunchableSmartApps() {
  return {
    type: GET_LAUNCHABLE_SMARTAPPS,
  };
}

export function getLaunchableSmartAppsSuccess(smartApps) {
  return {
    // Set the new launchable smartApps to state
    type: GET_LAUNCHABLE_SMARTAPPS_SUCCESS,
    smartApps,
  };
}

export function getLaunchableSmartAppsError(error) {
  return dispatch => {
    dispatch({ type: GET_LAUNCHABLE_SMARTAPPS_ERROR });
    dispatch(showMessageError(error));
  };
}

// Set launchable smartApps to state
export function setLaunchableSmartApps(smartApps) {
  return {
    type: SET_LAUNCHABLE_SMARTAPPS,
    smartApps,
  };
}

// Create a new Smart App
export function createSmartApp(formData) {
  return {
    type: CREATE_SMARTAPP,
    formData,
  };
}

export function createSmartAppSuccess(smartAppData) {
  return {
    type: CREATE_SMARTAPP_SUCCESS,
    smartAppData,
  };
}

export function createSmartAppError(error) {
  return dispatch => dispatch(showMessageError(error));
  // return {
  //   type: CREATE_SMARTAPP_ERROR,
  //   error,
  // };
}

// Update a Smart App
export function updateSmartApp(formData) {
  return {
    type: UPDATE_SMARTAPP,
    formData,
  };
}

export function updateSmartAppSuccess(smartAppData) {
  return {
    type: UPDATE_SMARTAPP_SUCCESS,
    smartAppData,
  };
}

export function updateSmartAppError(error) {
  return dispatch => dispatch(showMessageError(error));
}

// Delete a Smart App
export function deleteSmartApp(appId) {
  return {
    type: DELETE_SMARTAPP,
    appId,
  };
}

export function deleteSmartAppSuccess(smartAppData) {
  return {
    type: DELETE_SMARTAPP_SUCCESS,
    smartAppData,
  };
}

export function deleteSmartAppError(error) {
  return {
    type: DELETE_SMARTAPP_ERROR,
    error,
  };
}
