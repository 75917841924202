const AUTH_CONFIG =
  process.env.NODE_ENV === 'development'
    ? {
        domain: process.env.REACT_APP_AUTH_DOMAIN,
        api_id: process.env.REACT_APP_AUTH_API_ID,
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
        callbackUrl: process.env.REACT_APP_AUTH_CALLBACK_URL,
      }
    : {
        domain: process.env.REACT_APP_AUTH_DOMAIN,
        api_id: process.env.REACT_APP_AUTH_API_ID,
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
        callbackUrl: process.env.REACT_APP_AUTH_CALLBACK_URL,
        configurationBaseUrl: process.env.REACT_APP_AUTH_CONFIG_BASE_URL,
      };

export default AUTH_CONFIG;
