import { cloneDeep, set, isEmpty } from 'lodash';
import { getDarkModeSettingValue, getLocalUserSettings } from 'app/utils/stateHelpers';
import { toggleUserSetting } from 'app/utils/settingsHelpers';
import { GlobalAppName } from 'app/store/stateConstants';
import {
  TOGGLE_DARK_MODE,
  GET_USER_SETTINGS_SUCCESS,
  GET_USER_SETTINGS_ERROR,
  SET_USER_SETTINGS,
  SET_USER_SETTINGS_ERROR,
  SET_LOCAL_USER_SETTINGS,
  SET_USER_LASTPATH,
} from '../../constants';

export function getUserSettingsSuccess(userSettings) {
  return {
    type: GET_USER_SETTINGS_SUCCESS,
    userSettings,
  };
}

export function getUserSettingsError(err) {
  return {
    type: GET_USER_SETTINGS_ERROR,
    err,
  };
}

// see saga for notes on why we include a userSettingSnapshot parameter
export function setUserSettings(userSettings, path) {
  return {
    type: SET_USER_SETTINGS,
    userSettings,
    path,
  };
}

export function setUserSettingsError(error) {
  return dispatch => {
    console.error(error);
    // doesn't change state, just broadcasts that setUserSettings failed
    dispatch({ type: SET_USER_SETTINGS_ERROR, error });
  };
}

export function setLocalUserSettings(userSettings, path) {
  return (dispatch, getState) => {
    const originalSettings = getState().auth?.settings;
    const newSettings = cloneDeep(originalSettings);
    if (!isEmpty(path)) {
      set(newSettings, path, userSettings);
    }
    dispatch({ type: SET_LOCAL_USER_SETTINGS, newSettings });
  };
}

// Toggle Dark Mode
// Dark Mode is currently a special case where we use a separate Action TOGGLE_DARK_MODE
// to trigger the theme system into switching themes. Our local and remote user setting
// state is then handled via toggleUserSetting.
export function toggleDarkMode() {
  return dispatch => {
    // // extract pre-toggle darkMode setting
    const darkMode = getDarkModeSettingValue();
    // dispatch TOGGLE_DARK_MODE to update the theme
    dispatch({ type: TOGGLE_DARK_MODE, value: !darkMode });
    // dispatch changes to local state and network
    toggleUserSetting(GlobalAppName, 'darkMode');
  };
}

// export function toggleSetting(settingPath) {
//   return {
//     type: TOGGLE_SETTING,
//     settingPath,
//   };
// }

export function setUserLastPath(path) {
  return dispatch => {
    // get settings before changing local state so setUserSettings can diff the original
    const originalSettings = getLocalUserSettings();
    // extract pre-set lastPath setting
    const { lastPath } = originalSettings?.global;
    // dispatch SET_USER_LASTPATH to update local state;
    dispatch({ type: SET_USER_LASTPATH, lastPath });
    // dispatch changes to network
    dispatch(setUserSettings(path, 'global.lastPath', originalSettings));
  };
}
