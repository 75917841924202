import { showMessageError } from 'app/store/actions';
import {
  GET_VALUESET,
  GET_VALUESET_SUCCESS,
  GET_VALUESET_BATCH,
  GET_VALUESET_BATCH_SUCCESS,
} from '../valueSet.constants';

// get individual valueSets
export function getValueSet(valueSetType) {
  return { type: GET_VALUESET, valueSetType };
}

export function getValueSetSuccess(valueSetType, values) {
  return { type: GET_VALUESET_SUCCESS, valueSetType, values };
}

export function getValueSetError(error) {
  return dispatch => dispatch(showMessageError(error));
}

// Get a batch of valuesets; optional function to call on success
export function getValueSetBatch(valueSetTypeArray, onSuccess) {
  return { type: GET_VALUESET_BATCH, valueSetTypeArray, onSuccess };
}

export function getValueSetBatchSuccess(valueSetTypes, batchEntries) {
  return { type: GET_VALUESET_BATCH_SUCCESS, valueSetTypes, batchEntries };
}

export function getValueSetBatchError(error) {
  return dispatch => dispatch(showMessageError(error));
}
