let ValueSetStore;

/**
 * Initializes the ValueSetService
 *
 * @param {string} baseUrl - The base URL of the FHIR server
 * @param {string} authHeader - The authorization header that should be used to access the FHIR server
 * @param {string} launchType - The launch type for the system ('auth0' | 'smart')
 */
export function initialize(fhirService, launchType) {
  ValueSetStore = {
    fhirService,
    launchType,
  };
}

export function getValueSetStore() {
  return ValueSetStore;
}
