import i18next from 'i18next';

// Centralizes our language loader
// domain is the area of the project sending locale objects
// locales is an array of objects to be added to the translation engine
export default function Translate(domain, locales) {
  locales.forEach(item => {
    i18next.addResourceBundle(item.locale, domain, item.translation);
  });
}
