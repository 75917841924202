import React from 'react';
import { authScopes } from 'app/services/auth0Service/authScopes';

export const SmartAppsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  requireAuthentication: true,
  routes: [
    {
      path: ['/admin/smartapps/:smartAppId', '/admin/smartapps'],
      component: React.lazy(() => import('app/content/settings/SmartApps')),
      scopes: [
        authScopes.view.smartAppsManage,
        authScopes.sofApp.create,
        authScopes.sofApp.read,
        authScopes.sofApp.update,
        authScopes.sofApp.delete,
      ],
    },
  ],
};
