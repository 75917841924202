const userProfile = `openid profile email`;
const smartOnFhirBase = `${userProfile} launch fhirUser`;
const auth0Base = `${userProfile} user_metadata app_metadata picture`;

export const authScopes = {
  view: {
    patientList: 'view:patient-list',
    patientAdd: 'view:add-patient',
    practitionerList: 'view:practitioner-list',
    practitionerAdd: 'view:add-practitioner',
    carePlanList: 'view:careplan-list',
    myCareTeams: 'view:my-careteams',
    carePlanNew: 'view:careplan-new',
    encounterList: 'view:encounter-list',
    encounterCreate: 'view:encounter-create',
    appointmentList: 'view:appointment-list',
    appointmentCreate: 'view:appointment-create',
    reports: 'view:reports',
    smartAppsManage: 'view:smart-apps-manage',
    usersManage: 'view:user-manage',
    organizationList: 'view:organization-list',
    organizationAdd: 'view:organization-add',
  },
  patient: {
    read: 'user/Patient.read',
    write: 'user/Patient.write',
  },
  sofApp: {
    create: 'create:SoF-App',
    read: 'read:SoF-App',
    update: 'update:SoF-App',
    delete: 'delete:SoF-App',
  },
  launchableSoFApp: {
    read: 'read:Launchable-SoF-App',
  },
  practitioner: {
    read: 'user/Practitioner.read',
    write: 'user/Practitioner.write',
  },
  practitionerRole: {
    read: 'user/PractitionerRole.read',
    write: 'user/PractitionerRole.write',
  },
  appointment: {
    read: 'user/Appointment.read',
    write: 'user/Appointment.write',
  },
  location: {
    read: 'user/Location.read',
    write: 'user/Location.write',
  },
  communication: {
    read: 'user/Communication.read',
    write: 'user/Communication.write',
  },
  encounter: {
    read: 'user/Encounter.read',
    write: 'user/Encounter.write',
  },
  goal: {
    read: 'user/Goal.read',
    write: 'user/Goal.write',
  },
  condition: {
    read: 'user/Condition.read',
    write: 'user/Condition.write',
  },
  observation: {
    read: 'user/Observation.read',
    write: 'user/Observation.write',
  },
  organization: {
    read: 'user/Organization.read',
    write: 'user/Organization.write',
  },
  carePlan: {
    read: 'user/CarePlan.read',
    write: 'user/CarePlan.write',
  },
  careTeam: {
    read: 'user/CareTeam.read',
    write: 'user/CareTeam.write',
  },
  valueSet: {
    read: 'user/ValueSet.read',
  },
  user: {
    read: 'read:User',
  },
  userMetadata: {
    read: 'read:User-Metadata',
    update: 'update:User-Metadata',
  },
  currentUserMetadata: {
    read: 'read:Current-User-Metadata',
    update: 'update:Current-User-Metadata',
  },
  userSecurityMetadata: {
    read: 'read:Security-Metadata',
    update: 'update:Security-Metadata',
  },
  currentUserSecurityMetadata: {
    read: 'read:Current-Security-Metadata',
    update: 'update:Current-Security-Metadata',
  },
};

const leaves = tree =>
  Object.keys(tree).reduce((old, next) => {
    if (typeof tree[next] === 'string') {
      return [...old, tree[next]];
    }
    return [...old, ...leaves(tree[next])];
  }, []);

const SMART_SCOPE_REGEX = '^(user|patient)\\/(.+)\\.(read|write|\\*)$';

// Function to simplify requested scopes
// i.e. user/Patient.read + user/Patient.write => user/Patient.*
export const simplifySmartScopes = scopes => {
  // First produce a structure that is easier to work with
  const scopeStructure = scopes
    // filter to only smart scopes
    .filter(scope => scope.match(SMART_SCOPE_REGEX))
    // simplify scopes down to smallest possible list
    // ex "user/Patient.read user/Patient.write" => "user/Patient.*"
    .reduce((old, next) => {
      const [res, accessType] = next.split('.');

      // If we haven't come accross this resource save it
      if (old[res] === undefined) {
        old[res] = [accessType];
      } else {
        old[res].push(accessType);
      }

      return old;
    }, {});

  // Take each resource type  (user|patient)/(fhir resource) and produce the
  // smallest scope that mirrors the requested functionality
  // ex [read, *] => *
  // ex [write, read] => *
  // ex [write] => write
  return Object.keys(scopeStructure).map(key => {
    const val = scopeStructure[key];

    // if there is more than 1 scope the simplified form must be '*'
    if (val.length > 1) {
      return `${key}.*`;
    }

    // otherwise, return the scope as is
    return `${key}.${val[0]}`;
  });
};

const scopeArray = leaves(authScopes);

export const auth0Scopes = `${auth0Base} ${scopeArray.join(' ')}`;
// export const smartOnFhirScopes = `${smartOnFhirBase} ${simplifySmartScopes(scopeArray).join(' ')}`;
export const smartOnFhirScopes = `${smartOnFhirBase} user/*.read user/*.write`;
