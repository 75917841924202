import React from 'react';
import { authScopes } from 'app/services/auth0Service/authScopes';

export const UserManagerConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  requireAuthentication: true,
  routes: [
    {
      path: '/admin/users',
      exact: true,
      component: React.lazy(() => import('app/content/settings/Users')),
      scopes: [authScopes.view.usersManage, authScopes.user.read],
    },
  ],
};
