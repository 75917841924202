import { Button, darken, makeStyles, TextField, Typography } from '@material-ui/core';
import NexusAnimate from '@nexus/core/NexusAnimate';
import { smartOnFhirScopes } from 'app/services/auth0Service/authScopes';
import { showMessageSuccess } from 'app/store/actions';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
      theme.palette.primary.dark,
      0.5,
    )} 100%)`,
    color: theme.palette.primary.contrastText,
    height: '100%',
  },
}));

function SmartConfig() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = useCallback(
    data => () => {
      navigator.clipboard.writeText(data);

      dispatch(showMessageSuccess('Copied successfully!', 1000));
    },
    [dispatch],
  );

  const scopes = smartOnFhirScopes;
  const launchUrl = `${window.location.origin}/smart/<client id or mapped id>/launch`;
  const redirectUrl = `${window.location.origin}/smart/<client id or mapped id>/callback`;

  return (
    <div className={clsx(classes.root, 'p-24')}>
      <NexusAnimate animation="transition.fadeIn">
        <div className="flex flex-col p-32 w-full md:p-64">
          <img className="w-512 mb-48" src="assets/images/logos/CareNexus-logo-full-dark.svg" alt="logo" />

          <Typography variant="h4" color="inherit" className="font-light">
            SMART Configuration Values:
          </Typography>

          <div className="flex gap-16 w-full">
            <TextField
              name="scopes"
              className="mt-16 w-full"
              label="SMART Scopes"
              value={scopes}
              color="secondary"
              variant="outlined"
              onClick={event => {
                event.target.select();
              }}
            />
            <Button
              id="copy-scopes"
              className="mt-16 normal-case"
              color="secondary"
              variant="contained"
              onClick={handleClick(scopes)}
            >
              Copy
            </Button>
          </div>

          <div className="flex gap-16 w-full">
            <TextField
              name="launchUrl"
              className="mt-24 w-full"
              label="Launch URL"
              value={launchUrl}
              color="secondary"
              variant="outlined"
              onClick={event => {
                event.target.select();
              }}
            />
            <Button
              id="copy-launch-url"
              className="mt-24 normal-case"
              color="secondary"
              variant="contained"
              onClick={handleClick(launchUrl)}
            >
              Copy
            </Button>
          </div>

          <div className="flex gap-16 w-full">
            <TextField
              name="redirectUrl"
              className="mt-24 w-full"
              label="Redirect URL"
              value={redirectUrl}
              color="secondary"
              variant="outlined"
              onClick={event => {
                event.target.select();
              }}
            />
            <Button
              id="copy-redirect-url"
              className="mt-24 normal-case"
              color="secondary"
              variant="contained"
              onClick={handleClick(redirectUrl)}
            >
              Copy
            </Button>
          </div>

          <Button
            className="text-20 normal-case max-w-192 mt-24"
            onClick={() => history.push('/login')}
            variant="contained"
          >
            To Home
          </Button>
        </div>
      </NexusAnimate>
    </div>
  );
}

export default SmartConfig;
