import React from 'react';
import { authRoles } from 'app/auth';
import SmartConfigPage from './index';

export const SmartConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  requireAuthentication: false,
  routes: [
    {
      exact: true,
      path: '/smart/config',
      component: () => <SmartConfigPage />,
    },
  ],
};
