import { isArray } from 'lodash';
import 'translations/navigation';
import { authScopes, simplifySmartScopes } from 'app/services/auth0Service/authScopes';
import { tokenDataSelector } from 'app/auth/store/reducers/user.reducer';
import { store } from 'app/App';

const navDashboard = {
  id: 'group_dashboard',
  title: 'Dashboard',
  translate: 'DASHBOARD',
  type: 'group',
  icon: 'dashboard',
  children: [
    {
      id: 'item_dashboard',
      title: 'My Dashboard',
      translate: 'MY_DASHBOARD',
      type: 'item',
      icon: 'dashboard',
      url: '/dashboard',
    },
  ],
};

const navApps = {
  id: 'group_applications',
  title: 'Applications',
  translate: 'APPLICATIONS',
  type: 'group',
  icon: 'apps',
  children: [
    {
      id: 'collapse_patients',
      title: 'Patients',
      translate: 'PATIENTS',
      type: 'collapse',
      icon: 'contactsoutlined',
      // url: '/app/patients',
      children: [
        {
          id: 'item_list_patients',
          title: 'List Patients',
          translate: 'LIST_PATIENTS',
          type: 'item',
          icon: 'view_list',
          url: '/app/patients',
          urlAlt: ['/app/patients/edit'],
          urlExclude: ['/app/patients/add'],
          scopes: [authScopes.view.patientList, authScopes.patient.read],
        },
        {
          id: 'item_add_patient',
          title: 'Add a Patient',
          translate: 'ADD_PATIENT',
          type: 'item',
          icon: 'library_add',
          url: '/app/patients/add',
          exact: true,
          scopes: [authScopes.view.patientAdd, authScopes.patient.read, authScopes.patient.write],
        },
      ],
    },
    {
      id: 'collapse_practitioners',
      title: 'Practitioners',
      translate: 'PRACTITIONERS',
      type: 'collapse',
      icon: 'fa:faUserMd',
      // url: '/app/practitioners',
      children: [
        {
          id: 'item_list_practitioners',
          title: 'List Practitioners',
          translate: 'LIST_PRACTITIONERS',
          type: 'item',
          icon: 'view_list',
          url: '/app/practitioners',
          urlAlt: ['/app/practitioners/edit'],
          urlExclude: ['/app/practitioners/add'],
          scopes: [authScopes.view.practitionerList, authScopes.practitioner.read],
        },
        {
          id: 'item_add_practitioner',
          title: 'Add a Practitioner',
          translate: 'ADD_PRACTITIONER',
          type: 'item',
          icon: 'library_add',
          url: '/app/practitioners/add',
          exact: true,
          scopes: [authScopes.view.practitionerAdd, authScopes.practitioner.read, authScopes.practitioner.write],
        },
      ],
    },
    {
      id: 'collapse_careplans',
      title: 'Care Plans',
      translate: 'CARE_PLANS',
      type: 'collapse',
      icon: 'people',
      // url: '/app/careplans',
      children: [
        {
          id: 'item_list_careplans',
          title: 'List Care Plans',
          translate: 'LIST_CAREPLANS',
          type: 'item',
          icon: 'view_list',
          url: '/app/careplans',
          urlAlt: ['/app/careplans/edit'],
          urlExclude: ['/app/careplans/new', '/app/careplans/careteams'],
          scopes: [
            authScopes.view.carePlanList,
            authScopes.patient.read,
            authScopes.condition.read,
            authScopes.goal.read,
            authScopes.careTeam.read,
            authScopes.carePlan.read,
            authScopes.practitioner.read,
          ],
        },
        {
          id: 'item_create_careplan',
          title: 'New Care Plan',
          translate: 'NEW_CAREPLAN',
          type: 'item',
          icon: 'library_add',
          url: '/app/careplans/new',
          exact: true,
          scopes: [
            authScopes.view.carePlanNew,
            authScopes.carePlan.read,
            authScopes.carePlan.write,
            authScopes.careTeam.read,
            authScopes.careTeam.write,
            authScopes.goal.read,
            authScopes.goal.write,
            authScopes.practitioner.read,
            authScopes.patient.read,
            authScopes.condition.read,
            authScopes.condition.write,
          ],
        },
      ],
    },
    {
      id: 'collapse_appointments',
      title: 'Appointments',
      translate: 'APPOINTMENTS',
      type: 'collapse',
      icon: 'schedule',
      children: [
        {
          id: 'item_list_appointments',
          title: 'List Appointments',
          translate: 'LIST_APPOINTMENTS',
          type: 'item',
          icon: 'view_list',
          url: '/app/appointments',
          urlAlt: ['/app/appointments/edit'],
          urlExclude: ['/app/appointments/new'],
          scopes: [
            authScopes.view.appointmentList,
            authScopes.appointment.read,
            authScopes.patient.read,
            authScopes.practitioner.read,
          ],
        },
        // {
        //   id: 'item_create_appointment',
        //   title: 'Create Appointment',
        //   translate: 'CREATE_APPOINTMENT',
        //   type: 'item',
        //   icon: 'library_add',
        //   url: '/app/appointments/new',
        //   exact: false,
        //   scopes: [authScopes.view.practitionerList],
        // },
      ],
    },
    {
      id: 'collapse_encounters',
      title: 'Encounters',
      translate: 'ENCOUNTERS',
      type: 'collapse',
      icon: 'fa:faHandshake',
      // url: '/app/practitioners',
      children: [
        {
          id: 'item_list_encounters',
          title: 'List Encounters',
          translate: 'LIST_ENCOUNTERS',
          type: 'item',
          icon: 'view_list',
          url: '/app/encounters',
          urlAlt: ['/app/encounters/edit'],
          urlExclude: ['/app/encounters/new'],
          scopes: [
            authScopes.view.encounterList,
            authScopes.encounter.read,
            authScopes.location.read,
            authScopes.patient.read,
          ],
        },
        {
          id: 'item_create_encounter',
          title: 'Create Encounter',
          translate: 'CREATE_ENCOUNTER',
          type: 'item',
          icon: 'library_add',
          url: '/app/encounters/new',
          exact: false,
          scopes: [
            authScopes.view.encounterCreate,
            authScopes.encounter.read,
            authScopes.encounter.write,
            authScopes.communication.read,
            authScopes.communication.write,
          ],
        },
      ],
    },
  ],
};

const navSmartAppsHeader = {
  id: 'group_smartapps',
  title: 'SMART Apps',
  translate: 'SMART_APPS',
  type: 'group',
  icon: 'apps',
};

const navAdmin = {
  id: 'group_admin',
  title: 'Administration',
  translate: 'ADMINISTRATION',
  type: 'group',
  icon: 'apps',
  children: [
    {
      id: 'item_reports',
      title: 'Reports',
      translate: 'REPORTS',
      type: 'item',
      icon: 'assessment',
      url: '/admin/reports',
      scopes: [authScopes.view.reports],
    },
    {
      id: 'item_managesmartapps',
      title: 'Manage SMART Apps',
      translate: 'MANAGE_SMART_APPS',
      type: 'item',
      icon: 'build',
      url: '/admin/smartapps',
      scopes: [
        authScopes.view.smartAppsManage,
        authScopes.sofApp.read,
        authScopes.sofApp.update,
        authScopes.sofApp.delete,
        authScopes.sofApp.create,
      ],
    },
    {
      id: 'manage_users',
      title: 'Manage Users',
      translate: 'MANAGE_USERS',
      type: 'item',
      icon: 'people',
      url: '/admin/users',
      scopes: [
        authScopes.view.usersManage,
        authScopes.user.read,
        authScopes.userMetadata.read,
        authScopes.userSecurityMetadata.read,
        authScopes.userSecurityMetadata.update,
      ],
    },
    {
      id: 'collapse_organizations',
      title: 'Organizations',
      translate: 'ORGANIZATIONS',
      type: 'collapse',
      icon: 'local_hospital',
      // url: '/app/organizations',
      // scopes: [authScopes.view.organizationList],
      children: [
        {
          id: 'item_list_organization',
          title: 'List Organizations',
          translate: 'LIST_ORGANIZATIONS',
          type: 'item',
          icon: 'view_list',
          url: '/app/organizations',
          urlAlt: ['/app/organizations/edit'],
          urlExclude: ['/app/organizations/add'],
          scopes: [authScopes.view.organizationList, authScopes.organization.read],
        },
        {
          id: 'item_add_organization',
          title: 'Add an Organization',
          translate: 'ADD_ORGANIZATION',
          type: 'item',
          icon: 'library_add',
          url: '/app/organizations/add',
          exact: true,
          scopes: [authScopes.view.organizationAdd, authScopes.organization.read, authScopes.organization.write],
        },
      ],
    },
  ],
};

// 'scopeToMatch' is the scope of the resource while 'scope' is the scope the user has
const smartScopeMatchPredicate = scopeToMatch => scope => {
  const [matchScopeResource, matchScopePermission] = scopeToMatch.split('/')[1].split('.');
  const [scopeResource, scopePermission] = scope.split('/')[1].split('.');

  if (scopeResource !== matchScopeResource && scopeResource !== '*') {
    return false;
  }

  if (scopePermission !== matchScopePermission && scopePermission !== '*') {
    return false;
  }

  return true;
};

const pruneNavTreeUsingScopes = navTree => {
  const state = store.getState();
  const scopeString = tokenDataSelector(state)?.scope;
  const scopes = scopeString ? scopeString.split(' ') : [];
  const simpleScopes = simplifySmartScopes(scopes);

  const loginType = state?.auth?.user?.from;

  return navTree.reduce((old, item) => {
    // Use the scopes property, if it exists, to determine if an item is filtered
    if (item.scopes !== undefined) {
      if (loginType !== 'auth0') {
        const itemSimpleScopes = simplifySmartScopes(item.scopes);

        // If there are no smart scopes the module requires CN 1st party scopes
        // therefore it should be filtered
        if (itemSimpleScopes.length === 0) {
          return old;
        }

        if (
          scopeString === undefined ||
          itemSimpleScopes.every(scope => simpleScopes.some(smartScopeMatchPredicate(scope)))
        ) {
          return [...old, item];
        }

        return old;
      }

      if (item.scopes.every(scope => scopes.includes(scope))) {
        return [...old, item];
      }
      return old;
    }

    // If the item has no children then don't filter it
    if (item.children === undefined) {
      return [...old, item];
    }

    // Apply the same rules to children and, if afterwards you have no children,
    // remove the node
    const newChildren = pruneNavTreeUsingScopes(item.children);
    if (newChildren.length === 0) {
      return old;
    }

    item.children = newChildren;
    return [...old, item];
  }, []);
};

export function navigationConfig(smartApps, filterUsingScopes = false) {
  let newNav;
  // Ensure we have smartApps; smartApps[0] check was added due to what appears to be webpack passing an empty array on initial load
  if (smartApps !== undefined && isArray(smartApps) && smartApps[0] !== undefined) {
    // filter smartapps to those launchable without context
    const rootNavSmartApps = smartApps.filter(smartApp => smartApp?.allowedLaunchContexts?.length === 0);
    // Convert launchable smartApp data to nav-friendly object array
    const navItems = smartAppToNav(rootNavSmartApps);
    // Attach natItems to smartApps parent
    newNav = appendNavItems(navSmartAppsHeader, navItems, 'smartapps');
  }

  let newNavMerged = [navDashboard, navApps, navAdmin];
  if (newNav) {
    newNavMerged.splice(2, 0, newNav);
  }

  if (filterUsingScopes) {
    newNavMerged = pruneNavTreeUsingScopes(newNavMerged);
  }

  return newNavMerged;
}

// Helper function to convert raw smartApp launch data into a nav item
function smartAppToNav(smartApps) {
  const nav = smartApps.map(_item => ({
    id: _item.clientId,
    title: _item.name,
    type: 'item',
    icon: _item.iconString,
    url: `/smartapp/${_item.clientId}`,
  }));

  return nav;
}

// Helper function to append navItems as children to a parent nav item
function appendNavItems(nav, navItems) {
  nav.children = [...navItems];
  return nav;
}

export default navigationConfig;
