import { red } from '@material-ui/core/colors';
import typography from './themes/typography';
import { careNexusPrimary, careNexusSecondary, backgroundLight, toolbarDark, footerDark } from './themes/colors';
import { tooltipDark, tooltipLight, button } from './themes/overrides';

export const careNexusLightTheme = {
  background: '#f2f4f6',
  paper: '#fbfdff',
};

const themesConfig = {
  mainLight: {
    palette: {
      type: 'light',
      primary: careNexusPrimary,
      secondary: careNexusSecondary,
      error: red,
      background: {
        default: careNexusLightTheme.background,
        paper: careNexusLightTheme.paper,
      },
    },
    overrides: { ...tooltipLight, ...button },
    typography,
    status: {
      danger: 'orange',
    },
  },
  toolbarLight: {
    palette: {
      type: 'light',
      primary: careNexusPrimary,
      secondary: careNexusSecondary,
      error: red,
      background: {
        default: backgroundLight[500],
        paper: backgroundLight[50],
      },
    },
    overrides: { ...tooltipLight, ...button },
    typography,
    status: {
      danger: 'orange',
    },
  },
  footerLight: {
    palette: {
      type: 'light',
      primary: careNexusPrimary,
      secondary: careNexusSecondary,
      error: red,
      background: {
        default: careNexusLightTheme.background,
        paper: careNexusLightTheme.paper,
      },
    },
    overrides: { ...tooltipLight, ...button },
    typography,
    status: {
      danger: 'orange',
    },
  },
  mainDark: {
    palette: {
      type: 'dark',
      primary: careNexusPrimary,
      secondary: careNexusSecondary,
      error: red,
      background: {
        paper: careNexusPrimary[700],
        default: careNexusPrimary[900],
      },
    },
    overrides: { ...tooltipDark, ...button },
    typography,
    status: {
      danger: 'orange',
    },
  },
  toolbarDark: {
    palette: {
      type: 'dark',
      primary: toolbarDark,
      secondary: careNexusSecondary,
      error: red,
      background: {
        default: toolbarDark[500],
        paper: toolbarDark[500],
      },
    },
    overrides: { ...tooltipDark, ...button },
    status: {
      danger: 'orange',
    },
    typography,
  },
  footerDark: {
    palette: {
      type: 'dark',
      primary: footerDark,
      secondary: careNexusSecondary,
      error: red,
      background: {
        default: footerDark[500],
        paper: footerDark[500],
      },
    },
    overrides: { ...tooltipDark, ...button },
    status: {
      danger: 'orange',
    },
    typography,
  },
};

export default themesConfig;
