/* eslint-disable react-hooks/rules-of-hooks */
import { combineReducers } from 'redux';
import { useSelector } from 'react-redux';
import { createSmartSelector } from 'app/utils/stateHelpers';
import navigation from './navigation.reducer';
import settings from './settings.reducer';
import navbar from './navbar.reducer';
import message from './message.reducer';
import dialog from './dialog.reducer';

const nexusReducers = combineReducers({
  navigation,
  settings,
  navbar,
  message,
  dialog,
});

export const navigationSelector = createSmartSelector(store => store.navigation);
export const useNavigationSelector = () => useSelector(navigationSelector);

export const nexusSelectors = [navigationSelector];

export default nexusReducers;
