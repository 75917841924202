import {
  NAVBAR_OPEN_FOLDED,
  NAVBAR_CLOSE_FOLDED,
  NAVBAR_TOGGLE_FOLDED,
  NAVBAR_TOGGLE_MOBILE,
  NAVBAR_OPEN_MOBILE,
  NAVBAR_CLOSE_MOBILE,
} from '../../nexusConstants';

export function navbarToggleFolded() {
  return {
    type: NAVBAR_TOGGLE_FOLDED,
  };
}

export function navbarOpenFolded() {
  return {
    type: NAVBAR_OPEN_FOLDED,
  };
}

export function navbarCloseFolded() {
  return {
    type: NAVBAR_CLOSE_FOLDED,
  };
}

export function navbarToggleMobile() {
  return {
    type: NAVBAR_TOGGLE_MOBILE,
  };
}

export function navbarOpenMobile() {
  return {
    type: NAVBAR_OPEN_MOBILE,
  };
}

export function navbarCloseMobile() {
  return {
    type: NAVBAR_CLOSE_MOBILE,
  };
}
