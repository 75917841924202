import React from 'react';
import { authScopes } from 'app/services/auth0Service/authScopes';

export const EncounterConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  requireAuthentication: true,
  routes: [
    {
      path: '/app/encounters',
      exact: true,
      component: React.lazy(() => import('app/content/apps/Encounters/EncounterList')),
      scopes: [authScopes.view.encounterList, authScopes.encounter.read],
    },
    {
      path: '/app/encounters/edit/:encounterId',
      component: React.lazy(() => import('app/content/apps/Encounters/EncounterEditor')),
      scopes: [authScopes.view.encounterCreate, authScopes.encounter.read, authScopes.encounter.write],
    },
    {
      path: '/app/encounters/new',
      exact: false,
      component: React.lazy(() => import('app/content/apps/Encounters/EncounterEditor')),
      scopes: [authScopes.view.encounterCreate, authScopes.encounter.write],
    },
  ],
};
