const Typography = {
  fontFamily: `
  Source Sans Pro,
  Helvetica Neue,
  Arial,
  -apple-system,
  BlinkMacSystemFont,
  Roboto,
  Segoe UI,
  sans-serif,
  Apple Color Emoji,
  Segoe UI Emoji,
  Segoe UI Symbol`,
  fontSize: 16,
};

export default Typography;
