import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { OrganizationsConfig } from 'app/content/apps/Organizations/OrganizationsConfig';
import { PatientsConfig } from 'app/content/apps/Patients/PatientsConfig';
import { PractitionersConfig } from 'app/content/apps/Practitioners/PractitionersConfig';
import { CarePlanConfig } from 'app/content/apps/CarePlans/CarePlanConfig';
import { EncounterConfig } from 'app/content/apps/Encounters/EncounterConfig';
import { AppointmentConfig } from 'app/content/apps/Appointments/AppointmentConfig';
import SmartAppLoaderConfig from 'app/content/apps/SmartAppLoader/SmartAppLoaderConfig';
import { SmartAppsConfig } from 'app/content/settings/SmartApps/SmartAppsConfig';
import { LoginConfig } from 'app/content/pages/login/LoginConfig';
import { LogoutConfig } from 'app/content/pages/logout/LogoutConfig';
import { CallbackConfig } from 'app/content/pages/callback/CallbackConfig';
import { ReportsConfig } from 'app/content/apps/Reports/ReportsConfig';
import { DashboardConfig } from 'app/content/apps/Dashboards/DashboardConfig';
import { TermsOfServiceConfig } from 'app/content/pages/termsOfService/TermsOfUseConfig';
import { PrivacyPolicyConfig } from 'app/content/pages/privacyPolicy/PrivacyPolicyConfig';
import { SmartConfig } from 'app/content/pages/smartConfig/SmartConfig';
import NexusUtils from '@nexus/utils';
import { DEFAULT_LASTPATH } from 'app/store/settingConstants';
import { URL_LOGIN } from 'app/utils/urlConstants';
import { UserManagerConfig } from 'app/content/settings/Users/UsersConfig';
import { statusSelector, settingsSelector } from 'app/auth/store/reducers';
import AuthContext from 'app/auth/AuthenticationProviderContext';

const routeConfigs = [
  DashboardConfig,
  PatientsConfig,
  PractitionersConfig,
  AppointmentConfig,
  CarePlanConfig,
  EncounterConfig,
  OrganizationsConfig,
  SmartAppLoaderConfig,
  SmartAppsConfig,
  LogoutConfig,
  LoginConfig,
  CallbackConfig,
  UserManagerConfig,
  ReportsConfig,
  TermsOfServiceConfig,
  PrivacyPolicyConfig,
  SmartConfig,
];

const routes = [
  ...NexusUtils.generateRoutesFromConfigs(routeConfigs, ['admin', 'staff', 'user']),
  {
    path: '/',
    component: () => <RedirectTo />,
    requireAuthentication: false,
  },
];

// React component to retrieve and render the user's last accessed path
function RedirectTo() {
  // retrieve last user path from local settings; this is set in src\app\layout\MainLayout\index.js
  const { global } = useSelector(settingsSelector);
  const { lastPath } = global;
  const { settingsLoaded } = useSelector(statusSelector);
  const { authenticationIsDone } = useContext(AuthContext);

  if (!authenticationIsDone) {
    // user is logged out; automatically redirect to URL_LOGIN
    return <Redirect to={URL_LOGIN} />;
  }

  if (!settingsLoaded) {
    // return null until the user's settings are loaded
    return null;
  }

  // TODO:: If the use does not have access to a 'lastPath' redirect them to DEFAULT_LASTPATH

  // user's settings are loaded and user is logged in; redirect to lastPath (or default, if lastPath is null)
  return <Redirect to={lastPath || DEFAULT_LASTPATH} />;
}

export default routes;
