export const URL_PATIENT = '/Patient';
export const URL_ENCOUNTER = '/Encounter';
export const URL_APPOINTMENT = '/Appointment';
export const URL_COMMUNICATION = '/Communication';
export const URL_CAREPLAN = '/CarePlan';
export const URL_CARETEAM = '/CareTeam';
export const URL_CONDITION = '/Condition';
export const URL_PRACTITIONER = '/Practitioner';
export const URL_ORGANIZATION = '/Organization';
export const URL_RELATEDPERSON = '/RelatedPerson';
export const URL_VALUESET = '/ValueSet';
