import { LOGIN_SUCCESS, LOGIN_ERROR } from '../../constants';

const initialState = {
  success: false,
  error: {
    username: null,
    password: null,
  },
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return {
        ...initialState,
        success: true,
      };
    }
    case LOGIN_ERROR: {
      return {
        success: false,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default login;
