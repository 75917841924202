import { bundleBatchValueSetBuilder } from 'app/resources/ValueSetResource';
import { valueSets } from './localRepository';
import { getValueSetStore } from './core';
import { queryTerminologyServer } from './terminologyLookup';

export async function getValueSets(requestedValueSets) {
  // Warn if any valueSets have the 'allowPreloading' flag set to false
  requestedValueSets
    .filter(set => set.allowPreloading !== undefined && !set.allowPreloading)
    .forEach(set =>
      console.warn(
        `ValueSet '${set.id}' is attempting to be pre-loaded even though it has 'allowPreloading' set to false. Make sure you really meant to pre-load this ValueSet`,
      ),
    );

  if (getValueSetStore().launchType === 'SMART') {
    return smartLookupStrategy(requestedValueSets);
  }

  return defaultLookupStrategy(requestedValueSets);
}

// This function produces a bundle with the results in the same order as they
// were requested
function valueSetsToSuccessBundle(originalOrder, _valueSets) {
  // Convert [{url: something}] => {"something": {url: something}}
  const valueSetMap = _valueSets.reduce((old, next) => {
    old[next.url] = next;
    return old;
  }, {});

  return {
    resourceType: 'Bundle',
    type: 'batch-response',
    entry: originalOrder
      // This is done to fix the ordering
      .map(setDefinition => valueSetMap[setDefinition.url])
      .map(set => ({
        response: {
          status: '200 OK',
        },
        resource: set,
      })),
  };
}

function getDifference(requested, current) {
  const existingValueSetUrls = current.map(set => set.url);

  return requested.filter(valueSetConst => !existingValueSetUrls.includes(valueSetConst.url));
}

async function getValueSetsFromConnectedFHIRServer(requestedValueSets) {
  const { fhirService } = getValueSetStore();
  const batchBundle = bundleBatchValueSetBuilder(requestedValueSets);
  const resolvedSets = [];

  try {
    // Batch will auto-fallback if it fails
    const response = await fhirService.batch(batchBundle);

    if (response.entry === undefined || response.entry.length !== requestedValueSets.length) {
      throw new Error(
        "Response while resolving value sets from FHIR server was missing 'entry' or has mismatched results",
      );
    }

    for (let i = 0; i < requestedValueSets.length; i++) {
      const requestedSet = requestedValueSets[i];
      const setResponse = response.entry[i];

      // Check if the response was 200
      if (setResponse.response.status === '200 OK') {
        const valueSet = setResponse.resource;

        // Set the url for use in getDifference
        valueSet.url = requestedSet.url;

        resolvedSets.push(valueSet);
      }
    }
  } catch (err) {
    console.error(err);
  }

  return resolvedSets;
}

async function getValueSetsFromCNTerminology(requestedValueSets) {
  const resolvedSets = [];
  // Create a request promise for each valueSet
  // Note: We don't use a batch request because remote term lookup on CN doesn't support batch
  const requests = requestedValueSets.map(set => queryTerminologyServer(set.relativePath));

  try {
    const responses = await Promise.all(requests);
    responses.forEach((response, i) => {
      const { url } = requestedValueSets[i];
      response.url = url;
      resolvedSets.push(response);
    });
  } catch (err) {
    console.error(err);
  }

  return resolvedSets;
}

function getLocalValueSets(missingValueSets) {
  return missingValueSets
    .map(set => {
      const resolvedSet = valueSets[set.key];
      if (resolvedSet !== undefined) {
        resolvedSet.url = set.url; // for use in getDifference
      }
      return resolvedSet;
    })
    .filter(x => x !== undefined);
}

const CN_TERM_LOOKUP = {
  name: 'CareNexus Terminology Lookup',
  operation: getValueSetsFromCNTerminology,
};

const FHIR_SERVER_LOOKUP = {
  name: 'FHIR Server Lookup',
  operation: getValueSetsFromConnectedFHIRServer,
};

const LOCAL_LOOKUP = {
  name: 'Local Lookup',
  operation: getLocalValueSets,
};

function createLookupStrategy(steps) {
  return async requestedValueSets => {
    const finalValueSets = [];
    let missingValueSets = requestedValueSets;

    // eslint-disable-next-line no-restricted-syntax
    for (const step of steps) {
      console.log(`Missing valuesets before performing step '${step.name}':`, missingValueSets);
      // eslint-disable-next-line no-await-in-loop
      const results = await Promise.resolve(step.operation(missingValueSets));
      results.forEach(set => finalValueSets.push(set));

      if (finalValueSets.length === requestedValueSets.length) {
        console.log('Found all valuesets: ', finalValueSets);
        return valueSetsToSuccessBundle(requestedValueSets, finalValueSets);
      }

      missingValueSets = getDifference(requestedValueSets, finalValueSets);
      console.log(`Missing valuesets after performing step '${step.name}': `, missingValueSets);
      console.log(`Still missing value sets after performing step '${step.name}'. Moving to next step`);
    }

    // We can only get here if we haven't found all of the valueSets
    throw new Error(`Failed to get ValueSets: ${missingValueSets.map(set => set.key).join(', ')}`);
  };
}

const smartLookupStrategy = createLookupStrategy([FHIR_SERVER_LOOKUP, CN_TERM_LOOKUP, LOCAL_LOOKUP]);
const defaultLookupStrategy = createLookupStrategy([FHIR_SERVER_LOOKUP, CN_TERM_LOOKUP, LOCAL_LOOKUP]);
