import React from 'react';
import PropTypes from 'prop-types';
import { Icon, IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { toggleQuickPanel } from './store/actions';

function QuickPanelToggleButton(props) {
  const dispatch = useDispatch();

  return (
    <IconButton className="w-64 h-64" onClick={() => dispatch(toggleQuickPanel())}>
      {props.children}
    </IconButton>
  );
}

QuickPanelToggleButton.defaultProps = {
  children: <Icon>format_list_bulleted</Icon>,
};

QuickPanelToggleButton.propTypes = {
  children: PropTypes.node,
};

export default QuickPanelToggleButton;
