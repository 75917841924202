// Colors generated using a Material Design Palette Generator:
// http://mcg.mbitson.com/#!?primary=%23425563&secondary=%23ff8200&primary800=%232b3b47&primary800900=%23101920&primary800800=%231a262f&primary50=%23e8ebec&themename=mcgtheme

// CareNexus's primary theme and logo color (500 is the default, the rest are generated)
export const careNexusPrimary = {
  50: '#e8ebec',
  100: '#c6ccd0',
  200: '#a1aab1',
  300: '#7b8892',
  400: '#5e6f7a',
  500: '#425563',
  600: '#3c4e5b',
  700: '#334451',
  800: '#2b3b47',
  900: '#1d2a35',
  A100: '#7bc0ff',
  A200: '#48a8ff',
  A400: '#1590ff',
  A700: '#0083fb',
  contrastDefaultColor: 'light',
};

// CareNexus's secondary or accent theme and logo color
export const careNexusSecondary = {
  50: '#fff0e0',
  100: '#ffdab3',
  200: '#ffc180',
  300: '#ffa84d',
  400: '#ff9526',
  500: '#ff8200',
  600: '#ff7a00',
  700: '#ff6f00',
  800: '#ff6500',
  900: '#ff5200',
  A100: '#ffffff',
  A200: '#fff6f2',
  A400: '#ffd0bf',
  A700: '#ffbda6',
  light: '#ffa84d', // [300]
  main: '#ff8200', // [500],
  dark: '#ff6f00', // [700],
  contrastDefaultColor: 'light',
};

// Based off of primary[50]
export const backgroundLight = {
  50: '#fcfdfd',
  100: '#f8f9f9',
  200: '#f4f5f6',
  300: '#eff1f2',
  400: '#ebeeef',
  500: '#e8ebec',
  600: '#e5e9ea',
  700: '#e2e5e7',
  800: '#dee2e4',
  900: '#d8dddf',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#ffffff',
  contrastDefaultColor: 'dark',
};

// Based off of careNexusPrimary[800] -> [800] -> [800]
export const toolbarDark = {
  50: '#e4e5e6',
  100: '#babec1',
  200: '#8d9397',
  300: '#5f676d',
  400: '#3c474e',
  500: '#1a262f',
  600: '#17222a',
  700: '#131c23',
  800: '#0f171d',
  900: '#080d12',
  A100: '#55aaff',
  A200: '#2290ff',
  A400: '#0077ee',
  A700: '#006ad4',
  contrastDefaultColor: 'light',
};

// Based off of careNexusPrimary[800] -> [900] -> [900]
export const footerDark = {
  50: '#e2e3e4',
  100: '#b7babc',
  200: '#888c90',
  300: '#585e63',
  400: '#343c41',
  500: '#101920',
  600: '#0e161c',
  700: '#0c1218',
  800: '#090e13',
  900: '#05080b',
  A100: '#5184ff',
  A200: '#1e60ff',
  A400: '#0044ea',
  A700: '#003dd0',
  contrastDefaultColor: 'light',
};

export const warning = {
  50: '#f3e0e0',
  100: '#e0b3b3',
  200: '#cc8080',
  300: '#b84d4d',
  400: '#a82626',
  500: '#990000',
  600: '#910000',
  700: '#860000',
  800: '#7c0000',
  900: '#6b0000',
  A100: '#ff9a9a',
  A200: '#ff6767',
  A400: '#ff3434',
  A700: '#ff1a1a',
  contrastDefaultColor: 'light',
};

export const success = {
  50: '#e7efe4',
  100: '#c3d6bb',
  200: '#9cbb8e',
  300: '#749f61',
  400: '#568b3f',
  500: '#38761d',
  600: '#326e1a',
  700: '#2b6315',
  800: '#245911',
  900: '#17460a',
  A100: '#94ff7d',
  A200: '#6aff4a',
  A400: '#40ff17',
  A700: '#2dfc00',
  contrastDefaultColor: 'light',
};
