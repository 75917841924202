import React from 'react';

const SmartAppLoaderConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  requireAuthentication: true,
  routes: [
    {
      path: '/smartapp/:smartAppId',
      component: React.lazy(() => import('app/content/apps/SmartAppLoader')),
    },
  ],
};

export default SmartAppLoaderConfig;
