// Helper functions for CareNexus
import { isString, isArray, isEmpty, has, isPlainObject, isRegExp, isNumber, isBoolean } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { differenceInMonths, getDaysInMonth, isDate } from 'date-fns';

export function displayAge(birth, target) {
  if (isDate(birth) && isDate(target)) {
    const months = differenceInMonths(target, birth);
    const birthSpan = {
      year: Math.floor(months / 12),
      month: Math.floor(months) % 12,
      day: Math.round((months % 1) * getDaysInMonth(target), 0),
    };
    // you can adjust below logic as your requirements by yourself
    if (birthSpan.year < 1 && birthSpan.month < 1) {
      return `${birthSpan.day} day${birthSpan.day > 1 ? 's' : ''}`;
    }
    if (birthSpan.year < 1) {
      return `${birthSpan.month} month${birthSpan.month > 1 ? 's ' : ' '}${birthSpan.day} day${
        birthSpan.day > 1 ? 's' : ''
      }`;
    }
    if (birthSpan.year < 2) {
      return `${birthSpan.year} year${birthSpan.year > 1 ? 's ' : ' '}${birthSpan.month} month${
        birthSpan.month > 1 ? 's ' : ''
      }`;
    }
    return `${birthSpan.year} year${birthSpan.year > 1 ? 's' : ''}`;
  }
  return '';
}

// https://stackoverflow.com/a/1862219/391076
export function stripNonDigits(strValue) {
  if (isString(strValue)) {
    return strValue.replace(/[^\d]/g, '');
  }
}

// https://stackoverflow.com/a/4993780/391076
export function stripDigits(strValue) {
  if (isString(strValue)) {
    return strValue.replace(/[0-9]/g, '');
  }
}

// takes in any number of string arguments and returns them as one string
export function joinStrings(...args) {
  const stringArgs = args.filter(arg => isString(arg));
  return stringArgs.join();
}

// because lodash isEmpty returns true for date objects, numbers and bools,
// we want to make extra sure that our value really is Empty
export function isEmptyEmpty(value) {
  return isEmpty(value) && !isDate(value) && !isNumber(value) && !isBoolean(value) && !isRegExp(value);
}

// determines if any passed arguments are empty
export function isAnyEmpty(...args) {
  if (isArray(args)) {
    for (let i = 0; i < args.length; i++) {
      if (isEmpty(args[i])) {
        return true;
      }
    }
    return false;
  }
  return isEmpty(args);
}

// determines if all passed array arguments are empty
export function isAllEmpty(...args) {
  if (isArray(args)) {
    for (let i = 0; i < args.length; i++) {
      if (!isEmpty(args[i])) {
        return false;
      }
    }
    return true;
  }
  return isEmpty(args);
}

// checks if a passed variable is a non-empty string
export function isNonEmptyString(value) {
  return isString(value) && !isEmpty(value);
}

// checks if a passed variable is a non-empty array
export function isNonEmptyArray(value) {
  return isArray(value) && !isEmpty(value);
}

// checks to see if array is an array of entirely string variables
export function isArrayOfStrings(array) {
  if (isArray(array)) {
    return array.every(element => isString(element));
  }
  // not an array, therefore not an array of strings
  return false;
}

// checks to see if array is an array of entirely string variables,
// and at least one of the array items is not empty
export function isNonEmptyArrayOfStrings(array) {
  if (isArray(array)) {
    const allStrings = isArrayOfStrings(array);
    if (allStrings) {
      return array.some(element => !isEmpty(element));
    }
  }
  // not an array, therefore not an array of strings
  return false;
}

// array join that skips empty strings
// https://stackoverflow.com/a/19903533/391076
export function joinNonEmpty(array, separator) {
  if (isArray(array) && isString(separator)) {
    return [...array].filter(val => val).join(separator);
  }
}

// take in a list of arguments, create a new array with non-empty values only
// borrows logic from lodash compact: https://github.com/lodash/lodash/blob/4.17.15/lodash.js#L6874
export function compactNonEmpty(...arrayItems) {
  let index = -1;
  let resIndex = 0;
  const result = [];
  const length = arrayItems?.length ? arrayItems.length : 0;

  // eslint-disable-next-line no-plusplus
  while (++index < length) {
    const value = arrayItems[index];
    if (!isEmptyEmpty(value)) {
      // eslint-disable-next-line no-plusplus
      result[resIndex++] = value;
    }
  }
  return result;
}

// check to see if an object has ALL of the paths included in pathArray
// returns true if all paths exist, false if not.
export function hasAll(object, pathArray) {
  if (isPlainObject(object) && isNonEmptyArray(pathArray)) {
    return pathArray.every(path => has(object, path));
  }
}

// check to see if an object has ANY of the paths included in pathArray
// returns true if any path exists, false if not.
export function hasAny(object, ...pathArray) {
  if (isPlainObject(object) && isNonEmptyArray(pathArray)) {
    return pathArray.some(path => has(object, path));
  }
}

export function createGuid() {
  return uuidv4();
}

export function stripHTML(str) {
  if (isString(str)) {
    return str.replace(/(<([^>]+)>)/gi, '');
  }
}

// takes an array of objects, looks for a path in each object,
// and returns a new array consisting only of that object property.
// filterEmpty will remove any objects that aren't found in the mapping loop
export function mapPropertyToArray(objectArray, path, filterEmpty) {
  if (isArray(objectArray) && isString(path)) {
    let propertyArray = objectArray.map(obj => {
      if (has(obj, path)) {
        return obj[path];
      }
      return undefined;
    });
    propertyArray = filterEmpty ? propertyArray.filter(val => !isEmpty(val)) : propertyArray;
    return propertyArray;
  }
}

export function parsePath(path) {
  if (isString(path)) {
    return path.split('/');
  }
  return [];
}
