import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AuthContext from '../AuthenticationProviderContext';
import SmartAuthManager from './SmartAuthManager';

function SMARTAuthProvider({ children, mode, clientId }) {
  const [authenticationIsDone, setAuthenticationIsDone] = useState(false);
  const [authenticationLoading, setAuthenticationLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        authenticationIsDone,
        authenticationLoading,
        loggedIn,
        setLoggedIn,
        setAuthenticationIsDone,
        setAuthenticationLoading,
        systemMode: 'SMART',
      }}
    >
      <SmartAuthManager mode={mode} clientId={clientId}>
        {children}
      </SmartAuthManager>
    </AuthContext.Provider>
  );
}

SMARTAuthProvider.propTypes = {
  children: PropTypes.node,
  mode: PropTypes.string,
  clientId: PropTypes.string,
};

export default SMARTAuthProvider;
