import { errorIfUninitialized, query } from './core';
import { URL_APPOINTMENT } from './urlConstants';

/**
 * A function that allows for querying appointments. Add params to modify the request
 *
 * @param {array} params - An array of query strings. They will be joined with '&' and appended to the request URL
 * @returns promise
 */
export async function getAppointments(params = []) {
  errorIfUninitialized();

  const finalParams = params.length > 0 ? params : '_count=200';

  const paramsString = `?${finalParams.join('&')}`;
  const response = await query(`${URL_APPOINTMENT}${paramsString}`);
  return response;
}
