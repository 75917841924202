import React from 'react';
import { authScopes } from 'app/services/auth0Service/authScopes';

export const OrganizationsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  requireAuthentication: true,
  routes: [
    {
      exact: true,
      path: '/app/organizations',
      component: React.lazy(() => import('app/content/apps/Organizations/OrganizationList')),
      scopes: [authScopes.view.organizationList, authScopes.organization.read],
    },
    {
      path: '/app/organizations/edit/:organizationId',
      component: React.lazy(() => import('app/content/apps/Organizations/OrganizationEditor')),
      scopes: [authScopes.view.organizationList, authScopes.organization.read, authScopes.organization.write],
    },
    {
      path: '/app/organizations/add',
      exact: true,
      component: React.lazy(() => import('app/content/apps/Organizations/OrganizationEditor')),
      scopes: [authScopes.view.organizationList, authScopes.organization.read, authScopes.organization.write],
    },
  ],
};
