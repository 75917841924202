import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import NexusNavBadge from '../../NexusNavBadge';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 48,
    '&.active': {
      backgroundColor: `${theme.palette.secondary.main}!important`,
      color: `${theme.palette.secondary.contrastText}!important`,
      pointerEvents: 'none',
      '& .nexus-list-item-text-primary': {
        color: 'inherit',
      },
      '& .nexus-list-item-icon': {
        color: 'inherit',
      },
    },
    '& .nexus-list-item-icon': {},
    '& .nexus-list-item-text': {
      padding: '0 0 0 16px',
    },
    color: theme.palette.text.primary,
    textDecoration: 'none!important',
  },
}));

function NexusNavHorizontalLink(props) {
  const classes = useStyles(props);
  const { item } = props;

  return useMemo(
    () => (
      <ListItem
        button
        component="a"
        href={item.url}
        target={item.target ? item.target : '_blank'}
        className={clsx('nexus-list-item', classes.root)}
        role="button"
      >
        {item.icon && (
          <Icon
            className={clsx('nexus-list-item-icon text-16 flex-shrink-0', item.iconClass)}
            color="action"
          >
            {item.icon}
          </Icon>
        )}

        <ListItemText
          className="nexus-list-item-text"
          primary={item.title}
          classes={{ primary: 'text-16 nexus-list-item-text-primary' }}
        />

        {item.badge && <NexusNavBadge className="ltr:ml-8 rtl:mr-8" badge={item.badge} />}
      </ListItem>
    ),
    [classes.root, item.badge, item.icon, item.iconClass, item.target, item.title, item.url]
  );
}

NexusNavHorizontalLink.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
    target: PropTypes.string,
  }),
};

NexusNavHorizontalLink.defaultProps = {};

const NavHorizontalLink = withRouter(memo(NexusNavHorizontalLink));

export default NavHorizontalLink;
