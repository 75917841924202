import {
  ADMINISTRATIVE_GENDER,
  APPOINTMENT_PARTICIPANT_REQUIRED,
  APPOINTMENT_STATUS,
  CARETEAM_STATUS,
  CARE_PLAN_INTENT,
  CONDITION_CLINICAL_STATUS,
  CONDITION_VERIFICATION_STATUS,
  CONTACT_POINT_SYSTEM,
  ENCOUNTER_PARTICIPANT_TYPE,
  ENCOUNTER_STATUS,
  GOAL_ACHIEVEMENT,
  GOAL_PRIORITY,
  GOAL_STATUS,
  IDENTIFIER_TYPE,
  OBSERVATION_CATEGORY,
  OBSERVATION_STATUS,
  REQUEST_STATUS,
  TERM_V3_ACT_ENCOUNTER_CODE,
  USCORE_BIRTHSEX,
  USCORE_CONDITION_CATEGORY,
  USCORE_NARRATIVE_STATUS,
  USCORE_OMB_ETHNICITY_CATEGORY,
  USCORE_OMB_RACE_CATEGORY,
  USCORE_USPS_STATE,
} from 'app/resources/store/valueSet.constants';

export const valueSets = {
  [CONDITION_CLINICAL_STATUS.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/condition-clinical',
          code: 'active',
          display: 'Active',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/condition-clinical',
          code: 'recurrence',
          display: 'Recurrence',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/condition-clinical',
          code: 'relapse',
          display: 'Relapse',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/condition-clinical',
          code: 'inactive',
          display: 'Inactive',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/condition-clinical',
          code: 'remission',
          display: 'Remission',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/condition-clinical',
          code: 'resolved',
          display: 'Resolved',
        },
      ],
    },
  },
  [CONDITION_VERIFICATION_STATUS.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/condition-ver-status',
          code: 'unconfirmed',
          display: 'Unconfirmed',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/condition-ver-status',
          code: 'provisional',
          display: 'Provisional',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/condition-ver-status',
          code: 'differential',
          display: 'Differential',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/condition-ver-status',
          code: 'confirmed',
          display: 'Confirmed',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/condition-ver-status',
          code: 'refuted',
          display: 'Refuted',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/condition-ver-status',
          code: 'entered-in-error',
          display: 'Entered in Error',
        },
      ],
    },
  },
  [USCORE_CONDITION_CATEGORY.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/condition-category',
          code: 'problem-list-item',
          display: 'Problem List Item',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/condition-category',
          code: 'encounter-diagnosis',
          display: 'Encounter Diagnosis',
        },
        {
          system: 'http://hl7.org/fhir/us/core/CodeSystem/condition-category',
          code: 'health-concern',
          display: 'Health Concern',
        },
      ],
    },
  },
  [GOAL_STATUS.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://hl7.org/fhir/goal-status',
          code: 'proposed',
          display: 'Proposed',
        },
        {
          system: 'http://hl7.org/fhir/goal-status',
          code: 'planned',
          display: 'Planned',
        },
        {
          system: 'http://hl7.org/fhir/goal-status',
          code: 'accepted',
          display: 'Accepted',
        },
        {
          system: 'http://hl7.org/fhir/goal-status',
          code: 'active',
          display: 'Active',
        },
        {
          system: 'http://hl7.org/fhir/goal-status',
          code: 'on-hold',
          display: 'On Hold',
        },
        {
          system: 'http://hl7.org/fhir/goal-status',
          code: 'completed',
          display: 'Completed',
        },
        {
          system: 'http://hl7.org/fhir/goal-status',
          code: 'cancelled',
          display: 'Cancelled',
        },
        {
          system: 'http://hl7.org/fhir/goal-status',
          code: 'entered-in-error',
          display: 'Entered in Error',
        },
        {
          system: 'http://hl7.org/fhir/goal-status',
          code: 'rejected',
          display: 'Rejected',
        },
      ],
    },
  },
  [GOAL_ACHIEVEMENT.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/goal-achievement',
          code: 'in-progress',
          display: 'In Progress',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/goal-achievement',
          code: 'improving',
          display: 'Improving',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/goal-achievement',
          code: 'worsening',
          display: 'Worsening',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/goal-achievement',
          code: 'no-change',
          display: 'No Change',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/goal-achievement',
          code: 'achieved',
          display: 'Achieved',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/goal-achievement',
          code: 'sustaining',
          display: 'Sustaining',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/goal-achievement',
          code: 'not-achieved',
          display: 'Not Achieved',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/goal-achievement',
          code: 'no-progress',
          display: 'No Progress',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/goal-achievement',
          code: 'not-attainable',
          display: 'Not Attainable',
        },
      ],
    },
  },
  [GOAL_PRIORITY.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/goal-priority',
          code: 'high-priority',
          display: 'High Priority',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/goal-priority',
          code: 'medium-priority',
          display: 'Medium Priority',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/goal-priority',
          code: 'low-priority',
          display: 'Low Priority',
        },
      ],
    },
  },
  [CARETEAM_STATUS.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://hl7.org/fhir/care-team-status',
          code: 'proposed',
          display: 'Proposed',
        },
        {
          system: 'http://hl7.org/fhir/care-team-status',
          code: 'active',
          display: 'Active',
        },
        {
          system: 'http://hl7.org/fhir/care-team-status',
          code: 'suspended',
          display: 'Suspended',
        },
        {
          system: 'http://hl7.org/fhir/care-team-status',
          code: 'inactive',
          display: 'Inactive',
        },
        {
          system: 'http://hl7.org/fhir/care-team-status',
          code: 'entered-in-error',
          display: 'Entered in Error',
        },
      ],
    },
  },
  [REQUEST_STATUS.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://hl7.org/fhir/request-status',
          code: 'draft',
          display: 'Draft',
        },
        {
          system: 'http://hl7.org/fhir/request-status',
          code: 'active',
          display: 'Active',
        },
        {
          system: 'http://hl7.org/fhir/request-status',
          code: 'on-hold',
          display: 'On Hold',
        },
        {
          system: 'http://hl7.org/fhir/request-status',
          code: 'revoked',
          display: 'Revoked',
        },
        {
          system: 'http://hl7.org/fhir/request-status',
          code: 'completed',
          display: 'Completed',
        },
        {
          system: 'http://hl7.org/fhir/request-status',
          code: 'entered-in-error',
          display: 'Entered in Error',
        },
        {
          system: 'http://hl7.org/fhir/request-status',
          code: 'unknown',
          display: 'Unknown',
        },
      ],
    },
  },
  [CARE_PLAN_INTENT.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://hl7.org/fhir/request-intent',
          code: 'proposal',
          display: 'Proposal',
        },
        {
          system: 'http://hl7.org/fhir/request-intent',
          code: 'order',
          display: 'Order',
        },
        {
          system: 'http://hl7.org/fhir/request-intent',
          code: 'option',
          display: 'Option',
        },
        {
          system: 'http://hl7.org/fhir/request-intent',
          code: 'plan',
          display: 'Plan',
        },
      ],
    },
  },
  [IDENTIFIER_TYPE.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
          code: 'DL',
          display: "Driver's license number",
          designation: [
            {
              language: 'nl',
              use: {
                system: 'http://terminology.hl7.org/CodeSystem/designation-usage',
                code: 'display',
              },
              value: 'Rijbewijsnummer',
            },
            {
              language: 'de',
              use: {
                system: 'http://terminology.hl7.org/CodeSystem/designation-usage',
                code: 'display',
              },
              value: 'Führerscheinnummer',
            },
          ],
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
          code: 'JHN',
          display: 'Jurisdictional health number (Canada)',
          designation: [
            {
              language: 'nl',
              use: {
                system: 'http://terminology.hl7.org/CodeSystem/designation-usage',
                code: 'display',
              },
              value: 'Rechtsgebied gezondheidsnummer (Canada)',
            },
          ],
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
          code: 'MCN',
          display: 'Microchip Number',
          designation: [
            {
              language: 'nl',
              use: {
                system: 'http://terminology.hl7.org/CodeSystem/designation-usage',
                code: 'display',
              },
              value: 'Microchip-nummer',
            },
          ],
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
          code: 'FILL',
          display: 'Filler Identifier',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
          code: 'PLAC',
          display: 'Placer Identifier',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
          code: 'NIIP',
          display: 'National Insurance Payor Identifier (Payor)',
          designation: [
            {
              language: 'nl',
              use: {
                system: 'http://terminology.hl7.org/CodeSystem/designation-usage',
                code: 'display',
              },
              value: 'Nationale verzekeraar betaler identificatie (Betaler)',
            },
            {
              language: 'de',
              use: {
                system: 'http://terminology.hl7.org/CodeSystem/designation-usage',
                code: 'display',
              },
              value: 'Vertragskassennummer (VKNR)',
            },
          ],
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
          code: 'SNO',
          display: 'Serial Number',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
          code: 'UDI',
          display: 'Universal Device Identifier',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
          code: 'ACSN',
          display: 'Accession ID',
          designation: [
            {
              language: 'nl',
              use: {
                system: 'http://terminology.hl7.org/CodeSystem/designation-usage',
                code: 'display',
              },
              value: 'Accession ID',
            },
          ],
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
          code: 'PPN',
          display: 'Passport number',
          designation: [
            {
              language: 'de',
              use: {
                system: 'http://terminology.hl7.org/CodeSystem/designation-usage',
                code: 'display',
              },
              value: 'Passnummer',
            },
            {
              language: 'nl',
              use: {
                system: 'http://terminology.hl7.org/CodeSystem/designation-usage',
                code: 'display',
              },
              value: 'Paspoortnummer',
            },
          ],
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
          code: 'EN',
          display: 'Employer number',
          designation: [
            {
              language: 'de',
              use: {
                system: 'http://terminology.hl7.org/CodeSystem/designation-usage',
                code: 'display',
              },
              value: 'Arbeitgebernummer',
            },
            {
              language: 'nl',
              use: {
                system: 'http://terminology.hl7.org/CodeSystem/designation-usage',
                code: 'display',
              },
              value: 'Werkgevernummer',
            },
          ],
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
          code: 'TAX',
          display: 'Tax ID number',
          designation: [
            {
              language: 'de',
              use: {
                system: 'http://terminology.hl7.org/CodeSystem/designation-usage',
                code: 'display',
              },
              value: 'Steueridentifikationsnummer',
            },
            {
              language: 'nl',
              use: {
                system: 'http://terminology.hl7.org/CodeSystem/designation-usage',
                code: 'display',
              },
              value: 'Belasting identificatie',
            },
          ],
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
          code: 'BRN',
          display: 'Breed Registry Number',
          designation: [
            {
              language: 'nl',
              use: {
                system: 'http://terminology.hl7.org/CodeSystem/designation-usage',
                code: 'display',
              },
              value: 'Dierenras-registernummer',
            },
          ],
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
          code: 'MD',
          display: 'Medical License number',
          designation: [
            {
              language: 'nl',
              use: {
                system: 'http://terminology.hl7.org/CodeSystem/designation-usage',
                code: 'display',
              },
              value: 'Geneeskundig licentienummer',
            },
          ],
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
          code: 'MR',
          display: 'Medical record number',
          designation: [
            {
              language: 'de',
              use: {
                system: 'http://terminology.hl7.org/CodeSystem/designation-usage',
                code: 'display',
              },
              value: 'Krankenaktennummer',
            },
            {
              language: 'nl',
              use: {
                system: 'http://terminology.hl7.org/CodeSystem/designation-usage',
                code: 'display',
              },
              value: 'Medisch dossiernummer',
            },
          ],
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
          code: 'SB',
          display: 'Social Beneficiary Identifier',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
          code: 'DR',
          display: 'Donor Registration Number',
          designation: [
            {
              language: 'nl',
              use: {
                system: 'http://terminology.hl7.org/CodeSystem/designation-usage',
                code: 'display',
              },
              value: 'Donorregistratienummer',
            },
          ],
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
          code: 'PRN',
          display: 'Provider number',
          designation: [
            {
              language: 'nl',
              use: {
                system: 'http://terminology.hl7.org/CodeSystem/designation-usage',
                code: 'display',
              },
              value: 'Zorgaanbieder/zorgverlener-nummer',
            },
          ],
        },
      ],
    },
  },
  [USCORE_NARRATIVE_STATUS.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://hl7.org/fhir/narrative-status',
          code: 'additional',
          display: 'additional',
        },
        {
          system: 'http://hl7.org/fhir/narrative-status',
          code: 'generated',
          display: 'generated',
        },
      ],
    },
  },
  [APPOINTMENT_STATUS.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://hl7.org/fhir/appointmentstatus',
          code: 'proposed',
          display: 'Proposed',
        },
        {
          system: 'http://hl7.org/fhir/appointmentstatus',
          code: 'waitlist',
          display: 'Waitlisted',
        },
        {
          system: 'http://hl7.org/fhir/appointmentstatus',
          code: 'pending',
          display: 'Pending',
        },
        {
          system: 'http://hl7.org/fhir/appointmentstatus',
          code: 'booked',
          display: 'Booked',
        },
        {
          system: 'http://hl7.org/fhir/appointmentstatus',
          code: 'arrived',
          display: 'Arrived',
        },
        {
          system: 'http://hl7.org/fhir/appointmentstatus',
          code: 'fulfilled',
          display: 'Fulfilled',
        },
        {
          system: 'http://hl7.org/fhir/appointmentstatus',
          code: 'cancelled',
          display: 'Cancelled',
        },
        {
          system: 'http://hl7.org/fhir/appointmentstatus',
          code: 'noshow',
          display: 'No Show',
        },
        {
          system: 'http://hl7.org/fhir/appointmentstatus',
          code: 'entered-in-error',
          display: 'Entered in error',
        },
        {
          system: 'http://hl7.org/fhir/appointmentstatus',
          code: 'checked-in',
          display: 'Checked In',
        },
      ],
    },
  },
  [APPOINTMENT_PARTICIPANT_REQUIRED.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://hl7.org/fhir/participantrequired',
          code: 'required',
          display: 'Required',
        },
        {
          system: 'http://hl7.org/fhir/participantrequired',
          code: 'optional',
          display: 'Optional',
        },
        {
          system: 'http://hl7.org/fhir/participantrequired',
          code: 'information-only',
          display: 'Information Only',
        },
      ],
    },
  },
  [USCORE_BIRTHSEX.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-AdministrativeGender',
          code: 'F',
          display: 'Female',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-AdministrativeGender',
          code: 'M',
          display: 'Male',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-NullFlavor',
          code: 'UNK',
          display: 'Unknown',
        },
      ],
    },
  },
  [ADMINISTRATIVE_GENDER.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://hl7.org/fhir/administrative-gender',
          code: 'male',
          display: 'Male',
        },
        {
          system: 'http://hl7.org/fhir/administrative-gender',
          code: 'female',
          display: 'Female',
        },
        {
          system: 'http://hl7.org/fhir/administrative-gender',
          code: 'other',
          display: 'Other',
        },
        {
          system: 'http://hl7.org/fhir/administrative-gender',
          code: 'unknown',
          display: 'Unknown',
        },
      ],
    },
  },
  [USCORE_OMB_ETHNICITY_CATEGORY.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'urn:oid:2.16.840.1.113883.6.238',
          code: '2186-5',
          display: 'Non Hispanic or Latino',
        },
        {
          system: 'urn:oid:2.16.840.1.113883.6.238',
          code: '2135-2',
          display: 'Hispanic or Latino',
        },
      ],
    },
  },
  [USCORE_OMB_RACE_CATEGORY.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'urn:oid:2.16.840.1.113883.6.238',
          code: '1002-5',
          display: 'American Indian or Alaska Native',
        },
        {
          system: 'urn:oid:2.16.840.1.113883.6.238',
          code: '2076-8',
          display: 'Native Hawaiian or Other Pacific Islander',
        },
        {
          system: 'urn:oid:2.16.840.1.113883.6.238',
          code: '2106-3',
          display: 'White',
        },
        {
          system: 'urn:oid:2.16.840.1.113883.6.238',
          code: '2028-9',
          display: 'Asian',
        },
        {
          system: 'urn:oid:2.16.840.1.113883.6.238',
          code: '2054-5',
          display: 'Black or African American',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-NullFlavor',
          code: 'ASKU',
          display: 'Asked but no answer',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-NullFlavor',
          code: 'UNK',
          display: 'Unknown',
        },
      ],
    },
  },
  [USCORE_USPS_STATE.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'https://www.usps.com/',
          code: 'AK',
          display: 'Alaska',
        },
        {
          system: 'https://www.usps.com/',
          code: 'AL',
          display: 'Alabama',
        },
        {
          system: 'https://www.usps.com/',
          code: 'AR',
          display: 'Arkansas',
        },
        {
          system: 'https://www.usps.com/',
          code: 'AS',
          display: 'American Samoa',
        },
        {
          system: 'https://www.usps.com/',
          code: 'AZ',
          display: 'Arizona',
        },
        {
          system: 'https://www.usps.com/',
          code: 'CA',
          display: 'California',
        },
        {
          system: 'https://www.usps.com/',
          code: 'CO',
          display: 'Colorado',
        },
        {
          system: 'https://www.usps.com/',
          code: 'CT',
          display: 'Connecticut',
        },
        {
          system: 'https://www.usps.com/',
          code: 'DC',
          display: 'District of Columbia',
        },
        {
          system: 'https://www.usps.com/',
          code: 'DE',
          display: 'Delaware',
        },
        {
          system: 'https://www.usps.com/',
          code: 'FL',
          display: 'Florida',
        },
        {
          system: 'https://www.usps.com/',
          code: 'FM',
          display: 'Federated States of Micronesia',
        },
        {
          system: 'https://www.usps.com/',
          code: 'GA',
          display: 'Georgia',
        },
        {
          system: 'https://www.usps.com/',
          code: 'GU',
          display: 'Guam',
        },
        {
          system: 'https://www.usps.com/',
          code: 'HI',
          display: 'Hawaii',
        },
        {
          system: 'https://www.usps.com/',
          code: 'IA',
          display: 'Iowa',
        },
        {
          system: 'https://www.usps.com/',
          code: 'ID',
          display: 'Idaho',
        },
        {
          system: 'https://www.usps.com/',
          code: 'IL',
          display: 'Illinois',
        },
        {
          system: 'https://www.usps.com/',
          code: 'IN',
          display: 'Indiana',
        },
        {
          system: 'https://www.usps.com/',
          code: 'KS',
          display: 'Kansas',
        },
        {
          system: 'https://www.usps.com/',
          code: 'KY',
          display: 'Kentucky',
        },
        {
          system: 'https://www.usps.com/',
          code: 'LA',
          display: 'Louisiana',
        },
        {
          system: 'https://www.usps.com/',
          code: 'MA',
          display: 'Massachusetts',
        },
        {
          system: 'https://www.usps.com/',
          code: 'MD',
          display: 'Maryland',
        },
        {
          system: 'https://www.usps.com/',
          code: 'ME',
          display: 'Maine',
        },
        {
          system: 'https://www.usps.com/',
          code: 'MH',
          display: 'Marshall Islands',
        },
        {
          system: 'https://www.usps.com/',
          code: 'MI',
          display: 'Michigan',
        },
        {
          system: 'https://www.usps.com/',
          code: 'MN',
          display: 'Minnesota',
        },
        {
          system: 'https://www.usps.com/',
          code: 'MO',
          display: 'Missouri',
        },
        {
          system: 'https://www.usps.com/',
          code: 'MP',
          display: 'Northern Mariana Islands',
        },
        {
          system: 'https://www.usps.com/',
          code: 'MS',
          display: 'Mississippi',
        },
        {
          system: 'https://www.usps.com/',
          code: 'MT',
          display: 'Montana',
        },
        {
          system: 'https://www.usps.com/',
          code: 'NC',
          display: 'North Carolina',
        },
        {
          system: 'https://www.usps.com/',
          code: 'ND',
          display: 'North Dakota',
        },
        {
          system: 'https://www.usps.com/',
          code: 'NE',
          display: 'Nebraska',
        },
        {
          system: 'https://www.usps.com/',
          code: 'NH',
          display: 'New Hampshire',
        },
        {
          system: 'https://www.usps.com/',
          code: 'NJ',
          display: 'New Jersey',
        },
        {
          system: 'https://www.usps.com/',
          code: 'NM',
          display: 'New Mexico',
        },
        {
          system: 'https://www.usps.com/',
          code: 'NV',
          display: 'Nevada',
        },
        {
          system: 'https://www.usps.com/',
          code: 'NY',
          display: 'New York',
        },
        {
          system: 'https://www.usps.com/',
          code: 'OH',
          display: 'Ohio',
        },
        {
          system: 'https://www.usps.com/',
          code: 'OK',
          display: 'Oklahoma',
        },
        {
          system: 'https://www.usps.com/',
          code: 'OR',
          display: 'Oregon',
        },
        {
          system: 'https://www.usps.com/',
          code: 'PA',
          display: 'Pennsylvania',
        },
        {
          system: 'https://www.usps.com/',
          code: 'PR',
          display: 'Puerto Rico',
        },
        {
          system: 'https://www.usps.com/',
          code: 'PW',
          display: 'Palau',
        },
        {
          system: 'https://www.usps.com/',
          code: 'RI',
          display: 'Rhode Island',
        },
        {
          system: 'https://www.usps.com/',
          code: 'SC',
          display: 'South Carolina',
        },
        {
          system: 'https://www.usps.com/',
          code: 'SD',
          display: 'South Dakota',
        },
        {
          system: 'https://www.usps.com/',
          code: 'TN',
          display: 'Tennessee',
        },
        {
          system: 'https://www.usps.com/',
          code: 'TX',
          display: 'Texas',
        },
        {
          system: 'https://www.usps.com/',
          code: 'UT',
          display: 'Utah',
        },
        {
          system: 'https://www.usps.com/',
          code: 'VA',
          display: 'Virginia',
        },
        {
          system: 'https://www.usps.com/',
          code: 'VI',
          display: 'Virgin Islands of the U.S.',
        },
        {
          system: 'https://www.usps.com/',
          code: 'VT',
          display: 'Vermont',
        },
        {
          system: 'https://www.usps.com/',
          code: 'WA',
          display: 'Washington',
        },
        {
          system: 'https://www.usps.com/',
          code: 'WI',
          display: 'Wisconsin',
        },
        {
          system: 'https://www.usps.com/',
          code: 'WV',
          display: 'West Virginia',
        },
        {
          system: 'https://www.usps.com/',
          code: 'WY',
          display: 'Wyoming',
        },
      ],
    },
  },
  [TERM_V3_ACT_ENCOUNTER_CODE.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
          code: 'EMER',
          display: 'emergency',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
          code: 'VR',
          display: 'virtual',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
          code: 'FLD',
          display: 'field',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
          code: 'HH',
          display: 'home health',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
          code: 'IMP',
          display: 'inpatient encounter',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
          code: 'OBSENC',
          display: 'observation encounter',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
          code: 'PRENC',
          display: 'pre-admission',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
          code: 'AMB',
          display: 'ambulatory',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
          code: 'SS',
          display: 'short stay',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
          code: 'ACUTE',
          display: 'inpatient acute',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
          code: 'NONAC',
          display: 'inpatient non-acute',
        },
      ],
    },
  },
  [ENCOUNTER_STATUS.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://hl7.org/fhir/encounter-status',
          code: 'planned',
          display: 'Planned',
        },
        {
          system: 'http://hl7.org/fhir/encounter-status',
          code: 'arrived',
          display: 'Arrived',
        },
        {
          system: 'http://hl7.org/fhir/encounter-status',
          code: 'triaged',
          display: 'Triaged',
        },
        {
          system: 'http://hl7.org/fhir/encounter-status',
          code: 'in-progress',
          display: 'In Progress',
        },
        {
          system: 'http://hl7.org/fhir/encounter-status',
          code: 'onleave',
          display: 'On Leave',
        },
        {
          system: 'http://hl7.org/fhir/encounter-status',
          code: 'finished',
          display: 'Finished',
        },
        {
          system: 'http://hl7.org/fhir/encounter-status',
          code: 'cancelled',
          display: 'Cancelled',
        },
        {
          system: 'http://hl7.org/fhir/encounter-status',
          code: 'entered-in-error',
          display: 'Entered in Error',
        },
        {
          system: 'http://hl7.org/fhir/encounter-status',
          code: 'unknown',
          display: 'Unknown',
        },
      ],
    },
  },
  [ENCOUNTER_PARTICIPANT_TYPE.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ParticipationType',
          code: 'ADM',
          display: 'admitter',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ParticipationType',
          code: 'ATND',
          display: 'attender',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ParticipationType',
          code: 'CALLBCK',
          display: 'callback contact',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ParticipationType',
          code: 'CON',
          display: 'consultant',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ParticipationType',
          code: 'DIS',
          display: 'discharger',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ParticipationType',
          code: 'ESC',
          display: 'escort',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ParticipationType',
          code: 'REF',
          display: 'referrer',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ParticipationType',
          code: 'PPRF',
          display: 'primary performer',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ParticipationType',
          code: 'SPRF',
          display: 'secondary performer',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ParticipationType',
          code: 'PART',
          display: 'Participation',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/participant-type',
          code: 'translator',
          display: 'Translator',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/participant-type',
          code: 'emergency',
          display: 'Emergency',
        },
      ],
    },
  },
  [OBSERVATION_STATUS.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://hl7.org/fhir/observation-status',
          code: 'registered',
          display: 'Registered',
        },
        {
          system: 'http://hl7.org/fhir/observation-status',
          code: 'preliminary',
          display: 'Preliminary',
        },
        {
          system: 'http://hl7.org/fhir/observation-status',
          code: 'final',
          display: 'Final',
        },
        {
          system: 'http://hl7.org/fhir/observation-status',
          code: 'amended',
          display: 'Amended',
        },
        {
          system: 'http://hl7.org/fhir/observation-status',
          code: 'corrected',
          display: 'Corrected',
        },
        {
          system: 'http://hl7.org/fhir/observation-status',
          code: 'cancelled',
          display: 'Cancelled',
        },
        {
          system: 'http://hl7.org/fhir/observation-status',
          code: 'entered-in-error',
          display: 'Entered in Error',
        },
        {
          system: 'http://hl7.org/fhir/observation-status',
          code: 'unknown',
          display: 'Unknown',
        },
      ],
    },
  },
  [OBSERVATION_CATEGORY.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/observation-category',
          code: 'social-history',
          display: 'Social History',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/observation-category',
          code: 'vital-signs',
          display: 'Vital Signs',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/observation-category',
          code: 'imaging',
          display: 'Imaging',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/observation-category',
          code: 'laboratory',
          display: 'Laboratory',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/observation-category',
          code: 'procedure',
          display: 'Procedure',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/observation-category',
          code: 'survey',
          display: 'Survey',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/observation-category',
          code: 'exam',
          display: 'Exam',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/observation-category',
          code: 'therapy',
          display: 'Therapy',
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/observation-category',
          code: 'activity',
          display: 'Activity',
        },
      ],
    },
  },
  [CONTACT_POINT_SYSTEM.key]: {
    resourceType: 'ValueSet',
    status: 'active',
    expansion: {
      contains: [
        {
          system: 'http://hl7.org/fhir/contact-point-system',
          code: 'phone',
          display: 'Phone',
        },
        {
          system: 'http://hl7.org/fhir/contact-point-system',
          code: 'fax',
          display: 'Fax',
        },
        {
          system: 'http://hl7.org/fhir/contact-point-system',
          code: 'email',
          display: 'Email',
        },
        {
          system: 'http://hl7.org/fhir/contact-point-system',
          code: 'pager',
          display: 'Pager',
        },
        {
          system: 'http://hl7.org/fhir/contact-point-system',
          code: 'url',
          display: 'URL',
        },
        {
          system: 'http://hl7.org/fhir/contact-point-system',
          code: 'sms',
          display: 'SMS',
        },
        {
          system: 'http://hl7.org/fhir/contact-point-system',
          code: 'other',
          display: 'Other',
        },
      ],
    },
  },
};
