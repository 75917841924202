import { call, put, select, takeEvery } from 'redux-saga/effects';
import { isEmpty, isFunction } from 'lodash';
import { requestWithToken, requestPostOptions } from 'app/utils/request';
import { extractValueSetExpansion, extractValueSetBatchEntries } from 'app/resources/ValueSetResource';
import { URL_FHIR_API } from 'app/utils/urlConstants';
import * as FHIRService from 'app/services/FHIR';
import * as ValueSetService from 'app/services/ValueSetService';
import { GET_VALUESET, GET_VALUESET_BATCH } from './valueSet.constants';
import {
  getValueSetSuccess,
  getValueSetError,
  getValueSetBatchSuccess,
  getValueSetBatchError,
} from './actions/valueSet.actions';

// valueSet selector
const getValueSet = (state, valueSetType) => state.valueSets[valueSetType.key];

// Filter valueSets that already exist in store
const filterValueSets = (state, valueSetTypeArray) =>
  valueSetTypeArray.filter(valueSetType => {
    const valueSetinStore = state.valueSets[valueSetType.key];
    return !!isEmpty(valueSetinStore);
  });

export function* getValueSetSaga({ valueSetType }) {
  // Check state to see if we already have the valueSet
  const storedValueSet = yield select(getValueSet, valueSetType);
  if (isEmpty(storedValueSet)) {
    // Contact API to pull valueSet into state
    const requestURL = valueSetType.relativePath;
    try {
      const valueSetBundle = yield call(FHIRService.query, requestURL);
      const valueSet = extractValueSetExpansion(valueSetBundle);
      yield put(getValueSetSuccess(valueSetType, valueSet));
    } catch (err) {
      yield put(getValueSetError(err));
    }
  } else {
    // valueSet already in state, return success
    try {
      yield put(getValueSetSuccess(valueSetType, storedValueSet));
    } catch (err) {
      yield put(getValueSetError(err));
    }
  }
}

export function* getValueSetBatchSaga({ valueSetTypeArray, onSuccess }) {
  try {
    // Filter incoming valueSet requests to exclude valueSets that are already in state
    const filteredValueSetTypes = yield select(filterValueSets, valueSetTypeArray);

    if (!isEmpty(filteredValueSetTypes)) {
      // At least one requested valueSet is not already in state; go get it
      const valueSetBatchBundle = yield call(ValueSetService.getValueSets, filteredValueSetTypes);
      const batchEntries = extractValueSetBatchEntries(valueSetBatchBundle);
      yield put(getValueSetBatchSuccess(filteredValueSetTypes, batchEntries));
    }
    // returns immediate success if all valueSets were in state
    if (isFunction(onSuccess)) {
      yield call(onSuccess);
    }
  } catch (err) {
    yield put(getValueSetBatchError(err));
  }
}

const fhirBatch = {
  resourceType: 'Bundle',
  id: 'joes-batch-test',
  type: 'batch',
  entry: [
    {
      request: {
        method: 'GET',
        url: 'ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/condition-clinical',
      },
    },
    {
      request: {
        method: 'GET',
        url: '/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/condition-ver-status',
      },
    },
  ],
};

export function* getValueSet2Saga({ valueSetType }) {
  // Check state to see if we already have the valueSet
  const storedValueSet = yield select(getValueSet, valueSetType);
  if (isEmpty(storedValueSet)) {
    // Contact API to pull valueSet into state
    // const requestURL = valueSetType.path;
    // const requestURL = `${URL_FHIR_API}/ValueSet?url=http://hl7.org/fhir/ValueSet/condition-ver-status${encodeURI('|')}http://hl7.org/fhir/ValueSet/condition-clinical`;
    const requestURL = `${URL_FHIR_API}`; // ${encodeURI('|')}ConditionCategoryCodes
    try {
      const valueSetBundle = yield call(requestWithToken, requestURL, requestPostOptions(fhirBatch));
      const valueSet = extractValueSetExpansion(valueSetBundle);
      yield put(getValueSetSuccess(valueSetType, valueSet));
    } catch (err) {
      yield put(getValueSetError(err));
    }
  } else {
    // valueSet already in state, return success
    try {
      yield put(getValueSetSuccess(valueSetType, storedValueSet));
    } catch (err) {
      yield put(getValueSetError(err));
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* valueSetRootSaga() {
  yield takeEvery(GET_VALUESET, getValueSetSaga);
  yield takeEvery(GET_VALUESET_BATCH, getValueSetBatchSaga);
}
