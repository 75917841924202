import * as redux from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import * as sentry from '@sentry/react';
import createReducer from './reducers';

export function configureStore(initialState = {}, history) {
  const reduxSagaMonitorOptions = {};
  const composeEnhancers = setComposeEnhancers();

  // NOTE: Uncomment the code below to restore support for Redux Saga
  // Dev Tools once it supports redux-saga version 1.x.x (https://github.com/redux-saga/redux-saga-devtools)
  // if (window.__SAGA_MONITOR_EXTENSION__)
  //   reduxSagaMonitorOptions = {
  //     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
  //   };

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. redux-thunk: Actions can return functions
  // 3. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, thunk, routerMiddleware(history)];
  // create sentry enhancer to add redux support to error reporting
  const sentryReduxEnhancer = sentry.createReduxEnhancer();
  const enhancers = [
    redux.applyMiddleware(...middlewares),
    createInjectorsEnhancer({
      createReducer,
      runSaga,
      sentryReduxEnhancer,
    }),
  ];

  const store = redux.createStore(createReducer(), initialState, composeEnhancers(...enhancers));

  store.asyncReducers = {};

  // Make reducers hot reloadable, see http://mxs.is/googmo
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      forceReducerReload(store);
    });
  }

  return store;
}

function setComposeEnhancers() {
  // This is where we could disable Redux DevTools in prod, i.e. if (process.env.NODE_ENV !== 'production')
  if (process.env.NODE_ENV) {
    if (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Set any redux devtool options here (name, actionsBlacklist, actionsCreators, serialize...)
      });
    }
  }
  // unknown environment; cannot support redux devtools
  return redux.compose;
}

export default configureStore;
