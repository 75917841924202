// global constants for user settings

import { URL_ROOT, URL_LOGIN, URL_LOGOUT, URL_CALLBACK, URL_REGISTER } from 'app/utils/urlConstants';

// global user settings version
export const SETTINGS_VERSION = '1.0';
export const LASTPATH_IGNORE_ROUTES = [
  { path: URL_ROOT, exact: true },
  { path: URL_LOGIN, exact: false },
  { path: URL_LOGOUT, exact: false },
  { path: URL_REGISTER, exact: false },
  { path: URL_CALLBACK, exact: false },
];

export const DEFAULT_LASTPATH = '/dashboard';

// Default user settings
export const DefaultUserSettings = {
  version: SETTINGS_VERSION,
  global: {
    shortcuts: [],
    darkMode: true,
    lastPath: DEFAULT_LASTPATH,
  },
};
