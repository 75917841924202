import { createTheme } from '@material-ui/core';
import NexusLayoutConfigs from 'app/layout/NexusLayoutConfigs';
import NexusSettingsConfig, { darkModeTheme, lightModeTheme } from 'app/config/settingsConfig';
import NexusThemesConfig from 'app/config/themesConfig';
import _ from '@lodash';
import {
  defaultSettings,
  getParsedQuerySettings,
  defaultThemes,
  defaultThemeOptions,
  mustHaveThemeOptions,
  extendThemeWithMixins,
  mainThemeVariations,
} from '@nexus/default-settings';
import { TOGGLE_DARK_MODE, GET_USER_SETTINGS_SUCCESS } from 'app/auth/constants';
import {
  SETTINGS_SET_SETTINGS,
  SETTINGS_SET_DEFAULT_SETTINGS,
  SETTINGS_SET_INITIAL_SETTINGS,
  SETTINGS_RESET_DEFAULT_SETTINGS,
} from '../../nexusConstants';

const themesObjRaw = Object.keys(NexusThemesConfig).length !== 0 ? NexusThemesConfig : defaultThemes;
const initialSettings = getInitialSettings();
const initialThemes = getInitialThemes();

const initialState = {
  initial: initialSettings,
  defaults: _.merge({}, initialSettings),
  current: _.merge({}, initialSettings),
  themes: initialThemes,
  ...getThemeOptions(initialThemes, initialSettings),
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_SET_SETTINGS: {
      const current = generateSettings(state.defaults, action.value);
      const themes =
        current.theme.main !== state.current.theme.main
          ? { ...state.themes, ...updateMainThemeVariations(current.theme.main) }
          : state.themes;
      return {
        ...state,
        current,
        themes,
        ...getThemeOptions(themes, current),
      };
    }

    case SETTINGS_SET_INITIAL_SETTINGS: {
      return _.merge({}, initialState);
    }

    case SETTINGS_SET_DEFAULT_SETTINGS: {
      const defaults = generateSettings(state.defaults, action.value);
      let themes =
        defaults.theme.main !== state.defaults.theme.main
          ? { ...state.themes, ...updateMainThemeVariations(defaults.theme.main, state.themes) }
          : state.themes;
      themes =
        defaults.direction !== state.defaults.direction ? updateThemeDirections(themes, defaults.direction) : themes;
      return {
        ...state,
        defaults: _.merge({}, defaults),
        current: _.merge({}, defaults),
        themes,
        ...getThemeOptions(themes, defaults),
      };
    }

    case SETTINGS_RESET_DEFAULT_SETTINGS: {
      const themes = { ...state.themes, ...updateMainThemeVariations(state.defaults.theme.main, state.themes) };
      return {
        ...state,
        defaults: _.merge({}, state.defaults),
        current: _.merge({}, state.defaults),
        themes,
        ...getThemeOptions(themes, state.defaults),
      };
    }
    case GET_USER_SETTINGS_SUCCESS: {
      const { darkMode } = action.userSettings?.global;
      const currentTheme = darkMode ? darkModeTheme : lightModeTheme;
      return {
        ...state,
        defaults: { ...state.defaults, theme: { ...currentTheme } },
        current: { ...state.current, theme: { ...currentTheme } },
        ...getThemeOptions(state.themes, { ...state.current, theme: { ...currentTheme } }),
      };
    }
    case TOGGLE_DARK_MODE: {
      // This is hard to understand but necessary due to realtime customizability options; consider simplifying
      const currentTheme = action.value ? darkModeTheme : lightModeTheme;
      return {
        ...state,
        defaults: { ...state.defaults, theme: { ...currentTheme } },
        current: { ...state.current, theme: { ...currentTheme } },
        ...getThemeOptions(state.themes, { ...state.current, theme: { ...currentTheme } }),
      };
    }

    default: {
      return state;
    }
  }
};

export default settings;

/**
 * SETTINGS
 */
function getInitialSettings() {
  const defaultLayoutStyle =
    NexusSettingsConfig.layout && NexusSettingsConfig.layout.style ? NexusSettingsConfig.layout.style : 'MainLayout';
  const layout = {
    style: defaultLayoutStyle,
    config: NexusLayoutConfigs[defaultLayoutStyle].defaults,
  };
  return _.merge({}, defaultSettings, { layout }, NexusSettingsConfig, getParsedQuerySettings());
}

/**
 * THEMES
 */
function getInitialThemes() {
  const { direction } = initialSettings;

  const themes = Object.assign(
    {},
    ...Object.entries(themesObjRaw).map(([key, value]) => {
      const muiTheme = _.merge({}, defaultThemeOptions, value, mustHaveThemeOptions);
      return {
        [key]: createTheme(
          _.merge({}, muiTheme, {
            mixins: extendThemeWithMixins(muiTheme),
            direction,
          }),
        ),
      };
    }),
  );

  return {
    ...themes,
    ...mainThemeVariations({
      ...themesObjRaw[initialSettings.theme.main],
      mixins: extendThemeWithMixins(themesObjRaw[initialSettings.theme.main]),
      direction,
    }),
  };
}

function updateMainThemeVariations(mainTheme, themes) {
  return mainThemeVariations({
    ...themesObjRaw[mainTheme],
    mixins: extendThemeWithMixins(themesObjRaw[mainTheme]),
    direction: themes[mainTheme].direction,
  });
}

function getThemeOptions(_themes, _themeSettings) {
  return {
    mainTheme: _themes[_themeSettings.theme.main],
    navbarTheme: _themes[_themeSettings.theme.navbar],
    toolbarTheme: _themes[_themeSettings.theme.toolbar],
    footerTheme: _themes[_themeSettings.theme.footer],
    ...updateMainThemeVariations(_themeSettings.theme.main, _themes),
  };
}

function updateThemeDirections(themes, direction) {
  const response = {};
  Object.entries(themes).forEach(([key, value]) => {
    response[key] = { ...value, direction };
  });
  return response;
}

export function generateSettings(_defaultSettings, _newSettings) {
  return _.merge(
    {},
    _defaultSettings,
    _newSettings && _newSettings.layout && _newSettings.layout.style
      ? { layout: { config: NexusLayoutConfigs[_newSettings.layout.style].defaults } }
      : {},
    _newSettings,
  );
}
