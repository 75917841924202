import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import NexusNavItem from '../NexusNavItem';

const useStyles = makeStyles((theme) => ({
  navigation: {
    '& .nexus-list-item': {
      '&:hover': {
        backgroundColor:
          theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0,0,0,.04)',
      },
      '&:focus:not(.active)': {
        backgroundColor:
          theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.06)' : 'rgba(0,0,0,.05)',
      },
    },
    '&.active-square-list': {
      '& .nexus-list-item, & .active.nexus-list-item': {
        width: '100%',
        borderRadius: '0',
      },
    },
    '&.dense': {
      '& .nexus-list-item': {
        paddingTop: 0,
        paddingBottom: 0,
        height: 32,
      },
    },
  },
}));

function NexusNavVerticalLayout1(props) {
  const classes = useStyles(props);
  const { navigation, layout, active, dense, className, onItemClick } = props;
  const dispatch = useDispatch();

  function handleItemClick(item) {
    onItemClick?.(item);
  }

  return (
    <List
      className={clsx(
        'navigation whitespace-nowrap px-12',
        classes.navigation,
        `active-${active}-list`,
        dense && 'dense',
        className
      )}
    >
      {navigation.map((_item) => (
        <NexusNavItem
          key={_item.id}
          type={`vertical-${_item.type}`}
          item={_item}
          nestedLevel={0}
          onItemClick={handleItemClick}
        />
      ))}
    </List>
  );
}

export default NexusNavVerticalLayout1;
