import { extractResourceId } from 'app/resources/resourceUtils';
import { requestPutOptions } from 'app/utils/request';
import urljoin from 'url-join';
import { errorIfUninitialized, query } from './core';
import { URL_PRACTITIONER } from './urlConstants';

/**
 * A function that allows for querying practitioners. Add params to modify the request
 *
 * @param {array} params - An array of query strings. They will be joined with '&' and appended to the request URL
 * @returns promise
 */
export async function getPractitioners(params = []) {
  errorIfUninitialized();

  // By default get 200 practitioners
  params.push('_count=200');

  const paramsString = params.length > 0 ? `?${params.join('&')}` : '';
  const response = await query(`${URL_PRACTITIONER}${paramsString}`);
  return response;
}

/**
 * A function for requesting a single practitioner
 *
 * @param {string} id - The id of the practitioner
 * @returns promise
 */
export async function getPractitioner(id) {
  errorIfUninitialized();
  const response = await query(`${URL_PRACTITIONER}?_id=${id}`);
  return response;
}

/**
 * A function for creating a practitioner
 *
 * @param {object} practitioner - A FHIR practitioner object
 * @returns promise
 */
export async function createPractitioner(practitioner) {
  errorIfUninitialized();
  const url = urljoin(URL_PRACTITIONER, extractResourceId(practitioner));
  const response = await query(url, requestPutOptions(practitioner));
  return response;
}
