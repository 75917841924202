import React from 'react';
import { authScopes } from 'app/services/auth0Service/authScopes';

export const AppointmentConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  requireAuthentication: true,
  routes: [
    {
      path: '/app/appointments',
      exact: true,
      component: React.lazy(() => import('app/content/apps/Appointments/AppointmentList')),
      scopes: [authScopes.appointment.read], // authScopes.view.appointmentList
    },
    // {
    //   path: '/app/appointments/edit/:appointmentId',
    //   component: React.lazy(() => import('app/content/apps/Appointments/AppointmentEditor')),
    //   // scopes: [authScopes.view.appointmentCreate, authScopes.appointment.read, authScopes.appointment.write],
    // },
    // {
    //   path: '/app/appointments/new',
    //   exact: false,
    //   component: React.lazy(() => import('app/content/apps/Appointments/AppointmentEditor')),
    //   // scopes: [authScopes.view.appointmentCreate, authScopes.appointment.write],
    // },
  ],
};
