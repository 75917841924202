import request, { requestPatchOptions, requestPostOptions, requestPutOptions } from 'app/utils/request';
import urljoin from 'url-join';

let FHIRServiceStore;

/**
 * Initializes the FHIRService
 *
 * @param {string} baseUrl - The base URL of the FHIR server
 * @param {string} authHeader - The authorization header that should be used to access the FHIR server
 * @param {string} launchType - The launch type for the system ('auth0' | 'smart')
 */
export function initialize(baseUrl, authHeader, launchType) {
  FHIRServiceStore = {
    baseUrl,
    authHeader,
    launchType,
  };
}

export function getConfig() {
  return FHIRServiceStore;
}

/**
 * Writes an error to the console if a function is called before the service is initialized
 */
export function errorIfUninitialized() {
  if (FHIRServiceStore === undefined) {
    console.error('FHIR Service was called before it was initialized');
  }
}

/**
 * The raw query function that should only be used if no action-specific function is provided
 *
 * @param {string} url - The URL part that should be appended to the base FHIR API path
 * @param {object} options - Fetch options
 * @returns promise
 */
export async function query(url, options = {}) {
  errorIfUninitialized();

  const fullUrl = urljoin(FHIRServiceStore.baseUrl, url);
  let fetchOptions = options;

  const { authHeader } = FHIRServiceStore;
  if (authHeader) {
    fetchOptions = {
      ...fetchOptions,
      headers: { Authorization: authHeader, ...fetchOptions.headers },
    };
  } else {
    console.error('No access_token found');
  }

  return request(fullUrl, fetchOptions);
}

function batchEntryToQuery(entry) {
  const { method, url } = entry.request;
  const { resource } = entry;

  if (method === 'GET') {
    return query(url);
  }
  if (method === 'PUT') {
    return query(url, requestPutOptions(resource));
  }
  if (method === 'POST') {
    return query(url, requestPostOptions(resource));
  }
  if (method === 'PATCH') {
    return query(url, requestPatchOptions(resource));
  }

  console.error(`unknown method: ${method}`);

  return undefined;
}

function errorToBatchEntry(err) {
  const { response: newResponse } = err;
  if (newResponse !== undefined) {
    return {
      response: { status: `${newResponse.status} ${newResponse.statusText}` },
      resource: newResponse.body,
    };
  }

  // If the request fails for some other reason stuff it in an entry
  // structure
  return {
    response: { status: '500 CN Error' },
    resource: `${err}`,
  };
}

/**
 * A function that provides basic batch functionality
 *
 * @param {object} batchBundle - FHIR batch bundle
 * @returns promise
 */
export async function batch(batchBundle) {
  errorIfUninitialized();
  try {
    // Initial batch attempt
    const response = await query('', requestPostOptions(batchBundle));

    // if the response has entries, try to determine if any request needs to be retried
    if (response.entry && response.entry.length > 0) {
      // If any entries were not '200 OK', retry them as a individual request
      const newEntryRequests = response.entry
        .map((entry, i) => {
          if (entry?.response?.status !== '200 OK') {
            // If entry 1 failed, get the request info for entry 1 from the original
            // batch bundle and turn it into a normal fetch request
            // If the request resolves convert it into a batch entry
            return batchEntryToQuery(batchBundle.entry[i]).then(res => ({
              response: { status: '200 OK' },
              resource: res,
            }));
          }

          // By default, return a promise-ized version of the entry so we can safely
          // assume that everything is a promise later on
          return Promise.resolve(entry);
        })
        // Add a catch method to force errors to not fail the whole request
        .map(newRequest => newRequest.catch(errorToBatchEntry));

      // newEntryRequests looks something like this
      // [ Promise(oldResolvedEntry1), Promise(oldResolvedEntry2), Promise(requestToGetNewEntry3), Promise(oldResolvedEntry4)]
      // calling Promise.all will result in [oldResolvedEntry1, oldResolvedEntry2, newResolvedEntry3, oldResolvedEntry4]
      const entries = await Promise.all(newEntryRequests);

      // Override old entry with new one
      response.entry = entries;
    }

    return response;
  } catch (err) {
    // This catch should only run if the whole batch failed outright
    if (err.response !== undefined) {
      console.warn('Failed to make batch request to the configured FHIR server, falling back to parallel requests');
      const requests = batchBundle.entry
        // Convert entries to a series of requests
        .map(batchEntryToQuery)
        // Remove entrires that failed to be turned into requets
        .filter(x => x !== undefined)
        // Add post procesing
        .map(newRequest =>
          newRequest
            // Add success processing to convert response to batch entry object
            .then(res => ({
              response: {
                status: '200 OK',
              },
              resource: res,
            }))
            // Add error process to convert response to batch entry object
            .catch(errorToBatchEntry),
        );

      const entries = await Promise.all(requests);

      return {
        resourceType: 'Bundle',
        type: 'batch-response',
        entry: entries,
      };
    }
    console.error(err);
    throw err;
  }
}

/**
 * A function that provides basic transaction funcationality
 *
 * @param {object} transactionBundle - Transaction bundle
 * @returns promise
 */
export async function transaction(transactionBundle) {
  errorIfUninitialized();
  const response = await query('', requestPostOptions(transactionBundle));
  return response;
}
