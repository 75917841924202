// constants to define app-wide functionality

export const AUTH_TIMEOUT_WARNING_SECONDS = 100;
export const AUTH_TIMEOUT_BUFFER_SECONDS = 10;
// number of seconds remaining on token before we attempt to refresh the auth token
export const AUTH_RENEW_TOKEN_THRESHOLD = 900;
// default debounce search value
export const DEBOUNCE_WAIT_DEFAULT = 500;
// React-hook-form validation settings
export const RHF_DIRTY_VALIDATE = { shouldDirty: true, shouldValidate: true };

export const GLOBAL_LOCALE = 'en-US';
