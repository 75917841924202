/* eslint-disable default-case */
import produce from 'immer';
import { isArray } from 'lodash';
import { extractValueSetFromBatchEntry } from 'app/resources/ValueSetResource';
import { GET_VALUESET_SUCCESS, GET_VALUESET_BATCH_SUCCESS } from '../valueSet.constants';

const initialState = {};

const valueSetReducer = produce((draft, action) => {
  switch (action.type) {
    case GET_VALUESET_SUCCESS:
      draft[action.valueSetType.key] = action.values;
      break;
    case GET_VALUESET_BATCH_SUCCESS:
      if (isArray(action.valueSetTypes) && isArray(action.batchEntries)) {
        action.valueSetTypes.forEach((valueSetType, index) => {
          draft[valueSetType.key] = extractValueSetFromBatchEntry(action.batchEntries[index]);
        });
      }
      break;
  }
}, initialState);

export default valueSetReducer;
