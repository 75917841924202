import React from 'react';
import { Container } from '@material-ui/core';

function Unauthorized() {
  return (
    <Container>
      <h1 className="self-center">Unauthorized</h1>
      <p>
        You do not have permission to view this page. Please contact your local administrator to request permission.
      </p>
    </Container>
  );
}

export default Unauthorized;
