import NexusUtils from '@nexus/utils';
// NOTE: this import fixes a circular dependency issue in importing selectors
// TODO: Fix and address this issue
// eslint-disable-next-line no-unused-vars
import { authSelector } from 'app/store/reducers';
import { GET_NAVIGATION, SET_NAVIGATION, RESET_NAVIGATION } from '../../nexusConstants';

export function getNavigation() {
  return {
    type: GET_NAVIGATION,
  };
}

export function setNavigation(navigation) {
  return {
    type: SET_NAVIGATION,
    navigation,
  };
}

export function resetNavigation() {
  return {
    type: RESET_NAVIGATION,
  };
}

export function appendNavigationItem(item, parentId) {
  return (dispatch, getState) => {
    const { navigation } = getState().nexus;
    return dispatch({
      type: SET_NAVIGATION,
      navigation: NexusUtils.appendNavItem(navigation, item, parentId),
    });
  };
}

export function prependNavigationItem(item, parentId) {
  return (dispatch, getState) => {
    const { navigation } = getState().nexus;
    return dispatch({
      type: SET_NAVIGATION,
      navigation: NexusUtils.prependNavItem(navigation, item, parentId),
    });
  };
}

export function updateNavigationItem(id, item) {
  return (dispatch, getState) => {
    const { navigation } = getState().nexus;
    return dispatch({
      type: SET_NAVIGATION,
      navigation: NexusUtils.updateNavItem(navigation, id, item),
    });
  };
}

export function removeNavigationItem(id) {
  return (dispatch, getState) => {
    const { navigation } = getState().nexus;
    return dispatch({
      type: SET_NAVIGATION,
      navigation: NexusUtils.removeNavItem(navigation, id),
    });
  };
}
