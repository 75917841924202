import { cloneDeep, isEmpty, isPlainObject, isArray, has, set, isFunction } from 'lodash';
import jsonPatch from 'fast-json-patch';
import { DefaultUserSettings, DEFAULT_LASTPATH, SETTINGS_VERSION } from 'app/store/settingConstants';
import { store } from 'app/App';
import { getUserSettingsError, getUserSettingsSuccess, setUserSettings } from 'app/auth/store/actions';
import { showMessageError } from 'app/store/actions';
import { URL_API_METADATA_USER } from './urlConstants';
import { requestWithToken } from './request';
import { getLocalUserSettings } from './stateHelpers';
import { isNonEmptyString } from './helpers';

// We have made getUserSettings a special case because we need to await user settings upon user login
export async function getUserSettings() {
  await requestWithToken(URL_API_METADATA_USER)
    .then(result => {
      if (result?.success) {
        const returnedSettings = result?.body;
        if (isEmpty(returnedSettings)) {
          // metadata for user was empty; set to defaults
          store.dispatch(setUserSettings(DefaultUserSettings));
          store.dispatch(getUserSettingsSuccess(DefaultUserSettings));
        } else {
          // user had metadata; validate returnedSettings
          const validatedSettings = validateGlobalUserSettings(returnedSettings);
          // create and send a patch to update any missing fields
          const patchOps = jsonPatch.compare(returnedSettings, validatedSettings);
          if (patchOps.length > 0) {
            store.dispatch(setUserSettings(validatedSettings));
          }
          store.dispatch(getUserSettingsSuccess(validatedSettings));
        }
      } else {
        const err = new Error(result?.errorMessage);
        store.dispatch(getUserSettingsError(err));
      }
    })
    .catch(err => {
      store.dispatch(showMessageError(err));
    });
}

// helper function to ensure the returned settings object meets some basic criteria
// NOTE: Anytime a global setting is added, this should also be amended to support the default value.
function validateGlobalUserSettings(settings) {
  const draftSettings = cloneDeep(settings);

  if (!has(draftSettings, 'version') || isEmpty(draftSettings?.version)) {
    draftSettings.version = SETTINGS_VERSION;
  }
  if (!isPlainObject(draftSettings?.global)) {
    draftSettings.global = {};
  }
  if (!isArray(draftSettings?.global?.shortcuts)) {
    draftSettings.global.shortcuts = [];
  }
  if (!has(draftSettings, 'global.darkMode')) {
    draftSettings.global.darkMode = true;
  }
  if (!has(draftSettings, 'global.lastPath')) {
    draftSettings.global.lastPath = DEFAULT_LASTPATH;
  }
  return draftSettings;
}

export function getUserSetting(appName, path) {
  return getLocalUserSettings(`${appName}.${path}`);
}

// validateFunc should be provided by the calling app; it should take one argument;
// the calling app settings. Calling app should then ensure that the settings
// are in the correct format. validateFunc should make changes to the settings object passed
// so that it has the current structure expected (ie. a 'panels' object with panel names and open/closed values).
// validate will diff and post changes to the server.
// NOTE: validateFunc should take care not to overwrite any existing user settings;
//       this is only to ensure that its settings are in the proper format.
export function initAppSettings(appName, defaultSettings, validateFunc) {
  const appSettings = getLocalUserSettings(appName);
  if (isEmpty(appSettings) && isNonEmptyString(appName) && isPlainObject(defaultSettings)) {
    // set the default settings locally
    store.dispatch(setUserSettings(defaultSettings, appName));
    return defaultSettings;
  }
  // app settings found; validate and post changes if necessary
  const validatedPatientListSettings = validateAppSettings(appName, appSettings, validateFunc);
  return validatedPatientListSettings;
}

function validateAppSettings(appName, appSettings, validateFunc) {
  const draftSettings = cloneDeep(appSettings);
  if (isFunction(validateFunc)) {
    // validateFunc should only make changes necessary to ensure app settings are in the proper format
    validateFunc(draftSettings);
  }
  // determine any changes made by validateFunc and post them to the server
  if (jsonPatch.compare(appSettings, draftSettings).length > 0) {
    store.dispatch(setUserSettings(draftSettings, appName));
  }
  return draftSettings;
}

export function setUserSetting(appName, path, value) {
  const appSettings = getLocalUserSettings(appName);
  const draftAppSettings = cloneDeep(appSettings);
  if (!isEmpty(appSettings)) {
    // make state copies before we perform the local change
    const originalAllSettings = getLocalUserSettings();
    // set change to draft copy of app settings. Used to provide setUserSettings with our changes
    set(draftAppSettings, path, value);
    // push changes to local state and network
    store.dispatch(setUserSettings(draftAppSettings, appName, originalAllSettings));
  }
  // no app user settings found; abort
}

export function toggleUserSetting(appName, path) {
  const originalSettingValue = getLocalUserSettings(`${appName}.${path}`);
  // don't toggle value if returned setting is an object or array
  if (!isPlainObject(originalSettingValue) && !isArray(originalSettingValue)) {
    // toggle originalSettingValue
    setUserSetting(appName, path, !originalSettingValue);
  }
}
