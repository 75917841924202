import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'app/utils/history';
import { createSmartSelector, prependSelectors } from 'app/utils/stateHelpers';
import valueSets from 'app/resources/store/reducers/valueSet.reducers';
import auth, { authSelectors } from 'app/auth/store/reducers';
import nexus, { nexusSelectors } from './nexus';

const createReducer = asyncReducers =>
  combineReducers({
    auth,
    nexus,
    valueSets,
    router: connectRouter(history),
    ...asyncReducers,
  });

export const nexusSelector = createSmartSelector(store => store.nexus);
export const authSelector = createSmartSelector(store => store.auth);
export const valueSetsSelector = createSmartSelector(store => store.valueSets);

prependSelectors(authSelectors, authSelector);
prependSelectors(nexusSelectors, nexusSelector);

export default createReducer;
