import { cloneDeep } from 'lodash';
import navigationConfig from 'app/config/navigationConfig';
import { GET_NAVIGATION, SET_NAVIGATION, RESET_NAVIGATION } from '../../nexusConstants';

const initialState = navigationConfig();

const navigation = (state = initialState, action) => {
  switch (action.type) {
    case GET_NAVIGATION: {
      return [...state];
    }
    case SET_NAVIGATION: {
      return [...cloneDeep(action.navigation)];
    }
    case RESET_NAVIGATION: {
      return [...cloneDeep(initialState)];
    }
    default: {
      return state;
    }
  }
};

export default navigation;
