import React from 'react';
import { Button } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import { auth0Scopes } from 'app/services/auth0Service/authScopes';
import authConfig from 'app/services/auth0Service/auth0ServiceConfig';

function Auth0LoginTab(props) {
  const { loginWithRedirect } = useAuth0();

  const loginOptions = {
    audience: authConfig.api_id,
    scope: auth0Scopes,
    redirectUri: authConfig.callbackUrl,
    // prompt: 'select_account',
  };

  return (
    <Button className="normal-case" size="large" variant="contained" onClick={() => loginWithRedirect(loginOptions)}>
      <div>
        <img className="w-224 mt-4 mb-12" src="assets/images/logos/CareNexus-logo-full.svg" alt="logo" />
        <div className="italic">Click to Login</div>
      </div>
    </Button>
  );
}

export default Auth0LoginTab;
