// Resource Type constants
export const RESOURCE_TYPE_PATIENT = 'Patient';
export const RESOURCE_TYPE_PRACTITIONER = 'Practitioner';
export const RESOURCE_TYPE_CONDITION = 'Condition';
export const RESOURCE_TYPE_APPOINTMENT = 'Appointment';
export const RESOURCE_TYPE_CAREPLAN = 'CarePlan';
export const RESOURCE_TYPE_CARETEAM = 'CareTeam';
export const RESOURCE_TYPE_GOAL = 'Goal';
export const RESOURCE_TYPE_ORGANIZATION = 'Organization';
export const RESOURCE_TYPE_ENCOUNTER = 'Encounter';
export const RESOURCE_TYPE_LOCATION = 'Location';
export const RESOURCE_TYPE_COMMUNICATION = 'Communication';
export const RESOURCE_TYPE_PRACTITIONERROLE = 'PractitionerRole';
export const RESOURCE_TYPE_RELATEDPERSON = 'RelatedPerson';
export const RESOURCE_TYPE_OBSERVATION = 'Observation';

// FHIR extension URLs
export const RESOURCE_EXT_USCORE_ETHNICITY = 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity';
export const RESOURCE_EXT_USCORE_RACE = 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-race';
export const RESOURCE_EXT_USCORE_BIRTHSEX = 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex';
export const RESOURCE_EXT_OMB_CATEGORY = 'ombCategory';
export const RESOURCE_EXT_TEXT = 'text';

// USCore URLs
export const USCORE_URL_PATIENT = 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-patient';
export const USCORE_URL_PRACTITIONER = 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-practitioner';
export const USCORE_URL_ORGANIZATION = 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-organization';
export const USCORE_URL_ENCOUNTER = 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-encounter';

// Identifier systems
export const IDENTIFIER_SYSTEM_NPI = 'http://hl7.org/fhir/sid/us-npi';
export const IDENTIFIER_SYSTEM_SSN = 'http://hl7.org/fhir/sid/us-ssn';

// Identifier common codes
export const IDENTIFIER_COMMONNAME_NPI = 'NPI';
export const IDENTIFIER_COMMONNAME_SSN = 'SSN';

// special CareNexus identifier
// Type: RI=Resource identifier (https://www.hl7.org/fhir/v2/0203/index.html)
export const IDENTIFIER_CARENEXUS = {
  identifier: 'CareNexus Identifier (CNID)',
  uri: 'https://carenexus.io/fhir/sid/cnid',
  oid: '',
  type: 'RI',
};

// Identifier Registry
export const IDENTIFIER_REGISTRY = [
  {
    identifier: 'Social Security Number (SSN)',
    uri: 'http://hl7.org/fhir/sid/us-ssn',
    oid: '2.16.840.1.113883.4.1',
    type: 'SB',
  },
  {
    identifier: 'National Provider Identifier (NPI)',
    uri: 'http://hl7.org/fhir/sid/us-npi',
    oid: '2.16.840.1.113883.4.6',
    type: 'PRN',
  },
  {
    identifier: 'Medicare Beneficiary Identifier (MBI)',
    uri: 'http://hl7.org/fhir/sid/us-mbi',
    oid: '2.16.840.1.113883.4.927',
    type: 'SB',
  },
  {
    identifier: 'Medicare Number',
    uri: 'http://hl7.org/fhir/sid/us-medicare',
    oid: '2.16.840.1.113883.4.572',
    type: 'SB',
  },
  {
    identifier: 'Passport Number',
    uri: 'urn:oid:2.16.840.1.113883.4.330',
    oid: '2.16.840.1.113883.4.330',
    type: 'PPN',
  },
  {
    identifier: 'Individual Tax ID',
    uri: 'urn:oid:2.16.840.1.113883.4.2',
    oid: '1.3.160',
    type: 'TAX',
  },
  {
    identifier: 'Organization Tax ID',
    uri: 'urn:oid:2.16.840.1.113883.4.4',
    oid: '2.16.840.1.113883.4.4',
    type: 'TAX',
  },
  // ***
  // State driver's licenses
  // ***
  {
    identifier: "Alaska Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.2',
    oid: '2.16.840.1.113883.4.3.2',
    type: 'DL',
  },
  {
    identifier: "Alabama Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.1',
    oid: '2.16.840.1.113883.4.3.1',
    type: 'DL',
  },
  {
    identifier: "Arkansas Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.5',
    oid: '2.16.840.1.113883.4.3.5',
    type: 'DL',
  },
  {
    identifier: "Arizona Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.4',
    oid: '2.16.840.1.113883.4.3.4',
    type: 'DL',
  },
  {
    identifier: "California Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.6',
    oid: '2.16.840.1.113883.4.3.6',
    type: 'DL',
  },
  {
    identifier: "Colorado Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.8',
    oid: '2.16.840.1.113883.4.3.8',
    type: 'DL',
  },
  {
    identifier: "Connecticut Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.9',
    oid: '2.16.840.1.113883.4.3.9',
    type: 'DL',
  },
  {
    identifier: "DC Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.11',
    oid: '2.16.840.1.113883.4.3.11',
    type: 'DL',
  },
  {
    identifier: "Delaware Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.10',
    oid: '2.16.840.1.113883.4.3.10',
    type: 'DL',
  },
  {
    identifier: "Florida Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.12',
    oid: '2.16.840.1.113883.4.3.12',
    type: 'DL',
  },
  {
    identifier: "Georgia Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.13',
    oid: '2.16.840.1.113883.4.3.13',
    type: 'DL',
  },
  {
    identifier: "Hawaii Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.15',
    oid: '2.16.840.1.113883.4.3.15',
    type: 'DL',
  },
  {
    identifier: "Indiana Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.18',
    oid: '2.16.840.1.113883.4.3.18',
    type: 'DL',
  },
  {
    identifier: "Iowa Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.19',
    oid: '2.16.840.1.113883.4.3.19',
    type: 'DL',
  },
  {
    identifier: "Idaho Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.16',
    oid: '2.16.840.1.113883.4.3.16',
    type: 'DL',
  },
  {
    identifier: "Illinois Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.17',
    oid: '2.16.840.1.113883.4.3.17',
    type: 'DL',
  },
  {
    identifier: "Kansas Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.20',
    oid: '2.16.840.1.113883.4.3.20',
    type: 'DL',
  },
  {
    identifier: "Kentucky Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.21',
    oid: '2.16.840.1.113883.4.3.21',
    type: 'DL',
  },
  {
    identifier: "Louisiana Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.22',
    oid: '2.16.840.1.113883.4.3.22',
    type: 'DL',
  },
  {
    identifier: "Massachusetts Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.25',
    oid: '2.16.840.1.113883.4.3.25',
    type: 'DL',
  },
  {
    identifier: "Maryland Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.24',
    oid: '2.16.840.1.113883.4.3.24',
    type: 'DL',
  },
  {
    identifier: "Maine Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.23',
    oid: '2.16.840.1.113883.4.3.23',
    type: 'DL',
  },
  {
    identifier: "Michigan Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.26',
    oid: '2.16.840.1.113883.4.3.26',
    type: 'DL',
  },
  {
    identifier: "Minnesota Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.27',
    oid: '2.16.840.1.113883.4.3.27',
    type: 'DL',
  },
  {
    identifier: "Missouri Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.29',
    oid: '2.16.840.1.113883.4.3.29',
    type: 'DL',
  },
  {
    identifier: "Mississippi Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.28',
    oid: '2.16.840.1.113883.4.3.28',
    type: 'DL',
  },
  {
    identifier: "Montana Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.30',
    oid: '2.16.840.1.113883.4.3.30',
    type: 'DL',
  },
  {
    identifier: "New York Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.36',
    oid: '2.16.840.1.113883.4.3.36',
    type: 'DL',
  },
  {
    identifier: "North Carolina Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.37',
    oid: '2.16.840.1.113883.4.3.37',
    type: 'DL',
  },
  {
    identifier: "North Dakota Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.38',
    oid: '2.16.840.1.113883.4.3.38',
    type: 'DL',
  },
  {
    identifier: "Nebraska Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.31',
    oid: '2.16.840.1.113883.4.3.31',
    type: 'DL',
  },
  {
    identifier: "New Hampshire Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.33',
    oid: '2.16.840.1.113883.4.3.33',
    type: 'DL',
  },
  {
    identifier: "New Jersey Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.34',
    oid: '2.16.840.1.113883.4.3.34',
    type: 'DL',
  },
  {
    identifier: "New Mexico Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.35',
    oid: '2.16.840.1.113883.4.3.35',
    type: 'DL',
  },
  {
    identifier: "Nevada Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.32',
    oid: '2.16.840.1.113883.4.3.32',
    type: 'DL',
  },
  {
    identifier: "Ohio Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.39',
    oid: '2.16.840.1.113883.4.3.39',
    type: 'DL',
  },
  {
    identifier: "Oklahoma Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.40',
    oid: '2.16.840.1.113883.4.3.40',
    type: 'DL',
  },
  {
    identifier: "Oregon Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.41',
    oid: '2.16.840.1.113883.4.3.41',
    type: 'DL',
  },
  {
    identifier: "Pennsylvania Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.42',
    oid: '2.16.840.1.113883.4.3.42',
    type: 'DL',
  },
  {
    identifier: "Rhode Island Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.44',
    oid: '2.16.840.1.113883.4.3.44',
    type: 'DL',
  },
  {
    identifier: "South Carolina Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.45',
    oid: '2.16.840.1.113883.4.3.45',
    type: 'DL',
  },
  {
    identifier: "South Dakota Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.46',
    oid: '2.16.840.1.113883.4.3.46',
    type: 'DL',
  },
  {
    identifier: "Tennessee Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.47',
    oid: '2.16.840.1.113883.4.3.47',
    type: 'DL',
  },
  {
    identifier: "Texas Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.48',
    oid: '2.16.840.1.113883.4.3.48',
    type: 'DL',
  },
  {
    identifier: "Utah Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.49',
    oid: '2.16.840.1.113883.4.3.49',
    type: 'DL',
  },
  {
    identifier: "Virginia Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.51',
    oid: '2.16.840.1.113883.4.3.51',
    type: 'DL',
  },
  {
    identifier: "Vermont Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.50',
    oid: '2.16.840.1.113883.4.3.50',
    type: 'DL',
  },
  {
    identifier: "Washington Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.53',
    oid: '2.16.840.1.113883.4.3.53',
    type: 'DL',
  },
  {
    identifier: "Wisconsin Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.55',
    oid: '2.16.840.1.113883.4.3.55',
    type: 'DL',
  },
  {
    identifier: "West Virginia Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.54',
    oid: '2.16.840.1.113883.4.3.54',
    type: 'DL',
  },
  {
    identifier: "Wyoming Driver's License",
    uri: 'urn:oid:2.16.840.1.113883.4.3.56',
    oid: '2.16.840.1.113883.4.3.56',
    type: 'DL',
  },
  {
    identifier: 'Unrecognized Identifier',
    uri: '???',
    oid: '',
    type: '',
  },
];

// Telecom types (http://hl7.org/fhir/ValueSet/contact-point-system)
export const CONTACT_POINT_SYSTEM = {
  phone: 'phone',
  fax: 'fax',
  email: 'email',
  pager: 'pager',
  url: 'url',
  sms: 'sms',
  other: 'other',
};

export const CONDITION_CODE_SEARCH_SYSTEM = [
  { display: 'ICD-10', code: 'icd10' },
  { display: 'SNOMED CT', code: 'snomedct' },
];

// hard-coded value required by CarePlan FHIR resource
export const USCORE_CAREPLAN_CATEGORY = {
  system: 'http://hl7.org/fhir/us/core/CodeSystem/careplan-category',
  code: 'assess-plan',
  display: 'Assessment and Plan of Treatment',
  userSelected: false,
};

// Default CareNexus country
export const DEFAULT_COUNTRY = 'US';

// date-fns compatible constant to properly format a FHIR-compatible 'instant'
export const DATE_FORMAT_DEFAULT = 'yyyy-MM-dd';
export const TIME_FORMAT_DEFAULT = 'h:mm a';
export const DATETIME_FORMAT_DEFAULT = 'yyyy-MM-dd h:mm a';
// alternate formatting strings
export const DATE_FORMAT_SLASHES = 'MM/dd/yyyy';
