// http://hl7.org/fhir/R4/datatypes.html#CodeableConcept
import { isPlainObject, isEmpty, isString } from 'lodash';
import { extractValueFromPath, extractResourceFromObject } from 'app/resources/resourceUtils';
import { createCoding } from './Coding';

// Todo: 'coding' in codeable concept can support multiple codings; add data=array support
// NOTE: if you'd like to create a text-based CodeableConcept, for example goal.description,
// set data to null and string to your desired text value.
export function createCodeableConcept(data, text = data?.display, userSelected = true) {
  const codeableConcept = {};
  if (isPlainObject(data)) {
    codeableConcept.coding = [createCoding(data, userSelected)];
    codeableConcept.text = isString(text) ? text : null;
  } else if (isString(text)) {
    // data object not provided, but we still have text to set
    codeableConcept.text = text;
  }
  return isEmpty(codeableConcept) ? null : codeableConcept;
}

export function extractCodeFromCodeableConcept(codeableConcept) {
  if (isPlainObject(codeableConcept)) {
    return extractValueFromPath(codeableConcept, 'coding[0].code');
  }
}

// handles either an object or an array with a codeableConcept at index=0
export function extractDisplayFromCodeableConcept(codeableConcept) {
  const codeableConceptObject = extractResourceFromObject(codeableConcept);
  return (
    extractValueFromPath(codeableConceptObject, 'text') ||
    extractValueFromPath(codeableConceptObject, 'coding[0].display') ||
    extractValueFromPath(codeableConceptObject, 'coding[0].code')
  );
}
