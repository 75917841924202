import React from 'react';
import { authScopes } from 'app/services/auth0Service/authScopes';

export const ReportsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  requireAuthentication: true,
  routes: [
    {
      path: '/admin/reports',
      exact: true,
      component: React.lazy(() => import('app/content/apps/Reports/ReportsList')),
      scopes: [authScopes.view.reports],
    },
    {
      path: '/admin/reports/:reportId',
      component: React.lazy(() => import('app/content/apps/Reports/ReportsList')),
      scopes: [authScopes.view.reports],
    },
  ],
};
