import { extractResourceId } from 'app/resources/resourceUtils';
import { requestPutOptions } from 'app/utils/request';
import urljoin from 'url-join';
import { errorIfUninitialized, getConfig, query } from './core';
import { URL_PATIENT } from './urlConstants';

/**
 * A function that allows for querying patients. Add params to modify the request
 *
 * @param {array} params - An array of query strings. They will be joined with '&' and appended to the request URL
 * @returns promise
 */
export async function getPatients(params = []) {
  errorIfUninitialized();
  const { launchType } = getConfig();

  if (launchType === 'SMART') {
    const paramsString = params.length > 0 ? `?${params.join('&')}` : '';
    const response = await query(`${URL_PATIENT}${paramsString}`);
    return response;
  }

  if (launchType === 'auth0') {
    params.push('_count=100');
    const paramsString = params.join('&');
    const response = await query(`${URL_PATIENT}?${paramsString}`);
    return response;
  }
  console.error(`Unknown launch type specified: '${launchType}'`);
}

/**
 * A function for requesting a single patient
 *
 * @param {string} id - The id of the patient
 * @returns promise
 */
export async function getPatient(id) {
  errorIfUninitialized();
  const response = await query(`${URL_PATIENT}?_id=${id}`);
  return response;
}

/**
 * A function for creating a patient
 *
 * @param {object} patient - A FHIR patient object
 * @returns promise
 */
export async function createPatient(patient) {
  errorIfUninitialized();
  const url = urljoin(URL_PATIENT, extractResourceId(patient));
  const response = await query(url, requestPutOptions(patient));
  return response;
}
