// ValueSet resource
// http://hl7.org/fhir/R4/valueset.html
import { getValueSetFromState } from 'app/utils/stateHelpers';
import { isString, lowerCase, isArray, isPlainObject } from 'lodash';
import { extractCodeFromCodeableConcept } from './datatypes/CodeableConcept';

export function extractValueSetExpansion(valueSetBundle) {
  const values = isValueSetBundle(valueSetBundle) ? valueSetBundle.expansion.contains : [];
  return values;
}

export function bundleBatchValueSetBuilder(valueSetTypeArray) {
  const bundleBatch = { resourceType: 'Bundle', type: 'batch', entry: [] };
  const entries = valueSetTypeArray.map(valueSetType => ({
    request: { method: 'GET', url: valueSetType.relativePath },
  }));
  // assign new GET requests to the batch entries
  bundleBatch.entry = entries;
  return bundleBatch;
}

export function extractValueSetBatchEntries(valueSetBatchResult) {
  const values = isValueSetBatchResult(valueSetBatchResult) ? valueSetBatchResult.entry : [];
  return values;
}

export function extractValueSetFromBatchEntry(valueSetBatchEntry) {
  const values = isValueSetBatchEntry(valueSetBatchEntry) ? valueSetBatchEntry.resource.expansion.contains : [];
  return values;
}

// Helper function to validate an incoming valueSet bundle
function isValueSetBundle(bundle) {
  // valueSet bundle must have a resourceType, an element count > 0, and a contains object that holds the actual valueset values
  return (
    bundle?.resourceType === 'ValueSet' &&
    bundle?.expansion &&
    bundle?.expansion?.total > 0 &&
    bundle?.expansion?.contains
  );
}

// Helper function to validate an incoming valueSet batch-request
function isValueSetBatchResult(bundle) {
  // valueSet bundle must have a resourceType, an element count > 0, and a contains object that holds the actual valueset values
  return (
    bundle?.resourceType === 'Bundle' && bundle?.type === 'batch-response' && bundle?.entry && bundle?.entry?.length > 0
  );
}

// Helper function to validate a batch-request result entry
function isValueSetBatchEntry(batchEntry) {
  return (
    batchEntry?.response?.status === '200 OK' &&
    batchEntry?.resource &&
    batchEntry?.resource?.resourceType === 'ValueSet' &&
    batchEntry?.resource?.expansion &&
    (batchEntry?.resource?.expansion?.total === undefined || batchEntry?.resource?.expansion?.total > 0) &&
    batchEntry?.resource?.expansion?.contains
  );
}

// valueSet should be an array of a FHIR valueSet; value should be any string looking for a match.
export function getValueSetItemFromValue(valueSet, value) {
  if (isArray(valueSet) && isString(value)) {
    // normalize value string
    const _value = lowerCase(value);
    // check both code and display for a match
    const valueSetItem = valueSet.find(item => {
      const itemCode = lowerCase(item?.code);
      const itemDisplay = lowerCase(item?.display);
      return itemCode === _value || itemDisplay === _value;
    });
    return valueSetItem;
  }
}

// valueSet should be an array of a FHIR valueSet; coding should be a fhir Coding object looking for a match.
export function getValueSetItemFromCodeableConcept(valueSetType, codeableConcept) {
  if (isPlainObject(valueSetType) && isPlainObject(codeableConcept)) {
    const valueSet = getValueSetFromState(valueSetType);
    // some external resources (ELTSS) don't set the .text field,
    // so we attempt to extract the code in codeableConcept
    // NOTE: use .text as a last resort because it may not match identically to a valueSet display value
    const codeToFind = lowerCase(extractCodeFromCodeableConcept(codeableConcept) || codeableConcept?.text);

    // check both code and display for a match
    const valueSetItem = valueSet.find(item => {
      const itemCode = lowerCase(item?.code);
      const itemDisplay = lowerCase(item?.display);
      return itemCode === codeToFind || itemDisplay === codeToFind;
    });
    return valueSetItem;
  }
}

// valueSetType should be one of our pre-defined valueSet type objects
// code should be a string that matches up with an item in the requested valueSet
// Returns: the valueSet item object for the given code
export function getValueSetItemFromCode(valueSetType, code) {
  const valueSet = getValueSetFromState(valueSetType);
  return getValueSetItemFromValue(valueSet, code);
}

// valueSetType should be one of our pre-defined valueSet type objects
// code should be a string that matches up with an item in the requested valueSet
// Returns: the valueSet item display string for the given code
export function getValueSetDisplayFromCode(valueSetType, code) {
  const valueSetItem = getValueSetItemFromCode(valueSetType, code);
  return getValueSetItemDisplay(valueSetItem);
}

// Takes in a valueSet item and returns a value to display
export function getValueSetItemDisplay(valueSetItem) {
  if (isPlainObject(valueSetItem)) {
    return valueSetItem?.display || valueSetItem?.code;
  }
}

// Takes in a valueSet item and returns a value to display
export function getValueSetItemCode(valueSetItem) {
  if (isPlainObject(valueSetItem)) {
    return valueSetItem?.code;
  }
}
