import { LOCALE_SPANISH } from '../constants';

const locale = {
  locale: LOCALE_SPANISH,
  translation: {
    DASHBOARD: 'Tablero',
    MY_DASHBOARD: 'Mi Tablero',
    APPLICATIONS: 'Aplicaciones',
    PATIENTS: 'Pacientes',
    LIST_PATIENTS: 'Todos Pacientes',
    ADD_PATIENT: 'Añadir Paciente',
    PRACTITIONERS: 'Médicos',
    LIST_PRACTITIONERS: 'Todos Médicos',
    ADD_PRACTITIONER: 'Añadir Médico',
    PRACTITIONER_VIEWER: 'Médico Espectadora',
    ORGANIZATIONS: 'Organizaciones',
    CARE_TEAMS: 'Equipo de Cuidado',
    CARE_PLANS: 'Planes de Cuidado',
    MY_CARETEAMS: 'Mis Equipos de Cuidado',
    NEW_CAREPLAN: 'Nuevo Plan de Cuidados',
    LIST_CAREPLANS: 'Plan de Cuidados Lista',
    MY_CAREPLANS: 'Mis Planes de Cuidado',
    APPOINTMENTS: 'Citas',
    LIST_APPOINTMENTS: 'Lista de Citas',
    CREATE_APPOINTMENT: 'Crear Cita',
    ENCOUNTERS: 'Encuentros',
    LIST_ENCOUNTERS: 'Lista de Encuentros',
    CREATE_ENCOUNTER: 'Crear Encuentro',
    SMART_APPS: 'SMART Apps',
    ADMINISTRATION: 'Administración',
    REPORTS: 'Informes',
    MANAGE_SMART_APPS: 'Administrar SMART Apps',
    MANAGE_USERS: 'Administrar Usuarios',
    LIST_ORGANIZATIONS: 'Listar Organizaciones',
    ADD_ORGANIZATION: 'Agregar Organización',
  },
};

export default locale;
