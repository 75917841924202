import { store } from 'app/App';
import { AUTH_MANAGING_ORG_CLAIM } from 'app/auth/constants';
import { createReference } from 'app/resources/ReferenceResource';
import { isString, get, isEmpty, isPlainObject } from 'lodash';
import { isNonEmptyString, isNonEmptyArray } from './helpers';

// returns the FHIR resource the current user is bound to
export function getUserResourceBinding() {
  const { auth } = store.getState();
  const fhirBinding = auth?.user?.data?.fhirUser || null;
  return fhirBinding;
}

// returns the FHIR resource in an object for easy feeding to a FHIR reference
export function getUserResourceBindingObject() {
  const { auth } = store.getState();
  const fhirBinding = auth?.user?.data?.fhirUser || null;
  // check to ensure auth.user.security returned a FHIR resource binding
  if (isNonEmptyString(fhirBinding)) {
    // can handle fully qualified URLs as FHIR resource bindings (ex: SmileCDR demo server fhirUser)
    // NOTE: this assumes that the binding is the same as the server we are connected to.
    // if the binding is somewhere other than the currently connected server,
    // then any References created using the UserResourceBinding will not point to a valid resource.
    const fhirReferenceArray = fhirBinding.split('/');
    if (isNonEmptyArray(fhirReferenceArray)) {
      const resourceId = fhirReferenceArray.pop();
      const resourceType = fhirReferenceArray.pop();
      return { resourceType, resourceId };
    }
  }
  // return null if no FHIR binding is found
  return null;
}

export function getUserResourceBindingAsReference() {
  const currentUserResource = getUserResourceBindingObject();
  if (isPlainObject(currentUserResource)) {
    return createReference(currentUserResource.resourceType, currentUserResource.resourceId);
  }
}

export function getUserOrgBinding() {
  const { auth } = store.getState();
  const fhirBinding = auth ? auth?.user?.security[AUTH_MANAGING_ORG_CLAIM] : null;
  return fhirBinding;
}

// Get the current login type
export function getUserLoginOrigin() {
  const { auth } = store.getState();
  const origin = auth?.user?.from;

  if (origin === undefined) {
    console.error('Somehow the auth origin for the user is undefined!');
    return;
  }

  return origin;
}

// valueSetType is a locally defined object (src\app\resources\store\valueSet.constants.js)
// that defines valueSets used by the system
export function getValueSetFromState(valueSetType) {
  const { valueSets } = store.getState();
  const valueSet = valueSets ? valueSets[valueSetType.key] : null;
  return valueSet;
}

// Get local user settings from state; we'll typically use this to modify settings and then
// push a JSON patch to the server to update the user's new settings.
// User can also supply a path to return only a segment of the user settings state
export function getLocalUserSettings(path) {
  const { auth } = store.getState();
  const localSettings = auth?.settings;
  if (isString(path) && !isEmpty(path)) {
    // return a portion of the setting state
    return get(localSettings, path);
  }
  // return the entire setting state
  return localSettings;
}

export function getDarkModeSettingValue() {
  const { auth } = store.getState();
  return auth?.settings?.global?.darkMode;
}

export const createSmartSelector = selectorFunc => {
  let selectorFunctions = [selectorFunc];
  const selector = state => selectorFunctions.reduce((_state, nextFunc) => nextFunc(_state), state);
  selector.prepend = newFunction => {
    selectorFunctions = [newFunction, ...selectorFunctions];
  };

  return selector;
};

export const prependSelectors = (selectors, newFunc) => {
  selectors.forEach(selector => {
    selector.prepend(newFunc);
  });
};
