import React from 'react';
import Provider from 'react-redux/es/components/Provider';
import { ConnectedRouter } from 'connected-react-router';
// js-in-css
import jssExtend from 'jss-plugin-extend';
import { create } from 'jss';
import { StylesProvider, jssPreset, createGenerateClassName } from '@material-ui/styles';
import rtl from 'jss-rtl';
// app-wide Helmet functionality
import { HelmetProvider } from 'react-helmet-async';
// Pickers
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
// Local imports
import NexusLayout from '@nexus/core/NexusLayout';
import NexusTheme from '@nexus/core/NexusTheme';
import history from 'app/utils/history';
import AuthTimer from 'app/auth/AuthTimer';
import AuthenticationProvider from './auth/AuthenticationProvider';
import { configureStore } from './store';

// Configure redux store
export const store = configureStore({}, history);

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend(), rtl()],
  insertionPoint: document.getElementById('jss-insertion-point'),
});

const generateClassName = createGenerateClassName();

const App = () => (
  <StylesProvider jss={jss} generateClassName={generateClassName}>
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <HelmetProvider>
          <ConnectedRouter history={history}>
            <AuthenticationProvider>
              <NexusTheme>
                <AuthTimer />
                <NexusLayout />
              </NexusTheme>
            </AuthenticationProvider>
          </ConnectedRouter>
        </HelmetProvider>
      </MuiPickersUtilsProvider>
    </Provider>
  </StylesProvider>
);

export default App;
