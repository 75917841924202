import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import NexusNavigation from '@nexus/core/NexusNavigation';

function Navigation(props) {
  const navigation = useSelector(({ nexus }) => nexus.navigation);

  return (
    <NexusNavigation
      className={clsx('navigation', props.className)}
      navigation={navigation}
      layout={props.layout}
      dense={props.dense}
      active={props.active}
    />
  );
}

Navigation.defaultProps = {
  layout: 'vertical',
};

Navigation.propTypes = {
  className: PropTypes.string,
  layout: PropTypes.string,
  dense: PropTypes.bool,
  active: PropTypes.string,
};

export default React.memo(Navigation);
