import _ from '@lodash';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultSettings, navbarToggleMobile, navbarToggleFolded } from 'app/store/actions/nexus';

function NavbarToggleButton(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const settings = useSelector(({ nexus }) => nexus.settings.current);
  const { config } = settings.layout;

  return (
    <IconButton
      className={props.className}
      onClick={event => {
        if (mdDown) {
          dispatch(navbarToggleMobile());
        } else if (config.navbar.style === 'style-folded') {
          dispatch(setDefaultSettings(_.set({}, 'layout.config.navbar.folded', !settings.layout.config.navbar.folded)));
        } else {
          dispatch(navbarToggleFolded());
        }
      }}
      color="inherit"
      // size="small"
    >
      {props.children}
    </IconButton>
  );
}

NavbarToggleButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

NavbarToggleButton.defaultProps = {
  children: <Icon fontSize="inherit">menu</Icon>,
};

export default NavbarToggleButton;
