import React from 'react';
import moment from 'moment';
import {
  Divider,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Switch,
  Button,
  SwipeableDrawer,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer } from 'redux-injectors';
import { makeStyles } from '@material-ui/styles';
import { toggleDarkMode } from 'app/auth/store/actions';
import Auth0Service from 'app/services/auth0Service';
import NexusScrollbars from '@nexus/core/NexusScrollbars';
import { QuickPanelAppName } from 'app/store/stateConstants';
import { AUTH_TIMEOUT_WARNING_SECONDS, AUTH_TIMEOUT_BUFFER_SECONDS } from 'app/utils/appConstants';
import { useAuth0 } from '@auth0/auth0-react';
import { toggleQuickPanel } from './store/actions/index';
import reducer from './store/reducers';

const key = QuickPanelAppName;

const useStyles = makeStyles(theme => ({
  root: {
    width: 280,
  },
}));

function QuickPanel(props) {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  useInjectReducer({ key, reducer });
  const state = useSelector(({ quickPanel }) => quickPanel.state);
  const { darkMode } = useSelector(({ auth }) => auth?.settings?.global);

  const classes = useStyles();

  return (
    <SwipeableDrawer
      classes={{ paper: classes.root }}
      open={state}
      anchor="right"
      onOpen={() => {}}
      onClose={() => dispatch(toggleQuickPanel())}
      disableSwipeToOpen
    >
      <NexusScrollbars>
        <List>
          <ListSubheader component="div">Quick Settings</ListSubheader>
          {/* <ListItem>
            <ListItemIcon className="min-w-40">
              <Icon>notifications</Icon>
            </ListItemIcon>
            <ListItemText primary="Notifications" />
            <ListItemSecondaryAction>
              <Switch color="secondary" onChange={() => dispatch(toggleNotifications())} checked={notifications} />
            </ListItemSecondaryAction>
          </ListItem> */}
          <ListItem>
            <ListItemIcon className="min-w-40">
              <Icon>brightness_high</Icon>
            </ListItemIcon>
            <ListItemText primary="Dark Mode" />
            <ListItemSecondaryAction>
              <Switch color="secondary" onChange={() => dispatch(toggleDarkMode())} checked={darkMode} />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Divider />
        <Button className="mt-12" variant="contained" onClick={() => Auth0Service.refreshToken(getAccessTokenSilently)}>
          Refresh Token
        </Button>
        <Button
          className="mt-12"
          variant="contained"
          onClick={() => {
            const momentPlus = moment().add(AUTH_TIMEOUT_WARNING_SECONDS + AUTH_TIMEOUT_BUFFER_SECONDS + 15, 'seconds');
            localStorage.setItem('expires_at', momentPlus.format('x'));
          }}
        >
          Set nearby Expiration
        </Button>
        <Button
          className="mt-12"
          variant="contained"
          onClick={() => {
            // eslint-disable-next-line no-undef
            notafunction();
          }}
        >
          Simulate app crash
        </Button>
      </NexusScrollbars>
    </SwipeableDrawer>
  );
}

export default React.memo(QuickPanel);
