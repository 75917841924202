//
// Helper functions to take form data and return backend-friendly formatted data
//
import { isArray } from 'lodash';

export function createSmartAppBff(formData) {
  // Check to ensure formData that needs to be sent to the API as an array is in the proper format
  const scopes = isArray(formData?.scopes) ? formData.scopes : formData.scopes.split(',');
  const redirectUrl = isArray(formData?.redirectUrl) ? formData.redirectUrl : formData.redirectUrl.split(',');
  const allowedLaunchContexts = isArray(formData?.allowedLaunchContexts)
    ? formData.allowedLaunchContexts
    : formData.allowedLaunchContexts.split(',');
  const { name, description, iconString, launchUrl, identified } = formData;

  const smartAppData = {
    scopes,
    launchUrl,
    redirectUrl,
    name,
    description,
    allowedLaunchContexts,
    iconString,
    identified,
  };

  return smartAppData;
}

// Since the data for updateSmartApp is nearly identical, and not to repeat ourselves,
// we pass the form data to createSmartAppBff and append the clientId to the result.
export function updateSmartAppBff(formData) {
  const { clientId } = formData;

  const smartAppUpdateData = { ...createSmartAppBff(formData), clientId };

  return smartAppUpdateData;
}
