import { TOGGLE_QUICK_PANEL } from '../constants';

const initialState = {
  state: false,
  data: null,
};

const quickPanel = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_QUICK_PANEL: {
      return {
        ...state,
        state: !state.state,
      };
    }
    default: {
      return state;
    }
  }
};

export default quickPanel;
