import {
  NAVBAR_OPEN_FOLDED,
  NAVBAR_CLOSE_FOLDED,
  NAVBAR_TOGGLE_FOLDED,
  NAVBAR_TOGGLE_MOBILE,
  NAVBAR_OPEN_MOBILE,
  NAVBAR_CLOSE_MOBILE,
} from '../../nexusConstants';

const initialState = {
  foldedOpen: false,
  mobileOpen: false,
};

const navbar = (state = initialState, action) => {
  switch (action.type) {
    case NAVBAR_TOGGLE_FOLDED: {
      return {
        ...state,
        foldedOpen: !state.foldedOpen,
      };
    }
    case NAVBAR_OPEN_FOLDED: {
      return {
        ...state,
        foldedOpen: true,
      };
    }
    case NAVBAR_CLOSE_FOLDED: {
      return {
        ...state,
        foldedOpen: false,
      };
    }
    case NAVBAR_TOGGLE_MOBILE: {
      return {
        ...state,
        mobileOpen: !state.mobileOpen,
      };
    }
    case NAVBAR_OPEN_MOBILE: {
      return {
        ...state,
        mobileOpen: true,
      };
    }
    case NAVBAR_CLOSE_MOBILE: {
      return {
        ...state,
        mobileOpen: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default navbar;
