import { isArray } from 'lodash';

export function isActive(location, item) {
  const currentLocation = location?.pathname;
  if (item?.exact) {
    // match exact url (give or take a closing slash)
    if ((item?.url === currentLocation) || (item?.url === `${currentLocation}/`) ) return true;
  } else {
    // Check to see if item.url exists in the current location
    if ((currentLocation?.indexOf(item?.url)) > -1) {
      // check exclude URLs
      if (isArray(item?.urlExclude) && item.urlExclude?.length > 0) {
        // check alt urls for any matches
        const excludeMatches = item.urlExclude.filter(url => currentLocation.indexOf(url) > -1);
        if (excludeMatches.length > 0) return false;
      }
      return true;
    };
  }

  if (isArray(item?.urlAlt) && item.urlAlt?.length > 0) {
    // check alt urls for any matches
    const matches = item.urlAlt.filter(url => currentLocation.indexOf(url) > -1);
    return matches.length > 0;
  }
}
