/*
 * SmartApp settings constants
 */

import { SettingsNamespace, SmartAppsSettingName } from 'app/store/stateConstants';

const SmartAppsNamespace = `${SettingsNamespace}/${SmartAppsSettingName}`;

// Get full list of smartApps
export const GET_SMARTAPPS = `${SmartAppsNamespace}/GET_SMARTAPPS`;
export const GET_SMARTAPPS_SUCCESS = `${SmartAppsNamespace}/GET_SMARTAPPS_SUCCESS`;
export const GET_SMARTAPPS_ERROR = `${SmartAppsNamespace}/GET_SMARTAPPS_ERROR`;
// Get list of smartApps launchable by logged-in user
export const GET_LAUNCHABLE_SMARTAPPS = `${SmartAppsNamespace}/GET_LAUNCHABLE_SMARTAPPS`;
export const GET_LAUNCHABLE_SMARTAPPS_SUCCESS = `${SmartAppsNamespace}/GET_LAUNCHABLE_SMARTAPPS_SUCCESS`;
export const GET_LAUNCHABLE_SMARTAPPS_ERROR = `${SmartAppsNamespace}/GET_LAUNCHABLE_SMARTAPPS_ERROR`;
// Set list of launchable smartApps to state
export const SET_LAUNCHABLE_SMARTAPPS = `${SmartAppsNamespace}/SET_LAUNCHABLE_SMARTAPPS`;
// Create a new smartApp
export const CREATE_SMARTAPP = `${SmartAppsNamespace}/CREATE_SMARTAPP`;
export const CREATE_SMARTAPP_SUCCESS = `${SmartAppsNamespace}/CREATE_SMARTAPP_SUCCESS`;
export const CREATE_SMARTAPP_ERROR = `${SmartAppsNamespace}/CREATE_SMARTAPP_ERROR`;
// Update an existing smartApp
export const UPDATE_SMARTAPP = `${SmartAppsNamespace}/UPDATE_SMARTAPP`;
export const UPDATE_SMARTAPP_SUCCESS = `${SmartAppsNamespace}/UPDATE_SMARTAPP_SUCCESS`;
export const UPDATE_SMARTAPP_ERROR = `${SmartAppsNamespace}/UPDATE_SMARTAPP_ERROR`;
// Delete an existing smartApp
export const DELETE_SMARTAPP = `${SmartAppsNamespace}/DELETE_SMARTAPP`;
export const DELETE_SMARTAPP_SUCCESS = `${SmartAppsNamespace}/DELETE_SMARTAPP_SUCCESS`;
export const DELETE_SMARTAPP_ERROR = `${SmartAppsNamespace}/DELETE_SMARTAPP_ERROR`;
// Open smartApp dialog
export const OPEN_NEW_SMARTAPP_DIALOG = `${SmartAppsNamespace}/OPEN_NEW_SMARTAPP_DIALOG`;
export const OPEN_EDIT_SMARTAPP_DIALOG = `${SmartAppsNamespace}/OPEN_EDIT_SMARTAPP_DIALOG`;
// Close smartApp dialog
export const CLOSE_SMARTAPP_DIALOG = `${SmartAppsNamespace}/CLOSE_SMARTAPP_DIALOG`;

// List of smartApp launch contexts we support, with Chip decorator classes
export const AllowedLaunchContexts = [
  {
    value: 'Patient',
    label: 'Patient',
    class: 'bg-orange text-white',
  },
  {
    value: 'Encounter',
    label: 'Encounter',
    class: 'bg-blue text-white',
  },
];
