import NavLinkAdapter from '@nexus/core/NavLinkAdapter';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import NexusNavBadge from '../../NexusNavBadge';
import { isActive } from '../../navUtils';
import NavIcon from '../../NexusNavIcon';

const useStyles = makeStyles((theme) => ({
  item: (props) => ({
    height: 48,
    width: '100%',
    borderRadius: '6px',
    margin: '0 0 4px 0',
    paddingRight: 12,
    paddingLeft: props.itemPadding > 80 ? 80 : props.itemPadding,
    color: alpha(theme.palette.text.primary, 0.7),
    cursor: 'pointer',
    textDecoration: 'none!important',
    '&:hover': {
      color: theme.palette.text.primary,
    },
    '&.active': {
      color: theme.palette.secondary.contrastText, // theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.main,
        // theme.palette.type === 'light'
        //   ? 'rgba(0, 0, 0, .05)!important'
        //   : 'rgba(255, 255, 255, .1)!important',
      // pointerEvents: 'none',
      transition: 'border-radius .25s cubic-bezier(0.4,0.0,0.2,1)',
      '& .nexus-list-item-text-primary': {
        color: 'inherit',
      },
      '& .nexus-list-item-icon': {
        color: 'inherit',
      },
      '&:hover': {
        // backgroundColor: alpha(theme.palette.secondary[400], 0.9),
        backgroundColor: theme.palette.secondary[400],
      }
    },
    '& .nexus-list-item-icon': {
      marginRight: 12,
      color: 'inherit',
    },
    '& .nexus-list-item-text': {},
  }),
}));

function NexusNavVerticalItem(props) {
  const { item, nestedLevel, onItemClick } = props;
  const classes = useStyles({
    itemPadding: nestedLevel > 0 ? 28 + nestedLevel * 16 : 12,
  });

  return useMemo(
    () => (
      <ListItem
        button
        component={NavLinkAdapter}
        to={item.url}
        activeClassName="active"
        isActive={(match, location) => isActive(location, item)}
        className={clsx(classes.item, 'nexus-list-item')}
        onClick={() => onItemClick && onItemClick(item)}
        exact={item.exact}
      >
          <NavIcon className="nexus-list-item-icon text-20 flex-shrink-0" icon={item.icon} />
        <ListItemText
          className="nexus-list-item-text"
          primary={item.title}
          classes={{ primary: 'text-16 font-medium nexus-list-item-text-primary' }}
        />

        {item.badge && <NexusNavBadge badge={item.badge} />}
      </ListItem>
    ),
    [classes.item, item, onItemClick]
  );
}

NexusNavVerticalItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
  }),
};

NexusNavVerticalItem.defaultProps = {};

const NavVerticalItem = NexusNavVerticalItem;

export default NavVerticalItem;
