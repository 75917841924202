import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { URL_LOGIN } from 'app/utils/urlConstants';
import { tokenDataSelector } from './store/reducers/user.reducer';
import AuthenticationProviderContext from './AuthenticationProviderContext';
import Unauthorized from './Unauthorized';

function MeetsAuthRequirements({ scopes, children, authenticationRequired }) {
  const { scope: scopeString } = useSelector(tokenDataSelector);
  const hasScopes = scopeString ? scopeString.split(' ') : [];
  const { authenticationIsDone, loggedIn, systemMode } = useContext(AuthenticationProviderContext);

  if (!authenticationIsDone) {
    return null;
  }

  if (authenticationRequired && !loggedIn) {
    return <Redirect to={URL_LOGIN} />;
  }

  if (systemMode === 'SMART') {
    return <>{children}</>;
  }

  if (!scopes.every(scope => hasScopes.includes(scope))) {
    return <Unauthorized />;
  }

  return <>{children}</>;
}

MeetsAuthRequirements.propTypes = {
  scopes: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  authenticationRequired: PropTypes.bool,
};

export default MeetsAuthRequirements;
