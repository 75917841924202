/*
 * Auth constants
 */

import { AuthNamespace } from 'app/store/stateConstants';
import { URL_FHIR_BASE_URL } from 'app/utils/urlConstants';

export const LOGIN_ERROR = `${AuthNamespace}/LOGIN_ERROR`;
export const LOGIN_SUCCESS = `${AuthNamespace}/LOGIN_SUCCESS`;

export const REGISTER_ERROR = `${AuthNamespace}/REGISTER_ERROR`;
export const REGISTER_SUCCESS = `${AuthNamespace}/REGISTER_SUCCESS`;

export const SET_USER_DATA = `${AuthNamespace}/SET_USER_DATA`;
export const REMOVE_USER_DATA = `${AuthNamespace}/REMOVE_USER_DATA`;
export const SET_SECURITY_DATA = `${AuthNamespace}/SET_SECURITY_DATA`;
export const REMOVE_SECURITY_DATA = `${AuthNamespace}/REMOVE_SECURITY_DATA`;
export const USER_LOGGED_OUT = `${AuthNamespace}/USER_LOGGED_OUT`;

export const TOGGLE_DARK_MODE = `${AuthNamespace}/TOGGLE_DARK_MODE`;
export const SET_USER_LASTPATH = `${AuthNamespace}/SET_USER_LASTPATH`;

export const GET_USER_SETTINGS = `${AuthNamespace}/GET_USER_SETTINGS`;
export const GET_USER_SETTINGS_SUCCESS = `${AuthNamespace}/GET_USER_SETTINGS_SUCCESS`;
export const GET_USER_SETTINGS_ERROR = `${AuthNamespace}/GET_USER_SETTINGS_ERROR`;
export const SET_USER_SETTINGS = `${AuthNamespace}/SET_USER_SETTINGS`;
export const SET_USER_SETTINGS_ERROR = `${AuthNamespace}/SET_USER_SETTINGS_ERROR`;
export const SET_LOCAL_USER_SETTINGS = `${AuthNamespace}/SET_LOCAL_USER_SETTINGS`;

// Open/close Auth Timer dialog
export const SHOW_AUTH_TIMER = `${AuthNamespace}/SHOW_AUTH_TIMER`;
export const HIDE_AUTH_TIMER = `${AuthNamespace}/HIDE_AUTH_TIMER`;
// Track whether the user was forced to logout via timer
export const FORCED_LOGOUT = `${AuthNamespace}/FORCED_LOGOUT`;

// Set flag for logging out
export const SET_LOGGING_OUT = `${AuthNamespace}/SET_LOGGING_OUT`;

// Auth security constants
// NOTE: local and dev use the same hard-coded auth0 scope (https://auth0.com/docs/tokens/create-namespaced-custom-claims)
// so we can hard-code the URL value when in dev (either locally or on dev.carenexus.io).
// In production or other environments, we will defer to URL_FHIR_API which provides the correct URL
// for its build context.
export const AUTH_FHIR_RESOURCE_CLAIM = `${URL_FHIR_BASE_URL}/fhirResourceBinding`;
export const AUTH_MANAGING_ORG_CLAIM = `${URL_FHIR_BASE_URL}/managingOrganization`;
