import React from 'react';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { useSelector } from 'react-redux';

function FooterLayoutMain(props) {
  const footerTheme = useSelector(({ nexus }) => nexus.settings.footerTheme);

  return (
    <ThemeProvider theme={footerTheme}>
      <AppBar
        id="nexus-footer"
        className="relative z-10 shadow-md"
        color="default"
        style={{ backgroundColor: footerTheme.palette.background.paper }}
      >
        <Toolbar className="min-h-48 md:min-h-64 px-8 sm:px-12 py-0 flex items-center overflow-x-auto">
          <Typography>Footer</Typography>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default React.memo(FooterLayoutMain);
