import {
  isString,
  isArray,
  isObject,
  isPlainObject,
  isEmpty,
  get,
  isFunction,
  set,
  unset,
  has,
  cloneDeep,
  isBoolean,
  isInteger,
  keys,
} from 'lodash';
import { isSSN } from 'app/utils/validators';
import {
  createGuid,
  isAnyEmpty,
  isEmptyEmpty,
  isNonEmptyArray,
  isNonEmptyString,
  joinNonEmpty,
} from 'app/utils/helpers';
import { extractResourceIdFromReference, stripResourceFromReference } from './ReferenceResource';
import { extractFamilyName, extractGivenNameDisplay, findHumanName, HUMAN_NAME_FIELDS } from './datatypes/HumanName';
import {
  extractAddressCity,
  extractAddressLine1,
  extractAddressLine2,
  extractAddressPostalCode,
  extractAddressStateValueSetItem,
} from './datatypes/Address';

// Formats a name object in standard 'firstname lastname' format
export function formatNameStandard(name) {
  if (isPlainObject(name)) {
    const givenName = extractGivenNameDisplay(name);
    const familyName = extractFamilyName(name);
    return joinNonEmpty([givenName, familyName], ' ');
  }
}

// Formats a name object in 'lastname, firstname' format
export function formatNameComma(name) {
  if (isPlainObject(name)) {
    const givenName = extractGivenNameDisplay(name);
    const familyName = extractFamilyName(name);
    return joinNonEmpty([familyName, givenName], ', ');
  }
}

// format SSN
export function formatSSN(ssn) {
  if (isSSN(ssn)) {
    if (ssn.length === 9) {
      // ssn is missing dashes, put them in
      // return `${ssn.substring(0, 3)}-${ssn.substring(3, 2)}-${ssn.substring(5, 4)}`;
      return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`;
    }
    // ssn is valid and has dashes, return same string
    return ssn;
  }
}

// takes an address object and breaks it out into fields compatible with a RHF editor
export function addressToEditor(address) {
  const addressValues = {};
  if (isPlainObject(address)) {
    appendElement(addressValues, 'address1', extractAddressLine1(address), true);
    appendElement(addressValues, 'address2', extractAddressLine2(address), true);
    appendElement(addressValues, 'city', extractAddressCity(address), true);
    if (!appendElement(addressValues, 'state', extractAddressStateValueSetItem(address), true)) {
      addressValues.state = null;
    }
    appendElement(addressValues, 'zipCode', extractAddressPostalCode(address), true);
  } else {
    addressValues.state = null;
  }
  return addressValues;
}

// Takes a fhir resource and adds pertinent metadata info
export function addMetadata(resource, profile) {
  resource.meta = { profile, source: 'https://carenexus.io/api/v1/fhir' };
}

// Takes a fhir resource object and adds identifier information
export function addIdentifier(resource, resourceType) {
  resource.id = createGuid();
  resource.resourceType = resourceType;
}

// Takes a fhir resource and adds a narrative resource
export function addNarrative(resource, text, status) {
  // eslint-disable-next-line no-useless-escape
  const div = `<div xmlns="http://www.w3.org/1999/xhtml">${text}</div>`;
  resource.text = { div, status };
}

// takes an object array (we use it to filter valueSets) and filters by code property
export function filterByCode(objectArray, codeToSearch) {
  if (isArray(objectArray)) {
    return objectArray.filter(val => val?.code === codeToSearch);
  }
}

// get any object's value from a given path. Path can be any value supported by lodash get
export function extractValueFromPath(resource, path) {
  if (isPlainObject(resource)) {
    return get(resource, path);
  }
  if (isArray(resource) && resource.length > 0) {
    if (isPlainObject(resource[0])) {
      return get(resource[0], path);
    }
  }
}

// returns either the passed object or the first object item in an array
export function extractResourceFromObject(object) {
  if (isPlainObject(object)) {
    return object;
  }
  if (isArray(object) && object.length > 0) {
    if (isPlainObject(object[0])) {
      return object[0];
    }
  }
}

export function isResource(resourceObject) {
  if (isPlainObject(resourceObject)) {
    const resourceId = resourceObject?.id;
    const resourceType = resourceObject?.resourceType;
    return !isAnyEmpty(resourceType, resourceId);
  }
}

// get resource id from resource
export function extractResourceId(resource) {
  if (isPlainObject(resource)) {
    return resource?.id;
  }
}

// get resource id from resource
export function extractResourceType(resource) {
  if (isPlainObject(resource)) {
    return resource?.resourceType;
  }
}

export function extractNameFromResource(resource, formatNameFunc) {
  if (isPlainObject(resource)) {
    const names = extractValueFromPath(resource, 'name');
    if (isNonEmptyArray(names)) {
      const foundName = findHumanName(names, HUMAN_NAME_FIELDS.family);
      if (isFunction(formatNameFunc)) {
        return formatNameFunc(foundName);
      }
      return foundName;
    }
  }
}

/**
 * Find a resource from a resource array given a FHIR reference
 * @param {Object} reference FHIR reference used to find the associated resource
 * @param {Array} resourceArray an array of FHIR references to search from
 * @return {Object} returns a FHIR resource that matches with the passed reference
 */
export function findResourceFromReference(reference, resourceArray) {
  if (!isPlainObject(reference) || !isArray(resourceArray)) return;
  const referenceResourceId = extractResourceIdFromReference(reference);
  return findResourceById(resourceArray, referenceResourceId);
}

// This is a function to take a references array, ie. carePlan.addresses (an array of condition references)
// and find the matching resources in an array of resources.
export function findResourcesFromReferences(referencesArray, resourcesBundle) {
  if (isArray(referencesArray) && isArray(resourcesBundle)) {
    // filter goal Reference array in a given carePlan.goal array and
    // compare the reference id to each id in the goals extracted from the original bundle.
    const resources = resourcesBundle.filter(bundleResource => {
      // Loop through the references array and return any that match the current bundleResource
      for (let i = 0; i < referencesArray.length; i++) {
        if (bundleResource?.id === stripResourceFromReference(referencesArray[i]?.reference)) {
          return true;
        }
      }
      return false;
    });
    return resources;
  }
}

/**
 * Replaces FHIR references in an array of objects with the actual resource
 * @param {Array} resourceArray an array of objects that contain a FHIR reference we want replaced with the full resource
 * @param {String} resourceReferencePath path in the object array object to find the reference to be replaced
 * @param {Array} resources an array of resources to search references from
 * @return {Array} returns an array of objects with the reference path replaced with the actual resource
 */
export function replaceReferenceWithResource(objectArray, objectReferencePath, resources) {
  if (!isNonEmptyArray(objectArray) || !objectReferencePath || !isNonEmptyArray(resources)) return;

  // make a copy of the object array so that we're not modifying the original
  const objectArrayClone = cloneDeep(objectArray);

  // loop through object array copy and return each object with the reference replaced with an actual resource
  const updatedResources = objectArrayClone.map(resource => {
    // if path is not found, return resource untouched
    if (!has(resource, objectReferencePath)) return resource;
    // get a copy of the reference to find
    const resourceReference = get(resource, objectReferencePath);
    const resourceReferenceId = extractResourceIdFromReference(resourceReference);
    const foundResource = resources.find(_resource => _resource?.id === resourceReferenceId);
    if (foundResource) {
      // replace the reference path with the found resource and return the resulting object
      return set(resource, objectReferencePath, foundResource);
    }
    return resource;
  });
  return updatedResources;
}

/**
 * Replaces FHIR resources in an array of objects with a FHIR reference
 * @param {Array} resourceArray an array of objects that contain a FHIR resource we want replaced with a FHIR reference
 * @param {String} resourcePath path in the object array object to find the resource to be replaced
 * @return {Array} returns an array of objects with the reference path replaced with the actual resource
 */
export function replaceResourceWithReference(objectArray, objectReferencePath, resources) {
  if (!isNonEmptyArray(objectArray) || !objectReferencePath || !isNonEmptyArray(resources)) return;

  const updatedResources = objectArray.map(resource => {
    // if path is not found, return resource untouched
    if (!has(resource, objectReferencePath)) return resource;
    // get a copy of the reference to find
    const resourceReference = get(resource, objectReferencePath);
    const resourceReferenceId = extractResourceIdFromReference(resourceReference);
    const foundResource = resources.find(_resource => _resource?.id === resourceReferenceId);
    if (foundResource) {
      // replace the reference path with the found resource and return the resulting object
      return set(resource, objectReferencePath, foundResource);
    }
    return resource;
  });
  return updatedResources;
}

/**
 * Pushes an object to a FHIR resource array path. Handles missing paths intelligently
 * @param {Object} resource a FHIR resource
 * @param {String} resourceArrayPath path to an array in the FHIR resource
 * @param {Object} objectToPush an object to push to the resource array
 * @return returns a truthy value when the resource array has been successfully appended
 */
export function pushObjectToResourceArrayPath(resource, resourceArrayPath, objectToPush) {
  if (!isPlainObject(resource) || !isNonEmptyString(resourceArrayPath) || !isObject(objectToPush)) return false;
  if (!has(resource, resourceArrayPath)) {
    // resource doesn't yet have this property; create it
    return set(resource, resourceArrayPath, [objectToPush]);
  }
  if (!isArray(get(resource, resourceArrayPath))) {
    // resource has the path but it's not an array; abort
    return undefined;
  }
  // resource has the path and it's an array; push objectToPush
  return resource[resourceArrayPath].push(objectToPush);
}

/**
 * Pushes a new resource to an array of FHIR resources. Optionally skips any resources that already exist in the array.
 * @param {Array} resourceArray an array of FHIR resources
 * @param {Object} resourceToPush a FHIR resource to add to the array
 * @param {Boolean} ensureUnique ensures that the resource being added is unique (resource id comparison)
 * @return returns a truthy value when the resource has been successfully added to the array
 */
export function addResourceToResourceArray(resourceArray, resourceToPush, ensureUnique) {
  if (!isArray(resourceArray) || !isResource(resourceToPush)) return;
  if (findResourceIndexById(resourceArray, extractResourceId(resourceToPush)) === -1) {
    // resource with provided resource id was not found in the resourceArray; append a new resource
    return resourceArray.push(resourceToPush);
  }
  // resource was found in resourceArray; only push if unique not required
  if (!ensureUnique) {
    return resourceArray.push(resourceToPush);
  }
}

// From an array of resources, filter out resources that are passed in arrays via args.
export function filterResources(resourceArray, ...args) {
  if (isArray(resourceArray) && isArray(args)) {
    const filteredResources = resourceArray.filter(resource => {
      const id = resource?.id;
      // const resourceType = resource?.resourceType;
      for (let i = 0; i < args.length; i++) {
        if (isArray(args[i]) && args[i].length > 0) {
          // each argument should be an array of resources
          for (let j = 0; j < args[i].length; j++) {
            // handle both an array of resources as well as an array of resource ids
            if (id === args[i][j]?.id || id === args[i][j]) {
              // filter out the original by returning false for any match we find
              return false;
            }
          }
        }
      }
      // keep any original resources that were not found in the args
      // by returning true here at the end of our search
      return true;
      // return false;
    });
    return filteredResources;
  }
}

// From an array of resources, filter out resources that are passed in arrays via args.
// sourcePath is the path to check in the resourceArray objects;
// argsPath checks the path in the args objects for matches with the resourceArray sourcePath property.
export function filterResourcesCustomPath(resourceArray, sourcePath = 'id', argsPath = 'id', ...args) {
  if (isArray(resourceArray) && isArray(args)) {
    const filteredResources = resourceArray.filter(resource => {
      const id = get(resource, sourcePath);
      // const resourceType = resource?.resourceType;
      for (let i = 0; i < args.length; i++) {
        if (isArray(args[i]) && args[i].length > 0) {
          // each argument should be an array of resources
          for (let j = 0; j < args[i].length; j++) {
            // handle both an array of resources as well as an array of resource ids
            if (id === get(args[i][j], argsPath) || id === args[i][j]) {
              // filter out the original by returning false for any match we find
              return false;
            }
          }
        }
      }
      // keep any original resources that were not found in the args
      // by returning true here at the end of our search
      return true;
      // return false;
    });
    return filteredResources;
  }
}

// searches object for a property that starts with the passed property prefix
export function findObjectPropertyName(object, propertyPrefix) {
  if (!isPlainObject(object)) return;
  const keysArray = keys(object);
  if (isNonEmptyArray(keysArray)) {
    const propertyName = keysArray.find(item => {
      if (isString(item)) {
        return item.startsWith(propertyPrefix);
      }
      return false;
    });
    return propertyName;
  }
}

export function findResourceById(resourceArray, resourceId) {
  if (isArray(resourceArray) && !isEmpty(resourceId)) {
    return resourceArray.find(resource => resource?.id === resourceId);
  }
}

export function findResourceByReferece(resourceArray, reference) {
  if (isArray(resourceArray) && isPlainObject(reference)) {
    return resourceArray.find(resource => resource?.id === extractResourceIdFromReference(reference));
  }
}

export function findResourceByValue(resourceArray, path, resourceValue) {
  if (isArray(resourceArray) && !isEmpty(resourceValue)) {
    return resourceArray.find(resource => get(resource, path) === resourceValue);
  }
}

export function findResourceByType(resourceArray, path, resourceValue) {
  if (isArray(resourceArray) && !isEmpty(resourceValue)) {
    return resourceArray.find(resource => get(resource, path) === resourceValue);
  }
}

/**
 * Finds a resource that matches a value in an array object in that resource.
 *
 * @param {array} resourceArray - An array of resources to be searched
 * @param {string} pathToArray - Path to the array object in the resource to search
 * @param {string} arrayItemPath - Path in the array object to search
 * @param {string} arrayItemValue - The value we're looking for in the given resource array path
 * @returns FHIR resource, or undefined if no resources match the search
 */
export function findResourceByArrayPathValue(resourceArray, pathToArray, arrayItemPath, arrayItemValue) {
  if (isAnyEmpty(resourceArray, pathToArray, arrayItemPath, arrayItemValue)) return;

  return resourceArray.find(resource => {
    const resourceInternalArray = get(resource, pathToArray);
    if (isNonEmptyArray(resourceInternalArray)) {
      const foundItem = findResourceByValue(resourceInternalArray, arrayItemPath, arrayItemValue);
      if (foundItem) return resource;
    }
    return false;
  });
}

export function findResourceIndexById(resourceArray, resourceId) {
  if (isArray(resourceArray) && !isEmpty(resourceId)) {
    return resourceArray.findIndex(resource => resource?.id === resourceId);
  }
}

// will append an existing object with the passed elementToAppend at the given path
export function appendElement(object, path, elementToAppend, ignoreEmptyElements) {
  // ensure that we're appending a plain JS object with a non-empty data element
  if (
    isPlainObject(object) &&
    isNonEmptyString(path) &&
    (isObject(elementToAppend) || isString(elementToAppend) || isBoolean(elementToAppend) || isInteger(elementToAppend))
  ) {
    // object, path and elementToAppend are valid; determine whether or not to append based on ignoreEmpty argument
    if (ignoreEmptyElements) {
      // if elementToAppend is empty, return false, else set the element to the object
      return isEmptyEmpty(elementToAppend) ? false : set(object, path, elementToAppend);
    }
    // ignoreEmptyElements is false, set the elementToAppend to object regardless of whether it has data or not
    return set(object, path, elementToAppend);
  }
}

// remove the passed path from an object
// returns true if property removal was successful
export function removeElement(object, path) {
  if (isPlainObject(object) && isNonEmptyString(path)) {
    return unset(object, path);
  }
}
