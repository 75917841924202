/**
 * index.js
 *
 * This is the entry file for the application.
 *
 */

// By default, this project supports all modern browsers.
// Support for Internet Explorer 11 requires polyfills.
// For to support Internet Explorer 11, install react-app-polyfill,
// https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import '@fontsource/source-sans-pro';
import 'styles/app-base.css';
import 'styles/app-components.css';
import 'styles/app-utilities.css';
import * as sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { LicenseManager } from 'ag-grid-enterprise';
import './i18n';
import App from 'app/App';
import * as serviceWorker from './serviceWorker';

// react-axe reports accessibility issues at runtime in the Chrome dev console
if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

if (process.env.NODE_ENV === 'production') {
  sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
  });
}

LicenseManager.setLicenseKey(process.env.REACT_APP_LICENSE_AG_GRID);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
