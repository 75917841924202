import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .logo-icon': {
      width: 28,
      height: 28,
      transition: theme.transitions.create(['width', 'height'], {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
    '& .logo-text': {
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
  },
}));

function Logo() {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, 'flex items-center')}>
      <img className="logo-icon" src="assets/images/logos/CareNexus-icon-white-blue.svg" alt="CareNexus logo icon" />
      <div className="pl-6 w-192">
        <img className="logo-text" src="assets/images/logos/CareNexus-logo-full-dark.svg" alt="CareNexus" />
      </div>
    </div>
  );
}

export default Logo;
