import { DIALOG_OPEN, DIALOG_CLOSE } from '../../nexusConstants';

const initialState = {
  state: false,
  options: {
    children: null,
  },
};

const dialog = (state = initialState, action) => {
  switch (action.type) {
    case DIALOG_OPEN: {
      return {
        ...state,
        state: true,
        options: {
          ...state.options,
          ...action.options,
        },
      };
    }
    case DIALOG_CLOSE: {
      return {
        ...state,
        state: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default dialog;
