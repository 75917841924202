import React from 'react';

function NexusSplashScreen() {
	return (
		<div id="nexus-splash-screen">
			<div className="center">
				<div className="logo">
					<img width="256" src="assets/images/logos/CareNexus-logo-stacked-dark.svg" alt="CareNexus Logo" />
				</div>
				<div className="spinner-wrapper">
					<div className="spinner">
						<div className="inner">
							<div className="gap" />
							<div className="left">
								<div className="half-circle" />
							</div>
							<div className="right">
								<div className="half-circle" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default React.memo(NexusSplashScreen);
