/* eslint-disable class-methods-use-this */
import Auth0Lock from 'auth0-lock';
import jwtDecode from 'jwt-decode';
import { isInteger, isEmpty, now } from 'lodash';
import { differenceInSeconds } from 'date-fns';
// eslint-disable-next-line import/no-cycle
import { requestWithToken, requestPatchOptions } from 'app/utils/request';
import { URL_API_METADATA_USER, URL_API_METADATA_SECURITY } from 'app/utils/urlConstants';
import AUTH_CONFIG from './auth0ServiceConfig';
import { auth0Scopes } from './authScopes';

const auth0Theme = {
  logo: '/assets/images/logos/CareNexus-logo-stacked.svg',
  primaryColor: '#425563',
};

class Auth0Service {
  constructor() {
    this.init();
  }

  sdk = { auth0Manage: null };

  init() {
    // Sanity check for auth0 config
    if (Object.entries(AUTH_CONFIG).length === 0 && AUTH_CONFIG.constructor === Object) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('Missing Auth0 configuration');
      }
      return false;
    }

    // Set up auth0-lock
    this.lock = new Auth0Lock(AUTH_CONFIG.clientId, AUTH_CONFIG.domain, {
      container: 'auth0login',
      autoclose: true,
      socialButtonStyle: 'big',
      allowAutocomplete: true,
      usernameStyle: 'email',
      rememberLastLogin: false,
      // Was getting seemingly random invalid_token responses when logging in; this appears to have fixed it
      leeway: 60,
      // Useful for production deployment
      configurationBaseUrl: AUTH_CONFIG.configurationBaseUrl,
      auth: {
        // redirect: false,
        redirectUrl: AUTH_CONFIG.callbackUrl,
        responseType: 'token id_token',
        audience: `${AUTH_CONFIG.api_id}`,
        params: {
          scope: auth0Scopes,
        },
      },
      languageDictionary: {
        title: 'CareNexus Login',
      },
      theme: auth0Theme,
    });
    // Assign authentication events to auth0-lock instance
    this.handleAuthentication();
    return true;
  }

  handleAuthentication = () => {
    if (!this.lock) {
      return false;
    }

    // Add a callback for Lock's `authenticated` event
    this.lock.on('authenticated', this.setSession);
    // Add a callback for Lock's `authorization_error` event
    this.lock.on('authorization_error', err => {
      console.warn(`Error: ${err.error}. Check the console for further details.`);
    });
    return true;
  };

  login = () => {
    if (!this.lock) {
      console.warn("Auth0 Service didn't initialize, check your configuration");
      return false;
    }
    // Call the show method to display the widget.
    return this.lock.show();
  };

  register = () => {
    if (!this.lock) {
      console.warn("Auth0 Service didn't initialize, check your configuration");
      return false;
    }

    return this.lock.show({
      initialScreen: 'signUp',
    });
  };

  onAuthenticated = callback => {
    if (!this.lock) {
      return false;
    }
    this.lock.on('authenticated', callback);
  };

  // left here for completeness, but /callback useEffect was only triggering on first auth error
  onAuthError = callback => {
    if (!this.lock) {
      return false;
    }
    this.lock.on('authorization_error', callback);
  };

  setSession = (accessToken, expiresIn) => {
    // Set the time (in ms) that the access token will expire at
    const expiresAt = JSON.stringify(expiresIn * 1000);
    localStorage.setItem('access_token', accessToken);
    // localStorage.setItem('id_token', idToken);
    localStorage.setItem('expires_at', expiresAt);
  };

  // less resource-intensive auth check to perform on new page renders
  isNotExpired = () => {
    if (!this.lock) {
      return false;
    }
    // Check whether the current time is past the access token's expiry time
    const expiresAt = this.getExpiresAt();
    if (isInteger(expiresAt)) {
      if (now() < expiresAt) {
        return true;
      }
    }
    return false;
  };

  async updateUserData(user) {
    const auth0UserUrl = `https://${AUTH_CONFIG.domain}/userinfo`;
    const dataObj = JSON.stringify({ user });
    try {
      return await requestWithToken(auth0UserUrl, requestPatchOptions(dataObj));
    } catch (err) {
      console.warn('Cannot update user data', err);
    }
  }

  async getSecurityMetadata() {
    const securityMetadataUrl = `${URL_API_METADATA_SECURITY}`;
    try {
      return await requestWithToken(securityMetadataUrl);
    } catch (err) {
      console.warn('Cannot retrieve security metadata', err);
    }
  }

  async getUserMetadata() {
    const userMetadataUrl = `${URL_API_METADATA_USER}`;
    try {
      return await requestWithToken(userMetadataUrl);
    } catch (err) {
      console.warn('Cannot retrieve user metadata', err);
    }
  }

  async updateUserMetadata(user) {
    const userMetadataUrl = `${URL_API_METADATA_USER}`;
    // const dataObj = JSON.stringify({ userMetadata });

    try {
      return await requestWithToken(userMetadataUrl, requestPatchOptions({ user }));
    } catch (err) {
      console.warn('Cannot update user metadata', err);
    }
  }

  // get access token from local storage
  getAccessToken = () => window.localStorage.getItem('access_token');

  // get decoded id token data
  getAccessTokenData = () => {
    const accessToken = this.getAccessToken();
    if (accessToken) {
      const decodedToken = jwtDecode(accessToken);
      if (decodedToken) {
        return decodedToken;
      }
    }
    // No valid token found
    return null;
  };

  // validate that our access token is valid
  validateAccessToken = () => {
    const accessTokenData = this.getAccessTokenData();
    if (!isEmpty(accessTokenData)) {
      const { iat, exp } = accessTokenData;
      if (isInteger(iat) && isInteger(exp)) {
        // access token has valid issued-at and expiry keys; determine whether token is still valid
        if (iat < exp && this.getNowEpoch() < exp)
          // token has sane iat and exp values, and exp is not yet expired; token is valid!
          return true;
      }
    }
  };

  // get id token from local storage
  getIdToken = () => window.localStorage.getItem('id_token');

  // get decoded id token data
  getIdTokenData = () => {
    const idToken = this.getIdToken();
    if (idToken) {
      const decodedToken = jwtDecode(idToken);
      if (decodedToken) {
        return decodedToken;
      }
    }
    // No valid token found
    return null;
  };

  // refresh token
  async refreshToken(getAccessToken) {
    const accessToken = await getAccessToken({
      audience: AUTH_CONFIG.api_id,
      scope: auth0Scopes,
    });

    const accessTokenData = jwtDecode(accessToken);
    this.setSession(accessToken, accessTokenData.exp);
  }

  // get time the access_token expires
  getExpiresAt = () => JSON.parse(localStorage.getItem('expires_at'));

  // get time, in seconds, until token expires
  tokenExpiresInSeconds = () => {
    const expiresAt = this.getExpiresAt();
    if (isInteger(expiresAt)) {
      const secondsUntilExpire = differenceInSeconds(expiresAt, now());
      return secondsUntilExpire <= 0 ? 0 : secondsUntilExpire;
    }
    return 0;
  };

  // Show lock component on assigned div
  show = () => {
    this.lock.show();
  };

  // Hide lock component
  hide = () => {
    this.lock.hide();
  };
}

// Retain Auth0Service singleton for life of app
const instance = new Auth0Service();

export default instance;
