import NexusLoading from '@nexus/core/NexusLoading';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * React Suspense defaults
 * For to Avoid Repetition
 */ function NexusSuspense(props) {
	return <React.Suspense fallback={<NexusLoading {...props.loadingProps} />}>{props.children}</React.Suspense>;
}

NexusSuspense.propTypes = {
	loadingProps: PropTypes.object
};

NexusSuspense.defaultProps = {
	loadingProps: {
		delay: 0
	}
};

export default NexusSuspense;
