//
// *** Date and Time helper functions ***
//
/* eslint-disable no-nested-ternary */
import { isString, isEmpty, isPlainObject, toInteger, cloneDeep, now, toLower, isArray } from 'lodash';
import {
  format,
  isDate,
  isSameDay,
  addMinutes,
  differenceInMinutes,
  formatISO,
  parseISO,
  toDate,
  isFuture,
  isPast,
  isBefore,
  isValid,
  startOfWeek,
} from 'date-fns';
import moment from 'moment';
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_SLASHES } from 'app/resources/resourceConstants';
import { GLOBAL_LOCALE } from './appConstants';
import { isAnyEmpty, isNonEmptyString, stripDigits } from './helpers';

export function getCurrentDate() {
  const dateObj = new Date();
  const currentDate = new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate(), 0, 0, 0, 0);
  return currentDate;
}

export function getStartOfWeek(date = now(), weekStartsOn = 1) {
  const startOfWeekDate = startOfWeek(date, { weekStartsOn });
  return startOfWeekDate;
}

export function nowDate() {
  return toDate(now());
}

// expects a date object as argument; returns whether or not the date is valid
export function isValidDate(date) {
  if (isDate(date)) {
    // return date-fns function result to check for date validity
    return isValid(date);
  }
  // passed date is not a date object, return false
  return false;
}

// expects two date objects; returns whether or not they are the same day
export function isSameDate(date1, date2) {
  if (isValidDate(date1) && isValidDate(date2)) {
    return isSameDay(date1, date2);
  }
  // one or both arguments is not a valid date object; return false
  return false;
}

// Checks for mm/dd/yyyy or mm-dd-yyyy date format in a given string
export function isValidUSDateString(dateString) {
  if (isNonEmptyString(dateString)) {
    // date regular expression
    const dateRegExp = new RegExp('^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$');
    const res = dateRegExp.test(dateString);
    return res;
  }
}

// determines whether variable and checks to see if it's a date or a valid valid date-onlys string (i.e. mm/dd/yyyy))
export function isDateOrDateString(date) {
  return isDate(date) || isValidUSDateString(date);
}

// take a date-only string in slash format (12/11/2020) and reformats it
export function formatSlashDate(dateString, formatString) {
  if (isValidUSDateString(dateString) && isNonEmptyString(formatString)) {
    const date = getDateFromDateString(dateString, DATE_FORMAT_SLASHES);
    return getDateStringFromDate(date, formatString);
  }
}

// handles either a date object or a dateOnly string
export function isFutureDate(date) {
  if (isDate(date)) {
    return isFuture(date);
  }
  if (isValidUSDateString(date)) {
    const dateFromString = getDateFromDateString(date, DATE_FORMAT_SLASHES);
    return isFuture(dateFromString);
  }
}

// handles either a date object or a dateOnly string
export function isPastDate(date) {
  if (isDate(date)) {
    return isPast(date);
  }
  if (isValidUSDateString(date)) {
    const dateFromString = getDateFromDateString(date, DATE_FORMAT_SLASHES);
    return isPast(dateFromString);
  }
}

export function isSameOrFutureDay(date, dateToCompare) {
  if (isDate(date) && isDate(dateToCompare)) {
    return isSameDay(date, dateToCompare) || isBefore(date, dateToCompare);
  }
}

// this will take an arbitrary time string, i.e. '12:00 PM'
// and convert it into its hour and minute components.
export function parseTime(timeString) {
  if (isString(timeString)) {
    const part = timeString.match(/(\d+):(\d+)(?: )?(am|pm)?/i);
    if (part.length < 3) return;
    let hh = parseInt(part[1], 10);
    const mm = parseInt(part[2], 10);
    const ap = part[3] ? part[3].toUpperCase() : null;
    if (ap === 'AM') {
      if (hh === 12) {
        hh = 0;
      }
    }
    if (ap === 'PM') {
      if (hh !== 12) {
        hh += 12;
      }
    }
    return { hh, mm };
  }
}
// Takes two time strings, i.e. '10:15 AM' or '23:00'
// returns an object with the time diff in hours and minutes

export function timeDiff(time1, time2) {
  const time1Obj = parseTime(time1);
  const time2Obj = parseTime(time2);

  // abort if we were unable to parse either provided time
  if (isAnyEmpty(time1Obj, time2Obj)) return;

  //
  const hourDiff = toInteger(time1Obj.hh) - toInteger(time2Obj.hh);
  const minuteDiff = toInteger(time1Obj.mm) - toInteger(time2Obj.mm);

  return { hourDiff, minuteDiff };
}

// returns 1 if time1 is less than time2
// returns 0 if time1 is equal to time2
// returns -1 if time1 is greater than time2
export function whichTimeIsGreater(time1, time2) {
  const _timeDiff = timeDiff(time1, time2);
  if (!isPlainObject(_timeDiff)) return;

  const { hourDiff, minuteDiff } = _timeDiff;
  if (hourDiff === 0) {
    // time is within the same hour; check minutes
    if (minuteDiff > 0) {
      return -1;
    }
    if (minuteDiff < 0) {
      return 1;
    }
    return 0;
  }
  // time diff is greater than an hour; evaluate hour only
  if (hourDiff > 0) {
    return -1;
  }
  if (hourDiff < 0) {
    return 1;
  }
  return 0;
}

export function formatTime(time, _format, step) {
  if (isEmpty(time) || !isString(time)) return;
  // clean up time entry by trimming off any extra info like mins/hrs from another time
  // -- usually provided by timeSlot generator, i.e. 12:30 AM (30 mins)
  const cleanTime = time.split('(')[0];
  let hour;
  let minute;
  let stepMinute;
  const defaultFormat = 'g:i A';
  let pm = cleanTime.match(/p/i) !== null;
  const num = cleanTime.replace(/[^0-9]/g, '');

  // Parse for hour and minute
  switch (num.length) {
    case 4:
      hour = parseInt(num[0] + num[1], 10);
      minute = parseInt(num[2] + num[3], 10);
      break;
    case 3:
      hour = parseInt(num[0], 10);
      minute = parseInt(num[1] + num[2], 10);
      break;
    case 2:
    case 1:
      hour = parseInt(num[0] + (num[1] || ''), 10);
      minute = 0;
      break;
    default:
      return '';
  }

  // Make sure hour is in 24 hour format
  if (pm === true && hour > 0 && hour < 12) hour += 12;

  // Force pm for hours between 13:00 and 23:00
  if (hour >= 13 && hour <= 23) pm = true;

  // Handle step
  if (step) {
    // Step to the nearest hour requires 60, not 0
    if (step === 0)
      // eslint-disable-next-line no-param-reassign
      step = 60;
    // Round to nearest step
    stepMinute = (Math.round(minute / step) * step) % 60;
    // Do we need to round the hour up?
    if (stepMinute === 0 && minute >= 30) {
      hour += 1;
      // Do we need to switch am/pm?
      if (hour === 12 || hour === 24) pm = !pm;
    }
    minute = stepMinute;
  }

  // Keep within range
  if (hour <= 0 || hour >= 24) hour = 0;
  if (minute < 0 || minute > 59) minute = 0;

  // Format output
  return (
    (_format || defaultFormat)
      // 12 hour without leading 0
      .replace(/g/g, hour === 0 ? '12' : 'g')
      .replace(/g/g, hour > 12 ? hour - 12 : hour)
      // 24 hour without leading 0
      .replace(/G/g, hour)
      // 12 hour with leading 0
      .replace(/h/g, hour.toString().length > 1 ? (hour > 12 ? hour - 12 : hour) : `0${hour > 12 ? hour - 12 : hour}`)
      // 24 hour with leading 0
      .replace(/H/g, hour.toString().length > 1 ? hour : `0${hour}`)
      // minutes with leading zero
      .replace(/i/g, minute.toString().length > 1 ? minute : `0${minute}`)
      // simulate seconds
      .replace(/s/g, '00')
      // lowercase am/pm
      .replace(/a/g, pm ? 'pm' : 'am')
      // lowercase am/pm
      .replace(/A/g, pm ? 'PM' : 'AM')
  );
}

/**
 * Converts a date string to a date object (momentjs)
 *
 * @param dateString - A string representing a date
 * @return {date} a Date object
 */
export function getDateFromString(dateString) {
  const date = moment(dateString).toDate();
  return date;
}

// converts a date string to an ISO date
export function getISODateFromString(dateString) {
  const date = parseISO(dateString);
  if (isValidDate(date)) {
    return date;
  }
}

// extract date only (i.e. 11/27/2020) from a date string in the user-provided format (i.e. MM/dd/yyyy)
// TODO: Consider consolidating with another function
export function getDateOnlyFromString(dateString, dateFormatString) {
  try {
    if (isNonEmptyString(dateString)) {
      const date = moment(dateString).toDate();
      return format(date, dateFormatString);
    }
  } catch (err) {
    // todo: send error to Sentry
    return undefined;
  }
}

// Takes in a date-only string (i.e. 07/22/1980) and converts it into a date object
// https://stackoverflow.com/a/25961926/391076
export function getDateFromDateString(dateString, incomingDateFormat) {
  if (isNonEmptyString(dateString) && isNonEmptyString(incomingDateFormat)) {
    // determine the date delimiter by checking the first non-numeric character
    const delimiter = stripDigits(dateString).charAt(0);
    if (delimiter) {
      // extract date and formatting elements
      const formatElements = toLower(incomingDateFormat).split(delimiter);
      const dateElements = dateString.split(delimiter);
      // extract index of each format element
      const monthIndex = formatElements.indexOf('mm');
      const dayIndex = formatElements.indexOf('dd');
      const yearIndex = formatElements.indexOf('yyyy');
      // sanity check to ensure everything is a-okay
      if (isEmpty(dateElements) || monthIndex < 0 || dayIndex < 0 || yearIndex < 0) {
        // couldn't find all components of dateFormat; abort
        return;
      }
      // Months start at 0 when creating a new date
      const monthValue = parseInt(dateElements[monthIndex], 10) - 1;
      // Create new date object
      const formattedDate = new Date(dateElements[yearIndex], monthValue, dateElements[dayIndex]);
      return formattedDate;
    }
  }
}

// extract date string from date object
export function getDateStringFromDate(date, dateFormatString = DATE_FORMAT_DEFAULT) {
  try {
    if (isDate(date)) {
      const formattedDate = format(date, dateFormatString);
      return formattedDate;
    }
  } catch (err) {
    // todo: send error to Sentry
    return undefined;
  }
}

/**
 * Takes a date string or date object and returns an ISO-friendly date string
 *
 * @param date - Either a Date object or a string representing a date
 * @return {date} an ISO-friendly date string (i.e. 1991-02-20)
 */
export function extractISODateOnlyFromObject(dateObject) {
  if (isNonEmptyString(dateObject)) {
    const date = getDateFromString(dateObject);
    if (isValidDate(date)) {
      return format(date, DATE_FORMAT_DEFAULT);
    }
  }
  if (isDate(dateObject)) {
    // probably set by a browser autofill
    return getDateStringFromDate(dateObject, DATE_FORMAT_DEFAULT);
  }
}

/**
 * Takes a date string or date object and sets the time according to the passed time string
 *
 * @param date - Either a Date object or a string representing a date
 * @param time - A string representing a time, i.e. '1:23 AM'
 * @return {date} a date object representing the merged date and time
 */
export function composeDateTime(date, time) {
  // convert to date object if date param is not yet a date object
  const dateObj = isDate(date) ? cloneDeep(date) : moment(date).toDate();
  // abort if we don't have a valid date object or time string
  if (!isDate(dateObj) || isEmpty(time) || !isString(time)) return;

  const parsedTime = parseTime(time);
  if (isPlainObject(parsedTime)) {
    dateObj.setHours(parsedTime.hh);
    dateObj.setMinutes(parsedTime.mm);
    dateObj.setSeconds('00');
  }
  return dateObj;
}

// this function will take two dates and return a formatted string with the time between them
export function calculateTimeDiffDisplay(dateEarlier, dateLater) {
  const diffMinutes = differenceInMinutes(dateLater, dateEarlier);
  const diffMinutesInHours = diffMinutes / 60;
  const diffMinutesInHoursDisplay = diffMinutesInHours.toLocaleString(GLOBAL_LOCALE, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  if (diffMinutes < 60) {
    return `(${diffMinutes} mins)`;
  }

  return `(${diffMinutesInHoursDisplay} hr${diffMinutesInHours === 1 ? '' : 's'})`;
}

// createTimeSlots returns an object array of time entries at a given interval
// from the start time until the end of the day.
export function createTimeSlots(startDate, intervalMinutes = 15, includeTimeDiff = false, formatDateString = 'h:mm a') {
  if (isDate(startDate)) {
    const timeSlots = [];
    // initialize timeSlotDate with the startDate
    let timeSlotDate = startDate;

    // increment timeSlotDate until it is no longer on the same day
    while (isSameDay(startDate, timeSlotDate)) {
      const timeSlotDisplayValue = format(timeSlotDate, formatDateString);
      const timeSlotDropdownValue = includeTimeDiff
        ? `${timeSlotDisplayValue} ${calculateTimeDiffDisplay(startDate, timeSlotDate)}`
        : `${timeSlotDisplayValue}`;
      timeSlots.push({
        fullDate: formatISO(timeSlotDate),
        display: timeSlotDisplayValue,
        displayDropdown: timeSlotDropdownValue,
      });
      timeSlotDate = addMinutes(timeSlotDate, intervalMinutes);
    }
    return timeSlots;
  }
}

export function createTimeSlotObject(fullDate, timeDisplayValue, timeDropdownValue) {
  return {
    fullDate,
    display: timeDisplayValue,
    displayDropdown: timeDropdownValue || timeDisplayValue,
  };
}

// compares two timeSlot objects to see if they are substantially similar.
// we ignore displayDropdown because that may contain timeDiff information
export function timeSlotMatch(timeSlot1, timeSlot2) {
  const sameFullDate = timeSlot1?.fullDate === timeSlot2?.fullDate;
  const sameDisplayValue = timeSlot1?.display === timeSlot2?.display;
  return sameFullDate && sameDisplayValue;
}

// Encounter-related timeSlot functions
// TODO: Migrate AppointmentDialog to use updated timeSlot functions

// createTimeSlots returns an object array of time entries at a given interval
// from the start time until the end of the day.
export function createEncounterTimeSlots(
  startDateTime,
  intervalMinutes = 15,
  includeTimeDiff = false,
  formatDateString = 'h:mm a',
) {
  const timeSlots = [];
  const startDate = isValidDate(startDateTime) ? startDateTime : getCurrentDate();
  // initialize timeSlotDate with the startDate, or current date if it doesn't exist
  let timeSlotDate = startDate;

  // increment timeSlotDate until it is no longer on the same day
  while (isSameDay(startDate, timeSlotDate)) {
    const timeSlotDisplayValue = format(timeSlotDate, formatDateString);
    const timeSlotDropdownValue = includeTimeDiff
      ? `${timeSlotDisplayValue} ${calculateTimeDiffDisplay(startDate, timeSlotDate)}`
      : `${timeSlotDisplayValue}`;
    timeSlots.push({
      display: timeSlotDisplayValue,
      displayDropdown: timeSlotDropdownValue,
    });
    timeSlotDate = addMinutes(timeSlotDate, intervalMinutes);
  }
  return timeSlots;
}

export function createEncounterTimeSlotObject(timeDisplayValue, timeDropdownValue) {
  return {
    display: timeDisplayValue,
    displayDropdown: timeDropdownValue || timeDisplayValue,
  };
}

// compares two timeSlot objects to see if they are substantially similar.
// we ignore displayDropdown because that may contain timeDiff information
export function encounterTimeSlotMatch(timeSlot1, timeSlot2) {
  const timeSlot1Value = isPlainObject(timeSlot1) ? timeSlot1?.display : timeSlot1;
  const timeSlot2Value = isPlainObject(timeSlot2) ? timeSlot2?.display : timeSlot2;
  const sameDisplayValue = timeSlot1Value === timeSlot2Value;
  return sameDisplayValue;
}

export function findTimeSlot(timeSlots, timeToFind) {
  if (isArray(timeSlots)) {
    return timeSlots.find(timeSlot => timeSlot?.display === timeToFind);
  }
}
