import React from 'react';
import QuickPanel from 'app/layout/shared-components/quickPanel/QuickPanel';

function RightSideLayoutMain(props) {
  return (
    <>
      <QuickPanel />
    </>
  );
}

export default React.memo(RightSideLayoutMain);
