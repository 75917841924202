import React, { useContext, useState } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import NexusAnimate from '@nexus/core/NexusAnimate';
import AuthenticationProviderContext from 'app/auth/AuthenticationProviderContext';
import { Link } from 'react-router-dom';
import Auth0LoginTab from './tabs/Auth0LoginTab';
import RedirectToLastPath from '../callback';

const useStyles = makeStyles(theme => ({
  root: {
    background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
      theme.palette.primary.dark,
      0.5,
    )} 100%)`,
    color: theme.palette.primary.contrastText,
  },
}));

function Login() {
  const { authenticationIsDone, loggedIn, loggingOut } = useContext(AuthenticationProviderContext);
  const classes = useStyles();
  // for keeping track of whether or not the forcedLogout localStorage was set
  const [forcedLogout, setForcedLogout] = useState(false);

  if (localStorage.getItem('forcedLogout') === 'true') {
    // save to local state so the page remembers we were forced to logout
    setForcedLogout(true);
    // remove the localStorage item so it won't still be set if we close the window
    localStorage.removeItem('forcedLogout');
  }

  if (authenticationIsDone && loggedIn && !loggingOut) {
    return <RedirectToLastPath />;
  }

  return (
    <div className={clsx(classes.root, 'flex flex-col flex-1 flex-shrink-0 p-24 md:flex-row md:p-0')}>
      <div className="flex flex-col flex-grow-0 items-center text-white p-16 text-center md:p-128 md:items-start md:flex-shrink-0 md:flex-1 md:text-left">
        <NexusAnimate animation="transition.expandIn">
          <img className="w-512 mb-48" src="assets/images/logos/CareNexus-logo-full-dark.svg" alt="logo" />
        </NexusAnimate>

        <NexusAnimate animation="transition.slideUpIn" delay={300}>
          <Typography variant="h3" color="inherit" className="font-light">
            Welcome to CareNexus!
          </Typography>
        </NexusAnimate>

        <NexusAnimate animation="transition.slideUpIn" delay={500}>
          <div>
            <Typography variant="subtitle1" color="inherit" className="max-w-512 mt-16">
              CareNexus® is a coordinated care planning solution, designed to improve care quality and data management
              by connecting community-based providers and patients across a patient’s continuum of care.
            </Typography>
          </div>
        </NexusAnimate>
        <NexusAnimate animation="transition.slideUpIn" delay={800}>
          <div className="flex flex-row mt-24">
            <Link to="/terms-of-service">Terms of Service</Link>
            <span className="mx-8">|</span>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </NexusAnimate>
      </div>

      <NexusAnimate animation={{ translateX: [0, '100%'] }}>
        <Card className="w-full max-w-400 mx-auto m-16 md:m-0" square>
          {forcedLogout ? (
            <CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-64">
              <Typography variant="h6" className="text-center md:w-full">
                Welcome Back!
              </Typography>
              <Card className="flex flex-col mt-16 p-16 mb-24" variant="outlined">
                <CardContent className="text-center">
                  We thought you left, so we logged you out to protect your account.
                </CardContent>
              </Card>
              <Auth0LoginTab />
            </CardContent>
          ) : (
            <CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128">
              <Typography variant="h6" className="text-center md:w-full mb-48">
                LOGIN TO YOUR ACCOUNT
              </Typography>
              <Auth0LoginTab />
            </CardContent>
          )}
        </Card>
      </NexusAnimate>
    </div>
  );
}

export default Login;
