import { URL_LOGIN } from 'app/utils/urlConstants';
import Login from './Login';

export const LoginConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  requireAuthentication: false,
  routes: [
    {
      path: URL_LOGIN,
      component: Login,
    },
  ],
};
