import {
  SETTINGS_SET_SETTINGS,
  SETTINGS_SET_DEFAULT_SETTINGS,
  SETTINGS_SET_INITIAL_SETTINGS,
  SETTINGS_RESET_DEFAULT_SETTINGS,
} from '../../nexusConstants';

export function setSettings(value) {
  return {
    type: SETTINGS_SET_SETTINGS,
    value,
  };
}

export function setDefaultSettings(value) {
  return {
    type: SETTINGS_SET_DEFAULT_SETTINGS,
    value,
  };
}

export function setInitialSettings() {
  return {
    type: SETTINGS_SET_INITIAL_SETTINGS,
  };
}

export function resetSettings(value) {
  return {
    type: SETTINGS_RESET_DEFAULT_SETTINGS,
    value,
  };
}
