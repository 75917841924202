import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useInjectSaga } from 'redux-injectors';
import { SettingsNamespace, SmartAppsSettingName } from 'app/store/stateConstants';
import smartAppsSaga from 'app/content/settings/SmartApps/store/sagas';
import Auth0Service from 'app/services/auth0Service';
import { useDispatch, useSelector } from 'react-redux';
import { getLaunchableSmartAppsApi } from 'app/content/settings/SmartApps/store/api';
import { setLaunchableSmartApps } from 'app/content/settings/SmartApps/store/actions';
import navigationConfig from 'app/config/navigationConfig';
import { setNavigation } from 'app/store/actions';
import { AUTH_TIMEOUT_WARNING_SECONDS } from 'app/utils/appConstants';
import { getUserSettings } from 'app/utils/settingsHelpers';
import NexusSplashScreen from '@nexus/core/NexusSplashScreen';
import { auth0Scopes } from 'app/services/auth0Service/authScopes';
import JwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import AuthContext from 'app/auth/AuthenticationProviderContext';
import * as FHIRService from 'app/services/FHIR';
import * as ValueSetService from 'app/services/ValueSetService';
import { URL_FHIR_API } from 'app/utils/urlConstants';
import { showAuthTimer, setSecurityData, setUserDataAuth0, setForcedLogout, setLoggingOut } from '../store/actions';
import settingsSaga from '../store/sagas/settings.saga';
import authConfig from '../../services/auth0Service/auth0ServiceConfig';

function AuthenticationManager({ children }) {
  const {
    authenticationIsDone,
    authenticationLoading,
    setAuthenticationIsDone,
    setAuthenticationLoading,
    setLoggedIn,
    loggedIn,
  } = useContext(AuthContext);
  const {
    isAuthenticated: isAuth0Authenticated,
    isLoading: isAuth0Loading,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    user,
  } = useAuth0();
  const [oldAuthenticated, setOldAuthenticated] = useState(false);

  const dispatch = useDispatch();

  // is auth timer already showing?
  const authTimerVisible = useSelector(({ auth }) => auth?.status?.showAuthTimer);

  // logging out ?
  const loggingOut = useSelector(state => state.auth.status.loggingOut);

  useInjectSaga({ key: SettingsNamespace, saga: settingsSaga });
  useInjectSaga({ key: SmartAppsSettingName, saga: smartAppsSaga });

  const anyAuthenticationStillLoading = authenticationLoading || isAuth0Loading;

  const handlePostLogin = useCallback(async () => {
    const idTokenData = user;
    let accessToken;
    // clear any forced timeout marker
    dispatch(setForcedLogout(false));
    // Set user profile data to state
    dispatch(setUserDataAuth0(idTokenData));
    try {
      // Set access_token info to state
      accessToken = await getAccessTokenSilently({
        audience: authConfig.api_id,
        scope: auth0Scopes,
      });
    } catch (error) {
      try {
        // Set access_token info to state
        accessToken = await getAccessTokenWithPopup({
          audience: authConfig.api_id,
          scope: auth0Scopes,
        });
      } catch (error) {
        console.error(
          `If you see this error, it's possibly related to the localhost environment not having proper scopes. Were scopes changed?`,
        );
      }
    }

    const accessTokenData = JwtDecode(accessToken);
    Auth0Service.setSession(accessToken, accessTokenData.exp);

    // Initialize the FHIR service
    FHIRService.initialize(URL_FHIR_API, `Bearer ${accessToken}`, 'auth0');

    // Initialize the ValueSet service
    ValueSetService.initialize(FHIRService, 'auth0');

    dispatch(setSecurityData(accessTokenData));
    // Set initial navTree state (this setups a clean tree regardless of potential downstream nav changes)
    // This must be done here instead of in the reducer becuase it is dependent on the access token scopes
    dispatch(setNavigation(navigationConfig(undefined, true)));
    // Get user settings and save to state
    await getUserSettings();
    // Get user's smartApps
    await loadSmartApps(dispatch);
  }, [dispatch, getAccessTokenSilently, getAccessTokenWithPopup, user]);

  useEffect(() => {
    if (!anyAuthenticationStillLoading) {
      if (isAuth0Authenticated !== oldAuthenticated) {
        setOldAuthenticated(isAuth0Authenticated);
        if (oldAuthenticated) {
          // No longer authenticated
        } else {
          // Just authenticated
          setAuthenticationLoading(true);
          handlePostLogin().then(() => {
            setLoggedIn(true);
            setAuthenticationIsDone(true);
            setAuthenticationLoading(false);
          });
        }
      }
    }
  }, [
    isAuth0Authenticated,
    isAuth0Loading,
    authTimerVisible,
    dispatch,
    setAuthenticationIsDone,
    handlePostLogin,
    oldAuthenticated,
    setAuthenticationLoading,
    anyAuthenticationStillLoading,
    setLoggedIn,
  ]);

  useEffect(() => {
    if (loggedIn) {
      // set up timer
      const timer = setInterval(() => {
        const secondsUntilExp = Auth0Service.tokenExpiresInSeconds();
        if (
          // show authTimer when approaching token timeout,
          // we are authenticated, and window isn't already showing.
          secondsUntilExp < AUTH_TIMEOUT_WARNING_SECONDS &&
          isAuth0Authenticated &&
          authTimerVisible === false
        ) {
          dispatch(showAuthTimer());
        }
      }, 6000);

      return () => clearInterval(timer);
    }
  }, [loggedIn, dispatch, authTimerVisible, isAuth0Authenticated]);

  if (!isAuth0Authenticated && loggingOut) {
    dispatch(setLoggingOut(false));
  }

  if (loggingOut) {
    return <NexusSplashScreen />;
  }

  // If the authentication phase is done
  // display children
  if (authenticationIsDone) {
    return <>{children}</>;
  }

  // If either auth0 or our post processing are currently loading
  // and the auth phase is not set to done
  // display a loading screen
  if (!authenticationIsDone && (isAuth0Loading || authenticationLoading)) {
    return <NexusSplashScreen />;
  }

  // If auth0 is not loading and it does not believe you are logged in
  // -----redirect to the login page
  if (!isAuth0Loading && !isAuth0Authenticated && !authenticationIsDone) {
    setAuthenticationIsDone(true);
    setLoggedIn(false);
  }

  return <>{children}</>;
}

// get smartApps from API
async function loadSmartApps(dispatch) {
  try {
    // console.log(`start loadSmartApps`);
    const userSmartApps = await getLaunchableSmartAppsApi();
    dispatch(setLaunchableSmartApps(userSmartApps));
    const newNav = navigationConfig(userSmartApps.body, true);
    dispatch(setNavigation(newNav));
    // console.log(`finish loadSmartApps`);
  } catch (err) {
    console.warn('Error Loading SmartApps:', err);
  }
}

AuthenticationManager.propTypes = {
  children: PropTypes.node,
};

export default AuthenticationManager;
