//
// Constants used across the entire CareNexus app
//

// State-related constants
export const AppName = 'CareNexus';
export const AppNamespace = `${AppName}/app`;
export const AuthNamespace = `${AppName}/auth`;
export const SettingsNamespace = `${AppName}/settings`;
export const NexusNamespace = `${AppName}/nexus`;
export const ValueSetNamespace = `${AppName}/valueSets`;
export const PractitionerDashboardAppName = 'practitionerDashboard';
export const GlobalAppName = 'global';
export const QuickPanelAppName = 'quickPanel';
export const PatientListAppName = 'patientList';
export const PatientEditorAppName = 'patientEditor';
export const PatientProfileAppName = 'patientProfile';
export const PractitionerListAppName = 'practitionerList';
export const PractitionerEditorAppName = 'practitionerEditor';
export const CarePlanListAppName = 'carePlanList';
export const CareTeamListAppName = 'careTeamList';
export const CarePlanWizardAppName = 'carePlanWizard';
export const CarePlanEditorAppName = 'carePlanEditor';
export const EncounterListAppName = 'encounterList';
export const EncounterEditorAppName = 'encounterEditor';
export const AppointmentListAppName = 'appointmentList';
export const AppointmentEditorAppName = 'appointmentEditor';
export const SmartAppsSettingName = 'smartApps';
export const UserManager = 'userManager';
export const OrganizationListAppName = 'organizationList';
export const OrganizationEditorAppName = 'organizationEditor';
export const ReportsAppName = 'reports';
