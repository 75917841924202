import request from 'app/utils/request';
import queryString from 'query-string';
import urljoin from 'url-join';
import { URL_VALUESET } from '../FHIR/urlConstants';

export async function queryTerminologyServer(url, options = {}) {
  const fullUrl = urljoin('https://dev.carenexus.io/api/v1/fhir', url);

  const fetchOptions = {
    ...options,
    headers: {
      ...options?.headers,
      Authorization: '7w6w6bLFvZZVC58NDNDKuAjKpvNuHAL73RnSKmpf',
    },
  };

  return request(fullUrl, fetchOptions);
}

export async function searchValueSetById(valueSetId, searchText, queryParams) {
  const requestUrl = queryString.stringifyUrl({
    url: urljoin(URL_VALUESET, valueSetId, '$expand'),
    query: {
      filter: searchText,
      ...queryParams,
    },
  });

  const response = await queryTerminologyServer(requestUrl);
  return response;
}

export async function searchValueSetByUrl(valueSetUrl, searchText, queryParams) {
  const requestUrl = queryString.stringifyUrl({
    url: urljoin(URL_VALUESET, '$expand'),
    query: {
      url: valueSetUrl,
      filter: searchText,
      ...queryParams,
    },
  });

  const response = await queryTerminologyServer(requestUrl);
  return response;
}
