import React from 'react';
import { authScopes } from 'app/services/auth0Service/authScopes';

export const PractitionersConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  requireAuthentication: true,
  routes: [
    {
      path: '/app/practitioners',
      exact: true,
      component: React.lazy(() => import('app/content/apps/Practitioners/PractitionerList')),
      scopes: [authScopes.view.practitionerList, authScopes.practitioner.read],
    },
    {
      path: '/app/practitioners/edit/:practitionerId',
      component: React.lazy(() => import('app/content/apps/Practitioners/PractitionerEditor')),
      scopes: [authScopes.view.practitionerList, authScopes.practitioner.read, authScopes.practitioner.write],
    },
    {
      path: '/app/practitioners/add',
      exact: true,
      component: React.lazy(() => import('app/content/apps/Practitioners/PractitionerEditor')),
      scopes: [authScopes.view.practitionerList, authScopes.practitioner.write],
    },
    // {
    //   path: '/app/practitioners',
    //   exact: true,
    //   component: () => <Redirect to="/app/practitioners/all" />,
    // },
  ],
};
