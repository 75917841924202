// http://hl7.org/fhir/R4/datatypes.html#Coding
import { isNonEmptyString } from 'app/utils/helpers';
import { isPlainObject, isEmpty, get } from 'lodash';
import { appendElement } from '../resourceUtils';

export function createCoding(data, userSelected = true) {
  const coding = {};
  if (isPlainObject(data)) {
    // explicit extraction from data object so we don't include any incidental non-'coding' fields
    appendElement(coding, 'system', data?.system, true);
    appendElement(coding, 'version', data?.version, true);
    appendElement(coding, 'code', data?.code, true);
    appendElement(coding, 'display', data?.display, true);
    appendElement(coding, 'userSelected', userSelected, true);
  }
  return isEmpty(coding) ? null : coding;
}

export function extractDisplayValueFromCoding(coding) {
  if (isPlainObject(coding)) {
    const display = get(coding, 'display');
    if (isNonEmptyString(display)) {
      return display;
    }
    // no display value; return code
    return extractCodeFromCoding(coding);
  }
}

export function extractCodeFromCoding(coding) {
  if (isPlainObject(coding)) {
    return get(coding, 'code');
  }
}
