import { LOCALE_ENGLISH } from '../constants';

const locale = {
  locale: LOCALE_ENGLISH,
  translation: {
    DASHBOARD: 'Dashboard',
    MY_DASHBOARD: 'My Dashboard',
    APPLICATIONS: 'Applications',
    PATIENTS: 'Patients',
    LIST_PATIENTS: 'List Patients',
    ADD_PATIENT: 'Add Patient',
    PRACTITIONERS: 'Practitioners',
    LIST_PRACTITIONERS: 'List Practitioners',
    ADD_PRACTITIONER: 'Add Practitioner',
    PRACTITIONER_VIEWER: 'View Practitioners',
    ORGANIZATIONS: 'Organizations',
    CARE_TEAMS: 'Care Teams',
    MY_CARETEAMS: 'My Care Teams',
    CARE_PLANS: 'Care Plans',
    NEW_CAREPLAN: 'New Care Plan',
    LIST_CAREPLANS: 'List Care Plans',
    MY_CAREPLANS: 'My Care Plans',
    APPOINTMENTS: 'Appointments',
    LIST_APPOINTMENTS: 'List Appointments',
    CREATE_APPOINTMENT: 'Create Appointment',
    ENCOUNTERS: 'Encounters',
    LIST_ENCOUNTERS: 'List Encounters',
    CREATE_ENCOUNTER: 'Create Encounter',
    SMART_APPS: 'SMART Apps',
    ADMINISTRATION: 'Administration',
    REPORTS: 'Reports',
    MANAGE_SMART_APPS: 'Manage SMART Apps',
    MANAGE_USERS: 'Manage Users',
    LIST_ORGANIZATIONS: 'List Organizations',
    ADD_ORGANIZATION: 'Add Organization',
  },
};

export default locale;
