import produce from 'immer';
import {
  SHOW_AUTH_TIMER,
  HIDE_AUTH_TIMER,
  FORCED_LOGOUT,
  GET_USER_SETTINGS_SUCCESS,
  GET_USER_SETTINGS_ERROR,
  USER_LOGGED_OUT,
  SET_LOGGING_OUT,
} from '../../constants';

const initialState = {
  settingsLoaded: false,
  showAuthTimer: false,
  forcedLogout: false,
  loggingOut: false,
};

const authStatusReducer = produce((draft, action) => {
  switch (action.type) {
    case SHOW_AUTH_TIMER:
      draft.showAuthTimer = true;
      break;
    case HIDE_AUTH_TIMER:
      draft.showAuthTimer = false;
      draft.forcedLogout = action.forcedLogout;
      localStorage.setItem('forcedLogout', action.forcedLogout);
      break;
    case FORCED_LOGOUT:
      draft.forcedLogout = action.forcedLogout;
      localStorage.setItem('forcedLogout', action.forcedLogout);
      break;
    case GET_USER_SETTINGS_SUCCESS:
      draft.settingsLoaded = true;
      break;
    case GET_USER_SETTINGS_ERROR:
      draft.settingsLoaded = false;
      break;
    case USER_LOGGED_OUT:
      draft.settingsLoaded = false;
      break;
    case SET_LOGGING_OUT:
      draft.loggingOut = action.payload;
      break;
    default:
      break;
  }
}, initialState);

export default authStatusReducer;
