import { OPEN_NEW_SMARTAPP_DIALOG, OPEN_EDIT_SMARTAPP_DIALOG, CLOSE_SMARTAPP_DIALOG } from '../constants';

export function openNewSmartAppDialog() {
  return {
    type: OPEN_NEW_SMARTAPP_DIALOG,
  };
}

export function openEditSmartAppDialog(appData) {
  return {
    type: OPEN_EDIT_SMARTAPP_DIALOG,
    appData,
  };
}

export function closeSmartAppDialog() {
  return {
    type: CLOSE_SMARTAPP_DIALOG,
  };
}
