import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import NavbarToggleFab from 'app/layout/shared-components/NavbarToggleFab';
import { useSelector } from 'react-redux';
import Navbar from './navbar/Navbar';

function NavbarWrapperLayoutMain(props) {
  const config = useSelector(({ nexus }) => nexus.settings.current.layout.config);
  const navbarTheme = useSelector(({ nexus }) => nexus.settings.navbarTheme);
  const navbar = useSelector(({ nexus }) => nexus.navbar);

  return (
    <>
      <ThemeProvider theme={navbarTheme}>
        <>{config.navbar.style === 'style-folded' && <Navbar />}</>
      </ThemeProvider>

      {config.navbar.display && !config.toolbar.display && !navbar.open && <NavbarToggleFab />}
    </>
  );
}

export default React.memo(NavbarWrapperLayoutMain);
