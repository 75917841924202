import { call, put, takeLatest } from 'redux-saga/effects';
import { requestWithToken, requestPutOptions, requestPostOptions, requestDeleteOptions } from 'app/utils/request';
import { showMessageSuccess, setNavigation, showMessageError } from 'app/store/actions';
import navigationConfig from 'app/config/navigationConfig';
import { URL_API_SMARTAPPS } from 'app/utils/urlConstants';
import { createSmartAppBff, updateSmartAppBff } from './helpers';
import {
  GET_SMARTAPPS,
  CREATE_SMARTAPP,
  UPDATE_SMARTAPP,
  DELETE_SMARTAPP,
  GET_LAUNCHABLE_SMARTAPPS,
  GET_LAUNCHABLE_SMARTAPPS_SUCCESS,
} from './constants';
import {
  getSmartApps,
  getSmartAppsSuccess,
  getSmartAppsError,
  createSmartAppSuccess,
  createSmartAppError,
  updateSmartAppSuccess,
  updateSmartAppError,
  deleteSmartAppSuccess,
  deleteSmartAppError,
  getLaunchableSmartApps,
  getLaunchableSmartAppsSuccess,
  getLaunchableSmartAppsError,
} from './actions/smartApp.actions';

export function* getSmartAppsSaga() {
  const requestURL = URL_API_SMARTAPPS;

  try {
    // Call our request helper (see 'utils/request')
    const smartApps = yield call(requestWithToken, requestURL);
    yield put(getSmartAppsSuccess(smartApps));
  } catch (err) {
    yield put(getSmartAppsError(err));
  }
}

export function* createSmartAppSaga({ formData }) {
  const requestURL = URL_API_SMARTAPPS;

  try {
    // Ensure form data is in proper format for API consumption
    const newSmartAppData = createSmartAppBff(formData);
    const smartAppId = yield call(requestWithToken, requestURL, requestPostOptions(newSmartAppData));
    yield put(createSmartAppSuccess(smartAppId));
    // Update list of smartApps
    yield put(getSmartApps());
    // Update logged in user's smartApp list
    yield put(getLaunchableSmartApps());
    yield put(showMessageSuccess(`New SMART App added successfully!`));
  } catch (err) {
    yield put(createSmartAppError(err));
  }
}

export function* updateSmartAppSaga({ formData }) {
  const requestURL = URL_API_SMARTAPPS;

  try {
    // Ensure form data is in proper format for API consumption
    const updateSmartAppData = updateSmartAppBff(formData);
    const smartAppId = yield call(requestWithToken, requestURL, requestPutOptions(updateSmartAppData));
    yield put(updateSmartAppSuccess(smartAppId));
    yield put(getSmartApps());
    // Update logged in user's smartApp list
    yield put(getLaunchableSmartApps());
    yield put(showMessageSuccess(`SMART App updated successfully!`));
  } catch (err) {
    yield put(updateSmartAppError(err));
  }
}

export function* deleteSmartAppSaga({ appId }) {
  const requestURL = `${URL_API_SMARTAPPS}/${appId}`;

  try {
    const smartAppId = yield call(requestWithToken, requestURL, requestDeleteOptions());
    yield put(deleteSmartAppSuccess(smartAppId));
    yield put(getSmartApps());
    // Update logged in user's smartApp list
    yield put(getLaunchableSmartApps());
    yield put(showMessageSuccess(`SMART App removed successfully!`));
  } catch (err) {
    yield put(deleteSmartAppError(err));
  }
}

export function* getLaunchableSmartAppsSaga() {
  const requestURL = `${URL_API_SMARTAPPS}/launchable`;

  try {
    const launchableSmartApps = yield call(requestWithToken, requestURL);
    yield put(getLaunchableSmartAppsSuccess(launchableSmartApps));
  } catch (err) {
    yield put(getLaunchableSmartAppsError(err));
  }
}

export function* getLaunchableSmartAppsSuccessSaga(smartApps) {
  try {
    // Generate new nav with updated smartApps list
    const smartAppsArray = smartApps.smartApps.body;
    const newNav = yield call(navigationConfig, smartAppsArray, true);
    yield put(setNavigation(newNav));
  } catch (err) {
    yield put(showMessageError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* smartAppsRootSaga() {
  // Watches for actions and calls relevant saga function when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(GET_SMARTAPPS, getSmartAppsSaga);
  yield takeLatest(CREATE_SMARTAPP, createSmartAppSaga);
  yield takeLatest(UPDATE_SMARTAPP, updateSmartAppSaga);
  yield takeLatest(DELETE_SMARTAPP, deleteSmartAppSaga);
  yield takeLatest(GET_LAUNCHABLE_SMARTAPPS, getLaunchableSmartAppsSaga);
  yield takeLatest(GET_LAUNCHABLE_SMARTAPPS_SUCCESS, getLaunchableSmartAppsSuccessSaga);
}
