import { DIALOG_OPEN, DIALOG_CLOSE } from '../../nexusConstants';

export function closeDialog() {
  return {
    type: DIALOG_CLOSE,
  };
}

export function openDialog(options) {
  return {
    type: DIALOG_OPEN,
    options,
  };
}
