import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Icon, Typography, Toolbar, AppBar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CheckIcon from '@material-ui/icons/Check';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import authService from 'app/services/auth0Service';
import { useAuth0 } from '@auth0/auth0-react';
import { AUTH_TIMEOUT_WARNING_SECONDS } from 'app/utils/appConstants';
import { logoutUser } from './store/actions/user.actions';
import { hideAuthTimer } from './store/actions/authStatus.actions';

function AuthTimerDialog(props) {
  const dispatch = useDispatch();
  const { getAccessTokenSilently, logout } = useAuth0();
  const buttonClasses = 'normal-case mr-12 mt-10';
  const [timeRemaining, setTimeRemaining] = useState(authService.tokenExpiresInSeconds());
  const showAuthTimer = useSelector(({ auth }) => auth?.status?.showAuthTimer);

  useEffect(() => {
    if (showAuthTimer) {
      const timer = setInterval(() => {
        const secondsRemaining = authService.tokenExpiresInSeconds();
        // useState to keep the timer countdown updating every second
        setTimeRemaining(secondsRemaining);
        if (secondsRemaining <= 0) {
          // time ran out; hide this window (with forcedLogout=true) and log out
          dispatch(hideAuthTimer(true));
          dispatch(logoutUser(logout));
        } else if (secondsRemaining > AUTH_TIMEOUT_WARNING_SECONDS) {
          // token has been updated elsewhere; hide window
          dispatch(hideAuthTimer(true));
        }
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [showAuthTimer, dispatch, logout]);

  if (!showAuthTimer) {
    return null;
  }

  return (
    <Dialog open={showAuthTimer} disableBackdropClick disableEscapeKeyDown fullWidth maxWidth="xs">
      <AppBar position="static" elevation={1}>
        <Toolbar className="flex w-full overflow-x-auto px-8 sm:px-16">
          <div className="flex flex-1">
            <Icon className="mr-8">apps</Icon>
            <Typography variant="subtitle1" color="inherit">
              Are You Still There?
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <DialogContent classes={{ root: 'p-0' }}>
        <div className="px-16 pt-16 sm:px-24">
          For your security, you will be logged out in {authService.tokenExpiresInSeconds() || timeRemaining} seconds.
        </div>
      </DialogContent>
      <DialogActions className="justify-between pl-8 sm:pl-16">
        <div className="w-full flex pt-16">
          <Button
            id="renew-login-token"
            className={buttonClasses}
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
            onClick={async () => {
              authService.refreshToken(getAccessTokenSilently);
              dispatch(hideAuthTimer(false));
            }}
          >
            Stay Logged In
          </Button>
          <Button
            id="logout"
            className={buttonClasses}
            variant="contained"
            color="primary"
            startIcon={<ExitToAppIcon />}
            onClick={() => {
              dispatch(logoutUser(logout));
              dispatch(hideAuthTimer(false));
            }}
          >
            Log Out
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default AuthTimerDialog;
