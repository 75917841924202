import produce from 'immer';
import { cloneDeep } from 'lodash';
import { DefaultUserSettings } from 'app/store/settingConstants';
import {
  GET_USER_SETTINGS_SUCCESS,
  SET_LOCAL_USER_SETTINGS,
  SET_USER_LASTPATH,
  USER_LOGGED_OUT,
} from '../../constants';

const initialState = cloneDeep(DefaultUserSettings);

const settings = produce((draft, action) => {
  switch (action.type) {
    case SET_LOCAL_USER_SETTINGS:
      return { ...cloneDeep(draft), ...cloneDeep(action.newSettings) };
    case GET_USER_SETTINGS_SUCCESS:
      return action.userSettings;
    case SET_USER_LASTPATH:
      draft.global.lastPath = action.lastPath;
      break;
    case USER_LOGGED_OUT:
      return initialState;
    default:
      return draft;
  }
}, initialState);

export default settings;
