import { extractResourceId } from 'app/resources/resourceUtils';
import { requestPutOptions } from 'app/utils/request';
import urljoin from 'url-join';
import { errorIfUninitialized, query } from './core';
import { URL_CAREPLAN } from './urlConstants';

export async function getCarePlanById(id) {
  const url = urljoin(URL_CAREPLAN, id);
  const response = await query(url);
  return response;
}

/**
 * A function that allows for querying carePlans. Add params to modify the request
 *
 * @param {array} params - An array of query strings. They will be joined with '&' and appended to the request URL
 * @returns promise
 */
export async function getCarePlans(params = []) {
  errorIfUninitialized();

  const finalParams = params.length > 0 ? params : '_count=100';

  const paramsString = `?${finalParams.join('&')}`;
  const response = await query(`${URL_CAREPLAN}${paramsString}`);
  return response;
}

export async function updateCarePlan(carePlan) {
  const url = urljoin(URL_CAREPLAN, extractResourceId(carePlan));
  const response = await query(url, requestPutOptions(carePlan));
  return response;
}
