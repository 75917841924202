import React from 'react';
import PropTypes from 'prop-types';
import { isString, isEmpty } from 'lodash';
import Icon from '@material-ui/core/Icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faUserMd, faHandshake } from '@fortawesome/free-solid-svg-icons';

function NavIcon(props) {
  const { className, icon } = props;

  function getIcon(iconType) {
    switch (iconType) {
      case 'faUsers':
        return faUsers
      case 'faUserMd':
        return faUserMd
      case 'faHandshake':
        return faHandshake
      default:
    }
  }

  function renderIcon(className, icon) {
    if (isString(icon)) {
      const iconType = icon.split(':');
      if (iconType.length > 1) {
        if (iconType[0] === 'fa') {
          // extract icon type from string
          const faIcon = getIcon(iconType[1]);
          if (!isEmpty(faIcon)) {
            // if we found an icon from the passed string, render an icon
            return (<FontAwesomeIcon icon={faIcon} className={className} />)
          }
        }
      }
      // no specifier (':') found; render as a normal icon
      return (
        <Icon color="action" className={className}>
          {icon}
        </Icon>
      );
    }
  }

  return (
    renderIcon(className, icon)
  )
}

NavIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
}


export default NavIcon;
