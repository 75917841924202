// http://hl7.org/fhir/R4/datatypes.html#Address

import { get, has, isArray, isEmpty, isPlainObject, isString } from 'lodash';
import { isNonEmptyArray, isNonEmptyArrayOfStrings, isNonEmptyString, joinNonEmpty } from 'app/utils/helpers';
import { USCORE_USPS_STATE } from 'app/resources/store/valueSet.constants';
import { appendElement, extractValueFromPath, removeElement } from '../resourceUtils';
import { getValueSetDisplayFromCode, getValueSetItemFromCode } from '../ValueSetResource';

const COMMA_SEPARATOR = ', ';
const SPACE_SEPARATOR = ' ';

// creates a FHIR Address datatype; line can be a string or an array of strings
export function createAddress(line, city, state, postalCode) {
  const address = {};
  let addressDisplay = '';

  // append address line(s); handle both arrays and string values
  if (isString(line)) {
    appendElement(address, 'line', [line]);
    addressDisplay = appendDisplayAddress(addressDisplay, SPACE_SEPARATOR, line);
  } else if (isNonEmptyArrayOfStrings(line)) {
    // append line array, but filter any empty lines first
    appendElement(
      address,
      'line',
      line.filter(item => !isEmpty(item)),
    );
    addressDisplay = appendDisplayAddress(addressDisplay, SPACE_SEPARATOR, joinNonEmpty(line, SPACE_SEPARATOR));
  }
  // append city name
  if (isNonEmptyString(city)) {
    appendElement(address, 'city', city);
    addressDisplay = appendDisplayAddress(addressDisplay, COMMA_SEPARATOR, city);
  }
  // append state
  if (isNonEmptyString(state)) {
    appendElement(address, 'state', state);
    addressDisplay = appendDisplayAddress(addressDisplay, SPACE_SEPARATOR, state);
  }
  // append postal code
  if (isNonEmptyString(postalCode)) {
    appendElement(address, 'postalCode', postalCode);
    addressDisplay = appendDisplayAddress(addressDisplay, SPACE_SEPARATOR, postalCode);
  }

  // finalize the text representation of the full name
  appendElement(address, 'text', addressDisplay, true);

  // return resulting HumanName
  return address;
}

// helper function to create an address display string
function appendDisplayAddress(currentDisplayAddress, separator, elementToAppend) {
  if (isEmpty(currentDisplayAddress)) {
    return elementToAppend;
  }
  if (isEmpty(elementToAppend)) {
    return currentDisplayAddress;
  }
  return `${currentDisplayAddress}${separator}${elementToAppend}`;
}

export function addressToString(address) {
  if (isPlainObject(address)) {
    let addressDisplay = '';
    // address lines
    const addressLines = extractAddressLine(address);
    const addressLinesDisplay = joinNonEmpty(addressLines, SPACE_SEPARATOR);
    addressDisplay = appendDisplayAddress(addressDisplay, SPACE_SEPARATOR, addressLinesDisplay);
    // city
    const city = extractAddressCity(address);
    addressDisplay = appendDisplayAddress(addressDisplay, COMMA_SEPARATOR, city);
    // state
    const state = extractAddressState(address);
    addressDisplay = appendDisplayAddress(addressDisplay, SPACE_SEPARATOR, state);
    // postalCode
    const postalCode = extractAddressPostalCode(address);
    addressDisplay = appendDisplayAddress(addressDisplay, SPACE_SEPARATOR, postalCode);
    // return addressDisplay value
    return addressDisplay;
  }
}

// returns the raw 'line' element array
export function extractAddressLine(address) {
  return extractValueFromPath(address, 'line');
}

export function extractAddressLine1(address) {
  const addressLines = extractAddressLine(address);
  if (isArray(addressLines) && !isEmpty(addressLines)) {
    return addressLines[0];
  }
}

export function extractAddressLine2(address) {
  const addressLines = extractAddressLine(address);
  if (isArray(addressLines) && addressLines?.length > 1) {
    return addressLines[1];
  }
}

export function extractAddressCity(address) {
  return extractValueFromPath(address, 'city');
}

export function extractAddressState(address) {
  return extractValueFromPath(address, 'state');
}

export function extractAddressStateValueSetItem(address) {
  const stateCode = extractValueFromPath(address, 'state');
  return getValueSetItemFromCode(USCORE_USPS_STATE, stateCode);
}
export function extractAddressStateDisplay(address) {
  const stateCode = extractValueFromPath(address, 'state');
  return getValueSetDisplayFromCode(USCORE_USPS_STATE, stateCode);
}

export function extractAddressPostalCode(address) {
  return extractValueFromPath(address, 'postalCode');
}

// NOTE: this is limited to setting the address to the first item of an address array
export function setAddress(resource, addressPath, address) {
  if (isPlainObject(resource)) {
    // get address array from object path
    const addresses = get(resource, addressPath);
    if (isNonEmptyArray(addresses)) {
      // we have at least one address already
      appendElement(resource, `${addressPath}[0]`, address);
    } else {
      // no address array currently exists, create it and set our address as the first item
      appendElement(resource, addressPath, [address]);
    }
  }
}

export function removeAddress(resource, addressPath) {
  if (isPlainObject(resource) && has(resource, addressPath)) {
    const addresses = get(resource, addressPath);
    if (isNonEmptyArray(addresses)) {
      return removeElement(resource, `${addressPath}[0]`);
    }
    // no addresses found, return false
    return false;
  }
}
