import { SHOW_AUTH_TIMER, HIDE_AUTH_TIMER, FORCED_LOGOUT } from '../../constants';

export function showAuthTimer() {
  return {
    type: SHOW_AUTH_TIMER,
  };
}

export function hideAuthTimer(forcedLogout = false) {
  return {
    type: HIDE_AUTH_TIMER,
    forcedLogout,
  };
}

export function setForcedLogout(forcedLogout) {
  return {
    type: FORCED_LOGOUT,
    forcedLogout,
  };
}
