import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useInjectSaga } from 'redux-injectors';
import { useLocation } from 'react-router-dom';
import { ValueSetNamespace } from 'app/store/stateConstants';
import valueSetSaga from 'app/resources/store/sagas';
import authService from 'app/services/auth0Service';
import { AUTH_RENEW_TOKEN_THRESHOLD } from 'app/utils/appConstants';
// import SettingsPanel from 'app/layout/shared-components/SettingsPanel';
import NexusScrollbars from '@nexus/core/NexusScrollbars';
import NexusMessage from '@nexus/core/NexusMessage';
import NexusDialog from '@nexus/core/NexusDialog';
import NexusSuspense from '@nexus/core/NexusSuspense';
import { setUserLastPath } from 'app/auth/store/actions';
import { LASTPATH_IGNORE_ROUTES } from 'app/store/settingConstants';
import routes from 'app/config/routesConfig';
import { useAuth0 } from '@auth0/auth0-react';
import ToolbarLayoutMain from './components/ToolbarLayoutMain';
import FooterLayoutMain from './components/FooterLayoutMain';
import LeftSideLayoutMain from './components/LeftSideLayoutMain';
import RightSideLayoutMain from './components/RightSideLayoutMain';
import NavbarWrapperLayoutMain from './components/NavbarWrapperLayoutMain';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    '&.boxed': {
      maxWidth: 1280,
      margin: '0 auto',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    },
    '&.scroll-body': {
      '& $wrapper': {
        height: 'auto',
        flex: '0 0 auto',
        overflow: 'auto',
      },
      '& $contentWrapper': {},
      '& $content': {},
    },
    '&.scroll-content': {
      '& $wrapper': {},
      '& $contentWrapper': {},
      '& $content': {},
    },
    '& .navigation': {
      '& .list-subheader-text, & .list-item-text, & .item-badge, & .arrow-icon': {
        transition: theme.transitions.create('opacity', {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.easeInOut,
        }),
      },
    },
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '100%',
    flex: '1 1 auto',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 3,
    overflow: 'hidden',
    flex: '1 1 auto',
  },
  content: {
    position: 'relative',
    display: 'flex',
    overflow: 'auto',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%',
    '-webkit-overflow-scrolling': 'touch',
    zIndex: 2,
  },
}));

function MainLayout(props) {
  const dispatch = useDispatch();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const config = useSelector(({ nexus }) => nexus.settings.current.layout.config);

  const classes = useStyles(props);
  const { lastPath } = useSelector(({ auth }) => auth?.settings?.global);
  const { settingsLoaded } = useSelector(({ auth }) => auth?.status);
  // extract path and any search queries on the current location
  const { pathname, search } = useLocation();
  // merge path and search to get the full url path
  const fullPath = `${pathname}${search}`;

  const [localLastPath, setLocalLastPath] = useState(pathname);

  useEffect(() => {
    // ignore if the path is an ignored one
    const foundIndex = LASTPATH_IGNORE_ROUTES.findIndex(route => {
      if (route.exact) {
        // ignore exact route
        return route.path === pathname;
      }
      // ignore route with optional arguments
      return pathname.includes(route.path);
    });

    if (foundIndex === -1) {
      // only set lastPath user setting if it's different from our current value
      if (lastPath !== fullPath && settingsLoaded) {
        // console.log(`DISPATCHING LASTPATH CHANGE`);
        dispatch(setUserLastPath(fullPath));
      }
    }
  }, [dispatch, settingsLoaded, lastPath, pathname, fullPath]);

  // inject global sagas
  useInjectSaga({ key: ValueSetNamespace, saga: valueSetSaga });

  // periodically renew token on user navigating
  if (pathname !== localLastPath) {
    // save new path to local state
    setLocalLastPath(pathname);
    if (authService.isNotExpired()) {
      const secondsRemaining = authService.tokenExpiresInSeconds();
      if (isAuthenticated && secondsRemaining < AUTH_RENEW_TOKEN_THRESHOLD) {
        authService.refreshToken(getAccessTokenSilently);
      }
    }
  }

  // console.warn('NexusLayout:: rendered');

  switch (config.scroll) {
    case 'body': {
      return (
        <div id="nexus-layout" className={clsx(classes.root, config.mode, `scroll-${config.scroll}`)}>
          {config.leftSidePanel.display && <LeftSideLayoutMain />}

          <div className="flex flex-1 flex-col overflow-hidden relative">
            {config.toolbar.display && config.toolbar.style === 'fixed' && config.toolbar.position === 'above' && (
              <ToolbarLayoutMain />
            )}

            <NexusScrollbars className="overflow-auto" scrollToTopOnRouteChange>
              {config.toolbar.display && config.toolbar.style !== 'fixed' && config.toolbar.position === 'above' && (
                <ToolbarLayoutMain />
              )}

              <div className={classes.wrapper}>
                {config.navbar.display && config.navbar.position === 'left' && <NavbarWrapperLayoutMain />}

                <div className={classes.contentWrapper}>
                  {config.toolbar.display && config.toolbar.position === 'below' && <ToolbarLayoutMain />}

                  <div className={classes.content}>
                    <NexusDialog />

                    <NexusSuspense>{renderRoutes(routes)}</NexusSuspense>

                    {props.children}
                  </div>

                  {config.footer.display && config.footer.position === 'below' && <FooterLayoutMain />}

                  {/* <SettingsPanel /> */}
                </div>

                {config.navbar.display && config.navbar.position === 'right' && <NavbarWrapperLayoutMain />}
              </div>

              {config.footer.display && config.footer.style !== 'fixed' && config.footer.position === 'above' && (
                <FooterLayoutMain />
              )}
            </NexusScrollbars>

            {config.footer.display && config.footer.style === 'fixed' && config.footer.position === 'above' && (
              <FooterLayoutMain />
            )}
          </div>

          {config.rightSidePanel.display && <RightSideLayoutMain />}

          <NexusMessage />
        </div>
      );
    }
    case 'content':
    default: {
      return (
        <div id="nexus-layout" className={clsx(classes.root, config.mode, `scroll-${config.scroll}`)}>
          {config.leftSidePanel.display && <LeftSideLayoutMain />}

          <div className="flex flex-1 flex-col overflow-hidden relative">
            {config.toolbar.display && config.toolbar.position === 'above' && <ToolbarLayoutMain />}

            <div className={classes.wrapper}>
              {config.navbar.display && config.navbar.position === 'left' && <NavbarWrapperLayoutMain />}

              <div className={classes.contentWrapper}>
                {config.toolbar.display && config.toolbar.position === 'below' && config.toolbar.style === 'fixed' && (
                  <ToolbarLayoutMain />
                )}

                <NexusScrollbars className={classes.content} scrollToTopOnRouteChange>
                  {config.toolbar.display &&
                    config.toolbar.position === 'below' &&
                    config.toolbar.style !== 'fixed' && <ToolbarLayoutMain />}

                  <NexusDialog />

                  <NexusSuspense>{renderRoutes(routes)}</NexusSuspense>

                  {props.children}

                  {config.footer.display && config.footer.position === 'below' && config.footer.style !== 'fixed' && (
                    <FooterLayoutMain />
                  )}
                </NexusScrollbars>

                {config.footer.display && config.footer.position === 'below' && config.footer.style === 'fixed' && (
                  <FooterLayoutMain />
                )}

                {/* <SettingsPanel /> */}
              </div>

              {config.navbar.display && config.navbar.position === 'right' && <NavbarWrapperLayoutMain />}
            </div>

            {config.footer.display && config.footer.position === 'above' && <FooterLayoutMain />}
          </div>

          {config.rightSidePanel.display && <RightSideLayoutMain />}

          <NexusMessage />
        </div>
      );
    }
  }
}

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default React.memo(MainLayout);
