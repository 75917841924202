import React from 'react';
import { authScopes } from 'app/services/auth0Service/authScopes';
import { URL_CAREPLANS_NEW, URL_CAREPLANS_LIST } from 'app/utils/urlConstants';

export const CarePlanConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  requireAuthentication: true,
  routes: [
    {
      path: URL_CAREPLANS_LIST,
      exact: true,
      component: React.lazy(() => import('app/content/apps/CarePlans/CarePlanList')),
      scopes: [
        authScopes.view.carePlanList,
        authScopes.carePlan.read,
        authScopes.condition.read,
        authScopes.patient.read,
        authScopes.goal.read,
        authScopes.careTeam.read,
      ],
    },
    {
      path: '/app/careplans/edit/:carePlanId',
      component: React.lazy(() => import('app/content/apps/CarePlans/CarePlanEditor')),
      scopes: [
        authScopes.view.carePlanNew,
        authScopes.carePlan.write,
        authScopes.carePlan.read,
        authScopes.condition.write,
        authScopes.condition.read,
        authScopes.careTeam.write,
        authScopes.careTeam.read,
        authScopes.goal.write,
        authScopes.goal.read,
        authScopes.appointment.write,
        authScopes.appointment.read,
      ],
    },
    {
      path: URL_CAREPLANS_NEW,
      exact: true,
      component: React.lazy(() => import('app/content/apps/CarePlans/CarePlanWizard')),
      scopes: [
        authScopes.view.carePlanNew,
        authScopes.carePlan.write,
        authScopes.condition.write,
        authScopes.careTeam.write,
        authScopes.goal.write,
      ],
    },
  ],
};
