export const darkModeTheme = {
  main: 'mainDark',
  navbar: 'mainDark',
  toolbar: 'toolbarDark',
  footer: 'toolbarDark',
};

export const lightModeTheme = {
  main: 'mainLight',
  navbar: 'toolbarLight',
  toolbar: 'toolbarLight',
  footer: 'toolbarLight',
};

const settingsConfig = {
  layout: {
    style: 'MainLayout', // layout-1 layout-2 layout-3
    config: {}, // checkout default layout configs at app/layouts for example  app/layouts/layoutMain/LayoutMainConfig.js
  },
  customScrollbars: false,
  animations: true,
  direction: 'ltr', // rtl, ltr
  theme: darkModeTheme,
};

export default settingsConfig;
