import React, { useEffect } from 'react';
import { authRoles } from 'app/auth';
import { logoutUser } from 'app/auth/store/actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

function Logout() {
  const { logout } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutUser(logout));
  }, [dispatch, logout]);

  return <p>Logging out...</p>;
}

export const LogoutConfig = {
  auth: authRoles.user,
  requireAuthentication: true,
  routes: [
    {
      path: '/logout',
      component: Logout,
    },
  ],
};
