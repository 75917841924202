// A collection of validators

import { isDateOrDateString, isPastDate } from './dateHelpers';
import { isEmptyEmpty } from './helpers';

export function isSSN(elementValue) {
  const ssnPattern = /^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$/;
  return ssnPattern.test(elementValue);
}

export function isNPI(elementValue) {
  const npiPattern = /^[0-9]{10}$/;
  return npiPattern.test(elementValue);
}

// for use with the 'rules' object of React Hook Form
export function birthDateValidationRules() {
  return {
    validate: {
      validDate: value => isDateOrDateString(value) || isEmptyEmpty(value) || 'Please enter a valid US birthdate.',
      futureDate: value => isPastDate(value) || isEmptyEmpty(value) || 'Date cannot be in the future.',
    },
  };
}
