//
// Appwide URL constants
//

// API path constants
export const URL_API_BACKEND = process.env.REACT_APP_API_ROOT_URL;
export const URL_API_SMARTAPPS = `${URL_API_BACKEND}/smart/apps`;
export const URL_API_METADATA = `${URL_API_BACKEND}/user`;
export const URL_API_METADATA_USER = `${URL_API_METADATA}/metadata`;
export const URL_API_METADATA_SECURITY = `${URL_API_METADATA}/securityMetadata`;
export const URL_API_USERS = `${URL_API_BACKEND}/users`;
export const URL_API_USER_METADATA = id => `${URL_API_USERS}/${id}/metadata`;
export const URL_API_USER_SECURITY_METADATA = id => `${URL_API_USERS}/${id}/securityMetadata`;
export const URL_AUTH_DEV_CUSTOM_CLAIM_NAMESPACE = `https://dev.carenexus.io/api/v1/fhir`;

// This is necessary because in local dev, we use the localhost:4000 url to proxy our FHIR API calls
export const URL_FHIR_BASE_URL = `${
  process.env.NODE_ENV === 'development' ? URL_AUTH_DEV_CUSTOM_CLAIM_NAMESPACE : URL_FHIR_API
}`;

export const URL_FHIR_API = `${URL_API_BACKEND}/fhir`;
// export const URL_FHIR_API = 'https://api.logicahealth.org/BZsandbox123/data';
export const URL_FHIR_APPOINTMENT = `${URL_FHIR_API}/Appointment`;
export const URL_FHIR_CAREPLAN = `${URL_FHIR_API}/CarePlan`;
export const URL_FHIR_CARETEAM = `${URL_FHIR_API}/CareTeam`;
export const URL_FHIR_CONDITION = `${URL_FHIR_API}/Condition`;
export const URL_FHIR_ENCOUNTER = `${URL_FHIR_API}/Encounter`;
export const URL_FHIR_PATIENT = `${URL_FHIR_API}/Patient`;
export const URL_FHIR_PRACTITIONER = `${URL_FHIR_API}/Practitioner`;
export const URL_FHIR_ORGANIZATION = `${URL_FHIR_API}/Organization`;
export const URL_FHIR_RELATEDPERSON = `${URL_FHIR_API}/RelatedPerson`;
export const URL_FHIR_VALUESET = `${URL_FHIR_API}/ValueSet`;

// Frontend path constants
export const URL_ROOT = '/';
export const URL_LOGIN = '/login';
export const URL_LOGOUT = '/logout';
export const URL_CALLBACK = '/callback';
export const URL_REGISTER = '/register';
export const URL_PATIENTS_ALL = `/app/patients`;
export const URL_PATIENTS_ADD = `/app/patients/add`;
export const URL_PATIENTS_DASHBOARD = id => `/app/patients/${id}`;
export const URL_PATIENTS_EDIT = id => `/app/patients/edit/${id}`;
export const URL_PRACTITIONERS_ALL = `/app/practitioners`;
export const URL_PRACTITIONERS_ADD = `/app/practitioners/add`;
export const URL_PRACTITIONERS_EDIT = id => `/app/practitioners/edit/${id}`;
export const URL_CAREPLANS_LIST = `/app/careplans`;
export const URL_CAREPLANS_MY = `/app/careplans/my`;
export const URL_CAREPLANS_NEW = `/app/careplans/new`;
export const URL_CAREPLANS_CARETEAMS = `/app/careplans/careteams`;
export const URL_CAREPLANS_EDIT = id => `/app/careplans/edit/${id}`;
export const URL_ORGANIZATIONS_ALL = `/app/organizations`;
export const URL_ORGANIZATIONS_EDIT = id => `/app/organizations/edit/${id}`;
export const URL_ORGANIZATION_ADD = `/app/organizations/add`;
export const URL_ENCOUNTERS_LIST = `/app/encounters`;
export const URL_ENCOUNTERS_NEW = `/app/encounters/new`;
export const URL_ENCOUNTERS_EDIT = id => `/app/encounters/edit/${id}`;
export const URL_APPOINTMENTS_LIST = `/app/appointments`;
export const URL_APPOINTMENTS_NEW = `/app/appointments/new`;
export const URL_APPOINTMENTS_EDIT = id => `/app/appointments/edit/${id}`;
